"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const shallowequal = require("shallowequal");

const StyleGlobals = require("../styles/globals");
const { translate } = require("react-translate");

class FilterButton extends React.Component {
  static displayName = "FilterButton";

  classes = () => {
    return {
      default: {
        filterButton: {
          padding: `${StyleGlobals.dimen8} ${StyleGlobals.dimen8} ${StyleGlobals.dimen8} ${StyleGlobals.dimen8}`,
          width: "100%",
          display: "flex",
          justifyContent: "space-beetween",
          alignItems: "center",
          verticalAlign: "middle",
          textAlign: "left",
          fontFamily: StyleGlobals.fonts.default,
          fontSize: "14px",
          textTransform: "capitalize",
          outline: 0,
          cursor: "pointer",
          border: `1px solid ${StyleGlobals.borderColors.lightGrey}`,
          borderColor: StyleGlobals.borderColors.lightGrey,
          margin: 0,
          borderRadius: StyleGlobals.dimen4,
          // transition: "border-color .2s",
          animation: `opacityAnimation .25s ${this.props.index * 0.05}s forwards `,
          background: StyleGlobals.bgColors.main
        },
        filterButtonIcon: {
          width: 25,
          height: 25,
          marginRight: StyleGlobals.dimen8,
          fill: "#000",
          stroke: "#000"
        },
        filterName: {
          width: "100%",
          color: StyleGlobals.textColors.lighten,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingRight: StyleGlobals.dimen4,
          transition: "color .2s"
        },
        box: {
          width: "16px",
          height: "16px",
          border: `2px solid ${StyleGlobals.borderColors.main}`,
          borderColor: StyleGlobals.borderColors.main,
          display: "inline-block",
          verticalAlign: "middle",
          borderRadius: StyleGlobals.dimen4,
          flex: "0 0 auto",
          // overflow: "hidden",
          position: "relative"
        },
        boxBg: {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          background: StyleGlobals.bgColors.accent,
          transform: "scaleY(0.01) translateZ(0)",
          transformOrigin: "left bottom",
          transition: ".24s transform ease-out"
        },
        checkbox: {
          opacity: "0",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0
        },
        checkMark: {
          opacity: 1,
          width: "20px",
          height: "16px",
          // transform: "scale(.1) translate(-2px, -1px) rotateZ(-10deg)",
          transform: "scale(0.8) translate(-5px, -4px)",
          transition: "transform 200ms cubic-bezier(0.000, -0.600, 0.525, 1.650)"
        }
      },
      isHover: {
        filterButton: {
          borderColor: StyleGlobals.borderColors.grey
        }
      },
      isActive: {
        filterButton: {
          borderColor: "#e9e9e9",
          background: "#F9F9F9"
        },
        checkMark: {
          opacity: 1
          // transform: "scale(0.8) translate(-5px, -4px)"
        },
        box: {
          borderColor: StyleGlobals.bgColors.accent
        },
        boxBg: {
          borderColor: "transparent",
          transform: "scaleY(1) translateZ(0)"
        },
        filterName: {
          color: StyleGlobals.textColors.main
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.props);
  };

  render() {
    let { isActive, onChange, onHover, name, t } = this.props;
    return (
      <button
        type="button"
        style={{ ...this.styles().filterButton, ...this.props.style }}
        onClick={() => onChange(name, !isActive)}
        onMouseEnter={() => onHover(name)}
        onMouseLeave={() => onHover(null)}
      >
        <svg
          style={this.styles().filterButtonIcon}
          viewBox="0 0 64 64"
          enableBackground="new 0 0 64 64"
        >
          <use xlinkHref={`#${name}`} />
        </svg>
        <span style={this.styles().filterName}>{t(name)}</span>
        <div style={this.styles().box}>
          <div style={this.styles().boxBg} />
          <svg style={this.styles().checkMark}>
            <path
              fill="#FFF"
              d="M3.2768813,9.56199493 L4.74335224,8.1243454 L8.76072581,12.0613715 L15.457558,5.5 L16.9245473,6.93663352 L8.76072581,14.9366706 L3.2768813,9.56199493 Z"
            />
          </svg>
        </div>
      </button>
    );
  }
}

function shouldComponentUpdate(nextProps, nextState, nextContext) {
  if (this.context.locale !== nextContext.locale) {
    return true;
  }
  if (this.props.isActive !== nextProps.isActive) {
    return true;
  }
  if (this.props.isHover !== nextProps.isHover) {
    return true;
  }
  return !shallowequal(this.props.style, nextProps.style);
}

module.exports = translate("FilterButton", shouldComponentUpdate)(FilterButton);
