"use strict";

const IMAGES_BUCKET = "www.bedandbike.se";
const IMAGES_PREFIX = "images/uploads";

// this assumes AWS has already been injected in the `loginAWS()` action
// and the credentials are correct.
exports.save = function(files, target) {
  return Promise.all(
    files.map(file => {
      return new Promise((resolve, reject) => {
        /* global AWS */
        const s3 = new AWS.S3();
        const params = {
          Bucket: IMAGES_BUCKET,
          Key: `${IMAGES_PREFIX}/${Date.now()}-${file.name}`,
          Body: file,
          ACL: "public-read",
          ContentType: file.type
        };
        s3.upload(
          params,
          (err, data) => err ? reject(err) : resolve({ ...data, target })
        );
      });
    })
  );
};
