"use strict";

const { LOCATION_CHANGE } = require("react-router-redux");

module.exports = function sights(state = { byId: {} }, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        /^\/routes\/\d+\/sights\/([-A-Za-z0-9+=]+)/g.test(
          action.payload.pathname
        )
      ) {
        let id = RegExp.$1;
        return {
          ...state,
          selected: id
        };
      } else if (state.selected) {
        return {
          ...state,
          selected: null
        };
      }

      return state;

    case "moving sight":
      return {
        ...state,
        moving: action.payload // id to feature
      };

    case "active sight":
      return {
        ...state,
        active: action.payload // id to feature
      };

    case "load routes success":
      return {
        ...state,
        byId: action.payload.features.reduce(
          (byId, feature) => {
            // pick out the sight features from each route feature
            if (feature.properties.sights) {
              feature.properties.sights.forEach(
                sight => byId[sight.id] = sight
              );
            }
            return byId;
          },
          state.byId || {}
        )
      };

    case "update selected sight property":
      if (state.selected) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [state.selected]: {
              ...state.byId[state.selected],
              properties: {
                ...state.byId[state.selected].properties,
                [action.payload.name]: action.payload.value
              }
            }
          }
        };
      }
      return state;

    case "update sight":
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload
        }
      };

    case "remove sight":
      return {
        ...state,
        byId: Object.keys(state.byId)
          .filter(id => id != action.payload.id)
          .reduce(
            (byId, id) => {
              byId[id] = state.byId[id];
              return byId;
            },
            {}
          )
      };

    case "save images success":
      let { Location, target } = action.payload[0];
      let id = state.selected;

      if (target === "sights") {
        return {
          ...state,

          // update the image of the selected sight
          byId: {
            ...state.byId,
            [id]: {
              ...state.byId[id],
              properties: {
                ...state.byId[id].properties,
                image: Location
              }
            }
          }
        };
      }

      return state;

    default:
      return state;
  }
};
