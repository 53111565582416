"use strict";

const { browserHistory } = require("react-router");

const routes = require("./data/routes");
const places = require("./data/places");
const images = require("./data/images");
const locales = require("./data/locales");

// ready states
const {
  INITIAL,
  SUCCESS,
  FAILURE,
  readyStateAction
} = require("./reducers/ready-states");

exports.activeSight = function activeSight(id) {
  return {
    type: "active sight",
    payload: id
  };
};

exports.movingSight = function movingSight(id) {
  return {
    type: "moving sight",
    payload: id
  };
};

exports.createSight = function createSight(position) {
  if (
    !position ||
    !Array.isArray(position) ||
    isNaN(position[0]) ||
    isNaN(position[1])
  ) {
    throw new Error("invalid sight position");
  }
  return (dispatch, getState) => {
    // first create the sight
    const id = generateID();
    dispatch(
      exports.updateSight({
        id,
        type: "Feature",
        properties: { id },
        geometry: {
          type: "Point",
          coordinates: position
        }
      })
    );

    // then redirect to it
    const state = getState();
    browserHistory.push(`/routes/${state.routes.selected}/sights/${id}`);
  };
};

exports.updateSight = function updateSight(feature) {
  if (!feature || !(feature.id || feature.properties.id)) {
    throw new Error("invalid sight feature. must have id.");
  }
  return {
    type: "update sight",
    payload: {
      ...feature,
      id: feature.id || feature.properties.id
    }
  };
};

exports.removeSight = function removeSight(feature) {
  if (!feature || !(feature.id || feature.properties.id)) {
    throw new Error("invalid sight feature. must have id.");
  }
  return {
    type: "remove sight",
    payload: {
      id: feature.id || feature.properties.id
    }
  };
};

exports.removeSightAndRedirect = function removeSightAndRedirect(feature) {
  return (dispatch, getState) => {
    dispatch(exports.removeSight(feature));

    // then redirect to the list of sights
    const state = getState();
    browserHistory.push(`/routes/${state.routes.selected}/sights`);
  };
};

exports.updateSelectedSightProperty = function updateSelectedSightProperty(
  name,
  value
) {
  return {
    type: "update selected sight property",
    payload: { name, value }
  };
};

exports.updateFilter = function updateFilter(filter) {
  return {
    type: "update filter",
    payload: filter
  };
};

exports.highlightRoute = function highlightRoute(id) {
  return {
    type: "highlight route",
    payload: { id }
  };
};

exports.focusRoute = function focusRoute(id) {
  return {
    type: "focus route",
    payload: { id }
  };
};

exports.highlightPlace = function highlightPlace(id) {
  return {
    type: "highlight place",
    payload: { id }
  };
};

exports.focusPlace = function focusPlace(id) {
  return {
    type: "focus place",
    payload: { id }
  };
};

exports.updateLanguage = function updateLanguage(lang) {
  /* eslint-env browser */
  window.localStorage.language = lang;
  return {
    type: "update language",
    payload: lang
  };
};

exports.updateInfoArea = function infoArea(visible) {
  return {
    type: "info visibility",
    payload: visible
  };
};

exports.toggleAdmin = function toggleAdmin(enabled) {
  return {
    type: "toggle admin",
    payload: enabled
  };
};

exports.updateRouteInfo = function updateRouteInfo(routeId, info) {
  return {
    type: "update route info",
    payload: { routeId, info }
  };
};

exports.updatePlaceInfo = function updatePlaceInfo(placeId, info) {
  return {
    type: "update place info",
    payload: { placeId, info }
  };
};

exports.loginAWS = function loginAWS(accessKeyId, secretAccessKey) {
  return dispatch => {
    // inject the AWS SDK and set `AWS.config.update({accessKeyId, secretAccessKey});`
    return injectScript(
      "injected-aws-sdk",
      "https://sdk.amazonaws.com/js/aws-sdk-2.2.43.min.js"
    ).then(() => {
      /* global AWS */
      AWS.config.update({
        accessKeyId,
        secretAccessKey,
        signatureVersion: "v4",
        region: "eu-central-1"
      });

      // set an admin flag to true to disable filtering of whitelisted places and show
      // little admin toggle buttons in the listing
      return dispatch(exports.toggleAdmin(true));
    });
  };
};

exports.saveImages = readyStateAction("save images", images.save);
exports.loadPlaces = readyStateAction("load places", places.load);
exports.loadRoutes = readyStateAction("load routes", routes.load);
exports.loadRoute = readyStateAction("load route", routes.load);
exports.saveRoutesInfo = readyStateAction("save routes info", routes.save);
exports.loadWhitelist = readyStateAction(
  "load whitelist",
  places.loadWhitelist
);
exports.saveWhitelist = readyStateAction(
  "save whitelist",
  places.saveWhitelist
);
exports.loadPlacesInfo = readyStateAction(
  "load places info",
  places.loadPlacesInfo
);
exports.savePlacesInfo = readyStateAction(
  "save places info",
  places.savePlacesInfo
);

exports.saveWhitelistIfNeeded = function saveWhitelistIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();
    if (state.whitelist.isDirty) {
      return dispatch(exports.saveWhitelist(state.whitelist.placeById));
    }
    return Promise.resolve();
  };
};

exports.savePlacesInfoIfNeeded = function savePlacesInfoIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();

    if (state.places.isDirty) {
      return dispatch(exports.savePlacesInfo(state.places.infoById));
    }

    return Promise.resolve();
  };
};
exports.saveRoutesInfoIfNeeded = function saveRoutesInfoIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();

    if (state.routes.isDirty) {
      return dispatch(exports.fetchRoutesIfNeeded()).then(() => {
        // make sure we have an updated routes list
        let state = getState();

        let info = {};
        Object.keys(state.routes.byId)
          .map(id => state.routes.byId[id])
          .forEach(feature => {
            let featureInfo = {
              image: feature.properties.image
            };

            // only save some of the route properties (for each locale)
            Object.keys(locales).forEach(lang => {
              featureInfo[`name_${lang}`] = feature.properties[`name_${lang}`];
              featureInfo[`sortName_${lang}`] = feature.properties[
                `sortName_${lang}`
              ];
              featureInfo[`description_${lang}`] = feature.properties[
                `description_${lang}`
              ];
              featureInfo[`short_description_${lang}`] = feature.properties[
                `short_description_${lang}`
              ];
              featureInfo[`image_caption_${lang}`] = feature.properties[
                `image_caption_${lang}`
              ];
            });

            // include any facets (not language dependent)
            Object.keys(state.filters.facets).forEach(facet => {
              featureInfo[facet] = feature.properties[facet];
            });

            // also include any sights assigned to the route
            if (feature.properties.sights) {
              featureInfo.sights = feature.properties.sights.map(
                id => state.sights.byId[id]
              );
            }

            info[feature.id] = featureInfo;
          });
        return dispatch(exports.saveRoutesInfo(info));
      });
    }

    return Promise.resolve();
  };
};

exports.toggleWhitelist = function toggleWhitelist(placeId, whitelisted) {
  return {
    type: "toggle whitelist",
    payload: {
      placeId,
      whitelisted
    }
  };
};

exports.toggleCertified = function toggleCertified(placeId, certified) {
  return {
    type: "toggle certified",
    payload: {
      placeId,
      certified
    }
  };
};

exports.fetchRoutesIfNeeded = function fetchRoutesIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();

    if (!state.routes.byId) {
      return dispatch(exports.loadRoutes());
    }

    return Promise.resolve();
  };
};

exports.fetchWhitelistIfNeeded = function fetchWhitelistIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();

    if (!state.whitelist.placeById) {
      return dispatch(exports.loadWhitelist());
    }

    return Promise.resolve();
  };
};

exports.fetchPlacesInfoIfNeeded = function fetchPlacesInfoIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();

    if (!state.places.infoById) {
      return dispatch(exports.loadPlacesInfo());
    }

    return Promise.resolve();
  };
};

exports.fetchPlacesIfNeeded = function fetchPlacesIfNeeded() {
  return (dispatch, getState) => {
    let state = getState();

    if (
      state.routes.selected &&
      [INITIAL, SUCCESS, FAILURE].indexOf(state.places.readyState) !== -1
    ) {
      return Promise.all([
        dispatch(exports.fetchRoutesIfNeeded()),
        dispatch(exports.fetchWhitelistIfNeeded()),
        dispatch(exports.fetchPlacesInfoIfNeeded())
      ]).then(() => {
        state = getState();
        let feature = state.routes.byId &&
          state.routes.byId[state.routes.selected];
        return dispatch(
          exports.loadPlaces(feature, {
            types: state.places.types,
            whitelist: state.views.isAdmin ? null : state.whitelist.placeById
          })
        );
      });
    }

    return Promise.resolve();
  };
};

exports.showImageViewer = function showImageViewer(imageList, current) {
  return {
    type: "show image viewer",
    payload: {
      list: imageList,
      current: current
    }
  };
};

function generateID() {
  /* eslint-env browser */
  let i = Math.random() * 1e6 | 0;
  return window.btoa(i).replace(/=*$/, "").slice(0, 6);
}

function injectScript(id, src) {
  let script = document.getElementById(id);
  if (script) {
    return Promise.resolve(script);
  } else {
    return new Promise(function(resolve, reject) {
      script = document.createElement("script");
      script.id = id;
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.querySelector("head").appendChild(script);
    });
  }
}
