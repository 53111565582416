"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const { translate } = require("react-translate");
const shallowCompare = require("react-addons-shallow-compare");

const Places = require("../data/places");
const imgRsrv = require("../../lib/img-wesrv");

const StyleGlobals = require("../styles/globals");

class BikePlaceListItem extends React.Component {
  static displayName = "BikePlaceListItem";

  state = {
    sources: (this.props.place.photos || [])
      .map(photo => Places.placeImage(photo.photo_reference))
  };

  styles = () => {
    return ReactCSS(this.classes(), { ...this.props, ...this.state });
  };

  classes = () => {
    let { toggleWhitelisted, style, onEdit } = this.props;
    return {
      default: {
        article: {
          margin: `0 0 0`,
          padding: `${StyleGlobals.dimen24} ${onEdit ? "120px" : StyleGlobals.dimen24} ${StyleGlobals.dimen24} ${StyleGlobals.dimen24}`,
          transition: "background .2s",
          backfaceVisibility: "hidden",
          position: "relative",
          border: 0,
          background: StyleGlobals.bgColors.main,
          borderBottom: `1px solid ${StyleGlobals.borderColors.lightGrey}`,
          ...style
        },
        media: {
          position: "relative",
          flex: "0 0 auto",
          width: "30%",
          marginRight: StyleGlobals.dimen24
        },
        figure: {
          padding: 0,
          margin: 0,
          height: 0,
          paddingBottom: "70%",
          width: "100%",
          lineHeight: 0,
          position: "relative",
          background: "#EAEAEA"
        },
        textContainer: {
          flex: "1 1 auto",
          overflow: "hidden"
        },
        img: {
          width: "100%",
          height: "100%",
          position: "absolute",
          lineHeight: 0,
          transition: "box-shadow .2s",
          objectFit: "cover",
          cursor: this.state.sources.length > 0 ? "pointer" : "inherit"
        },
        numImgs: {
          padding: `${StyleGlobals.dimen4} ${StyleGlobals.dimen8}`,
          background: StyleGlobals.bgColors.dark,
          color: StyleGlobals.textColors.lighten,
          display: "block",
          position: "absolute",
          borderRadius: "4px",
          fontSize: "12px",
          textAlign: "center",
          lineHeight: 1.2,
          bottom: StyleGlobals.dimen4,
          left: StyleGlobals.dimen4,
          pointerEvents: "none"
        },
        numImgsBorder: {
          left: "2px",
          top: "-2px",
          border: `1px solid ${StyleGlobals.toRgba(StyleGlobals.bgColors.dark, 0.2)}`,
          background: `${StyleGlobals.toRgba(StyleGlobals.bgColors.dark, 0.4)}`,
          width: "100%",
          height: "100%",
          position: "absolute",
          borderRadius: "inherit"
        },
        numImgsText: {
          position: "relative",
          zIndex: 2
        },
        container: {
          display: "flex",
          position: "relative"
          // paddingBottom: StyleGlobals.dimen24
        },
        title: {
          fontSize: StyleGlobals.dimen16,
          fontWeight: "bold",
          color: StyleGlobals.textColors.grey,
          margin: "0 0 .4rem",
          lineHeight: 1.4
        },
        address: {
          fontSize: StyleGlobals.dimen16,
          fontStyle: "inherit",
          display: "block",
          paddingRight: StyleGlobals.dimen36,
          lineHeight: 1.2
        },
        images: {
          margin: `${StyleGlobals.dimen8} 0 0 -${StyleGlobals.dimen4}`
        },
        addressP: {
          margin: "0 0 .2rem",
          display: "block",
          color: "inherit",
          textDecoration: "none"
        },
        websiteLink: {
          color: StyleGlobals.textColors.accent,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
          maxWidth: "100%"
        },
        type: {
          position: "absolute",
          top: 0,
          right: 0
        },
        typeIcon: {
          width: 72
        },
        rating: {
          width: "97px",
          display: "block",
          position: "relative",
          margin: `${StyleGlobals.dimen16} 0`
        },
        ratingBg: {
          width: "97px",
          display: "block"
        },
        textRating: {
          position: "absolute",
          top: 0,
          right: 0,
          transform: "translateX(120%)"
        },
        userRating: {
          position: "absolute",
          left: 0,
          top: 0,
          width: `${this.props.place.rating / 5 * 100}%`,
          height: "100%",
          display: "block",
          backgroundImage: "url(/images/reviews-active.png)",
          backgroundSize: "auto 100%"
        },
        placeIcon: {
          fill: "#A6A6A6",
          stroke: "#A6A6A6",
          strokeWidth: "30px"
        },
        whitelistBtn: {
          position: "absolute",
          right: 0,
          top: 0,
          height: "100%",
          width: "100px",
          background: StyleGlobals.bgColors.dark,
          color: "#FFF",
          fontSize: StyleGlobals.dimen16,
          fontFamily: StyleGlobals.fonts.default,
          border: 0,
          outline: 0,
          cursor: "pointer"
        },
        whitelistContent: {
          transform: "rotateZ(90deg)",
          whiteSpace: "nowrap"
        },
        certifiedBtn: {
          background: StyleGlobals.bgColors.dark,
          border: 0,
          outline: 0,
          fontSize: StyleGlobals.dimen16,
          fontFamily: StyleGlobals.fonts.default,
          color: "#FFF"
        },
        showMore: {
          outline: 0,
          border: `1px solid  ${StyleGlobals.borderColors.lightGrey}`,
          padding: `${StyleGlobals.dimen12} ${StyleGlobals.dimen16}`,
          marginTop: 8,
          display: "inline-block",
          textAlign: "center",
          cursor: "pointer"
        },
        textarea: {
          fontFamily: StyleGlobals.fonts.default,
          lineHeight: 1.6,
          width: "100%",
          maxWidth: 550,
          fontSize: StyleGlobals.dimen16,
          border: `1px solid ${StyleGlobals.borderColors.main}`,
          padding: StyleGlobals.dimen12,
          marginBottom: 16
        },
        paragraph: {
          maxWidth: 800,
          lineHeight: 1.6,
          margin: 0,
          padding: "1em 0 0"
        }
      },
      isHighlighted: {
        img: {
          boxShadow: "0 0 11px 0 rgba(0,0,0,.2)"
        },
        article: {
          background: StyleGlobals.bgColors.light
        }
      },
      isFocused: {
        article: {
          borderColor: StyleGlobals.borderColors.accent
        }
      },
      isCertified: {
        article: {
          background: onEdit && toggleWhitelisted
            ? StyleGlobals.toRgba(StyleGlobals.bgColors.accent, 0.3)
            : "#FFF"
        },
        textContainer: {
          paddingRight: 80
        },
        certifiedBtn: {
          background: "#FFF",
          color: StyleGlobals.textColors.main
        }
      },
      isWhitelisted: {
        whitelistBtn: {
          background: StyleGlobals.bgColors.accent,
          color: "#FFF"
        }
      },
      showMoreHover: {
        showMore: {
          background: StyleGlobals.borderColors.lightGrey,
          border: `1px solid ${StyleGlobals.borderColors.lightGrey}`,
          color: StyleGlobals.textColors.grey
        }
      },
      onEdit: {
        article: {
          paddingRight: 120
        }
      },
      isMobile: {
        media: {
          flex: "initial",
          width: "30%",
          marginBottom: StyleGlobals.dimen24
        },
        textContainer: {
          width: "100%",
          position: "relative"
        },
        type: {
          right: 0
        },
        images: {
          display: "none"
        },
        container: {
          paddingBottom: 0
        }
      }
    };
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFocused && this.props.isFocused != nextProps.isFocused) {
      this.refs.placeArticle.parentElement.parentElement.scrollTop = this.refs.placeArticle.offsetTop +
        this.refs.placeArticle.parentElement.offsetTop;
    }
  }

  render() {
    let {
      place,
      toggleWhitelisted,
      isCertified,
      onEdit,
      t,
      isWhitelisted
    } = this.props;
    let { sources, expandInfo } = this.state;
    return (
      <article
        ref="placeArticle"
        data-id={place.place_id}
        style={{ ...this.styles().article, ...this.props.style }}
        onMouseEnter={this.onPlaceOver}
        onMouseLeave={this.onPlaceOut}
      >
        <div style={this.styles().container}>
          <div style={this.styles().media}>
            <figure style={this.styles().figure}>
              <img
                src={sources[0] ? imgRsrv(sources[0], "h=200") : ""}
                alt=""
                style={this.styles().img}
                onClick={sources.length > 0 ? this.onImageClick : undefined}
              />
              {sources.length > 0
                ? <div style={this.styles().numImgs}>
                    <span style={this.styles().numImgsText}>
                      {sources.length}
                    </span>
                    <div style={this.styles().numImgsBorder} />
                  </div>
                : ""}
            </figure>
          </div>
          <div style={this.styles().textContainer}>
            <h1 style={this.styles().title}>
              {place.name}
            </h1>
            <address style={this.styles().address}>
              <p
                style={this.styles().addressP}
                dangerouslySetInnerHTML={{ __html: place.adr_address }}
              />
              <p style={this.styles().addressP}>
                <a
                  href={
                    `tel:${place.international_phone_number ? place.international_phone_number.replace(/ /g, "") : ""}`
                  }
                >
                  {place.formatted_phone_number}
                </a>
              </p>
              <p>
                <a
                  style={{
                    ...this.styles().addressP,
                    ...this.styles().websiteLink
                  }}
                  href={place.website}
                  target="_blank"
                >
                  {place.website}
                </a>
              </p>
            </address>
            {place.rating
              ? <div style={this.styles().rating}>
                  <img
                    src="/images/reviews.png"
                    style={this.styles().ratingBg}
                  />
                  <div style={this.styles().userRating} />
                  <span style={this.styles().textRating}>
                    {place.rating} / 5
                  </span>
                </div>
              : ""}
            {!expandInfo && !onEdit && place.info.description
              ? <div
                  style={this.styles().showMore}
                  onClick={() => this.setState({ expandInfo: !expandInfo })}
                  onMouseEnter={() => this.setState({ showMoreHover: true })}
                  onMouseLeave={() => this.setState({ showMoreHover: false })}
                >
                  {t("toggleInfo")}
                </div>
              : null}
          </div>
          {isCertified
            ? <div style={this.styles().type}>
                <img
                  style={this.styles().typeIcon}
                  src="/images/icons/certified.svg"
                />
              </div>
            : null}
        </div>
        {onEdit && isWhitelisted
          ? <textarea
              onBlur={evt =>
                onEdit(place.place_id, {
                  description: evt.currentTarget.value
                })}
              style={this.styles().textarea}
              defaultValue={place.info.description}
              rows={`${place.info.description ? "5" : "1"}`}
            />
          : expandInfo
              ? <div>{this.renderWithParagraph(place.info.description)}</div>
              : null}
        {!toggleWhitelisted ? "" : this.renderToggleButtons()}
      </article>
    );
  }

  renderToggleButtons = () => {
    let { isWhitelisted, isCertified } = this.props;
    return (
      <div>
        {isWhitelisted
          ? <button
              style={this.styles().certifiedBtn}
              onClick={this.onToggleCertified}
            >
              {isCertified ? "B&B Certified" : "Mark as certified"}
            </button>
          : ""}

        <button
          style={this.styles().whitelistBtn}
          type="button"
          onClick={this.onToggleWhitelisted}
        >
          <div style={this.styles().whitelistContent}>
            {isWhitelisted ? "Is whitelisted" : "Not whitelisted"}
          </div>
        </button>
      </div>
    );
  };

  onImageClick = e => {
    this.props.showImageViewer(
      this.state.sources,
      e.currentTarget.getAttribute("src")
    );
  };

  onToggleWhitelisted = () => {
    let { place, toggleWhitelisted, isWhitelisted } = this.props;
    toggleWhitelisted(place.place_id, !isWhitelisted);
  };

  onToggleCertified = () => {
    let { place, toggleCertified, isCertified } = this.props;
    toggleCertified(place.place_id, !isCertified);
  };

  onPlaceOver = () => {
    let { place } = this.props;
    if (!this.props.onEdit && this.props.highlightPlace) {
      this.props.highlightPlace(place.place_id);
    }
  };

  onPlaceOut = () => {
    if (!this.props.onEdit && this.props.isHighlighted) {
      this.props.highlightPlace(null);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  renderWithParagraph = str => {
    let style = this.styles().paragraph;
    let l = str.length;
    return str.split("\n").map(function(item, index) {
      return <p key={l * index} style={style}>{item}</p>;
    });
  };
}

function preferredType(place) {
  const types = place.types;
  if (place.name.toLowerCase().indexOf("hotel") > -1) {
    return "hotel";
  }
  if (types.indexOf("bicycle_store") > -1) {
    return "bikeshop";
  }
  if (types.indexOf("campground") > -1) {
    return "camping";
  }
  if (types.indexOf("hostel") > -1) {
    return "hostel";
  }
  if (types.indexOf("restaurant") > -1) {
    return "restaurant";
  }
  return "hostel";
}

module.exports = translate("BikePlaceListItem")(BikePlaceListItem);
