"use strict";

const { LOCATION_CHANGE } = require("react-router-redux");

const TYPES = require("../data/types");
const {
  INITIAL,
  PENDING,
  PAYLOAD,
  SUCCESS,
  FAILURE
} = require("./ready-states");

module.exports = function places(state = { readyState: INITIAL }, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      let tab = action.payload.hash.slice(1);
      if (state.tab !== tab) {
        return {
          ...state,
          tab: tab,
          types: TYPES[tab] || TYPES["lodging"]
        };
      }
      return state;

    case "highlight place":
      if (state.highlighted === action.payload.id) {
        return state;
      }
      return {
        ...state,
        highlighted: action.payload.id
      };

    case "focus place":
      if (state.focused === action.payload.id) {
        return state;
      }
      return {
        ...state,
        focused: action.payload.id
      };

    case "load places pending":
    case "load places info pending":
      return {
        ...state,
        readyState: PENDING
      };
    case "load places failure":
    case "load places info failure":
      return {
        ...state,
        failure: action.payload,
        readyState: FAILURE
      };
    case "load places info payload":
      return {
        ...state,
        readyState: PAYLOAD,
        infoById: {
          ...state.infoById,
          [action.payload.place_id]: action.payload
        }
      };
    case "load places payload":
      return {
        ...state,
        readyState: PAYLOAD,
        byId: {
          ...state.byId,
          [action.payload.place_id]: action.payload
        }
      };
    case "load places success":
      return {
        ...state,
        readyState: SUCCESS,
        byId: action.payload.reduce(
          (byId, place) => ((byId[place.place_id] = place), byId),
          { ...state.byId }
        )
      };
    case "load places info success":
      return {
        ...state,
        readyState: SUCCESS,
        infoById: {
          ...state.infoById,
          ...action.payload
        }
      };

    case "update place info":
      const { placeId, info } = action.payload;
      return {
        ...state,
        isDirty: true,
        infoById: {
          ...state.infoById,
          [placeId]: {
            ...state.infoById[placeId],
            ...info
          }
        }
      };

    case "save places info pending":
      return {
        ...state,
        readyState: PENDING
      };

    case "save places info failure":
      return {
        ...state,
        failure: action.payload,
        readyState: FAILURE
      };

    case "save places info success":
      return {
        ...state,
        isDirty: false,
        readyState: SUCCESS
      };

    default:
      return state;
  }
};
