"use strict";

const { createSelector } = require("reselect");

const { selectedRouteSelector } = require("./routes");

const emptySights = {};

const sightsByIdSelector = createSelector(
  state => state.sights.byId || emptySights,
  byId => byId
);

const sightsFeaturesSelector = createSelector(
  selectedRouteSelector,
  sightsByIdSelector,
  (route, byId) =>
    route && route.properties.sights
      ? route.properties.sights.map(id => byId[id])
      : []
);

const sightsSelector = createSelector(sightsFeaturesSelector, features => ({
  type: "FeatureCollection",
  features
}));

const selectedSightSelector = createSelector(
  sightsByIdSelector,
  state => state.sights.selected,
  (byId, id) => byId[id]
);

const movingSightSelector = createSelector(
  sightsByIdSelector,
  state => state.sights.moving,
  (byId, moving) => moving ? byId[moving] : null
);

const activeSightSelector = createSelector(
  sightsByIdSelector,
  state => state.sights.active,
  (byId, active) => active ? byId[active] : null
);

exports.selectedSightSelector = selectedSightSelector;
exports.sightsSelector = sightsSelector;
exports.movingSightSelector = movingSightSelector;
exports.activeSightSelector = activeSightSelector;
