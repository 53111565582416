"use strict";

const PBF = require("pbf");
const geobuf = require("geobuf");
const fetch = require("isomorphic-fetch");

const INFO_KEY = "routes.info.json";
const INFO_BUCKET = "www.bedandbike.se";
const INFO_URL = "production" !== "production"
  ? `http://${INFO_BUCKET}.s3-website.eu-central-1.amazonaws.com/${INFO_KEY}`
  : `/${INFO_KEY}`;

exports.load = function() {
  return Promise.all([loadRoutes(), loadRoutesInfo()]).then(([
    geojson,
    info
  ]) => {
    geojson.features.forEach(feature => {
      if (info[feature.id]) {
        let i = info[feature.id];
        for (let k in i) {
          if (i.hasOwnProperty(k)) {
            feature.properties[k] = i[k];
          }
        }
      }
    });
    return geojson;
  });
};

exports.save = function(info) {
  return saveRoutesInfo(info);
};

function loadRoutes() {
  return fetch("/routes.pbf").then(res => res.arrayBuffer()).then(buf => {
    const routes = new PBF(buf);
    const geojson = geobuf.decode(routes);
    return Promise.resolve(geojson);
  });
}

function loadRoutesInfo() {
  return fetch(INFO_URL).then(res => res.json()).catch(() => ({})); // fallback to empty object
}

// this assumes AWS has already been injected in the `loginAWS()` action
// and the credentials are correct.
function saveRoutesInfo(info) {
  return new Promise((resolve, reject) => {
    /* global AWS */
    const s3 = new AWS.S3();
    const params = {
      Bucket: INFO_BUCKET,
      Key: INFO_KEY,
      Body: JSON.stringify(stripEmptyKeys(info), null, 2),
      ACL: "public-read",
      ContentType: "application/json"
    };
    s3.putObject(params, (err, data) => err ? reject(err) : resolve(data));
  });
}

function stripEmptyKeys(obj) {
  let o = {};
  for (let id in obj) {
    let i = obj[id];
    for (let k in i) {
      if (i.hasOwnProperty(k) && i[k]) {
        o[id] = o[id] || {};
        o[id][k] = i[k];
      }
    }
  }
  return o;
}
