"use strict";

// ready states
const { INITIAL, PENDING, SUCCESS, FAILURE } = require("./ready-states");

module.exports = function images(state = { readyState: INITIAL }, action) {
  switch (action.type) {
    case "save images pending":
      return {
        ...state,
        readyState: PENDING
      };
    case "save images failure":
      return {
        ...state,
        ...action.payload,
        readyState: FAILURE
      };
    case "save images success":
      return {
        ...state,
        readyState: SUCCESS
      };
    default:
      return state;
  }
};
