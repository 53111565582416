const React = require("react");
const { ReactCSS } = require("reactcss");
const { connect } = require("react-redux");
const actions = require("../actions");
const { fullscreenMapSelector } = require("../selectors");

const BikePlaceListItem = require("../components/BikePlaceListItem");

const withMQ = require("../mixins/mq");
const StyleGlobals = require("../styles/globals");

class PlaceFullscreenUI extends React.Component {
  static displayName = "PlaceFullscreenUI";

  state = {};

  componentDidMount() {
    this.props.onPlaceFocused(null);
    this.props.onPlaceHighlight(null);
  }

  classes = () => {
    return {
      default: {
        container: {
          position: "fixed",
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          zIndex: 10,
          opacity: 1,
          pointerEvents: "none"
        },
        background: {
          position: "absolute",
          top: 0,
          bottom: 0,
          left: "50%",
          right: 0,
          background: "rgba(0,0,0,0)",
          pointerEvents: "none",
          transition: "background-color .6s"
        },
        listItemContainer: {
          transition: "transform .3s ease-out",
          easingTimingFunction: "ease-out",
          transform: "translate3d(0,100%,0)",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: "50%",
          right: 0
        },

        listItem: {
          borderRadius: `${StyleGlobals.dimen8} ${StyleGlobals.dimen8} 0 0`,
          outline: 0,
          pointerEvents: "all",
          position: "absolute",
          left: StyleGlobals.dimen8,
          right: StyleGlobals.dimen8,
          bottom: 0
        }
      },
      mobile: {
        listItemContainer: {
          left: 0
        },
        background: {
          left: 0
        }
      },
      focused: {
        listItemContainer: {
          transform: "translate3d(0,0,0)"
        },
        background: {
          bottom: 0,
          height: "auto",
          background: "rgba(0,0,0,.3)",
          pointerEvents: "all"
        }
      },
      blur: {
        background: {
          background: "rgba(0,0,0,0)"
        },

        listItemContainer: {
          easingTimingFunction: "ease-in",
          transform: "translate3d(0,100%,0)"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(
      this.classes(),
      {
        focused: this.props.place != undefined,
        ...this.state
      },
      this.props
    );
  };

  render() {
    let { place, showImageViewer, whitelist } = this.props;
    return (
      <div style={this.styles().container}>
        <div style={this.styles().background} onClick={this.onBlur} />
        <div style={this.styles().listItemContainer}>
          {place
            ? <BikePlaceListItem
                place={place}
                style={this.styles().listItem}
                isMobile={this.state.mobile}
                isCertified={whitelist[place.place_id] === 2}
                showImageViewer={showImageViewer}
              />
            : ""}
        </div>

      </div>
    );
  }

  onBlur = () => {
    this.setState({
      blur: true
    });

    setTimeout(() => this.blurred(), 350);
  };

  blurred = () => {
    this.props.onPlaceFocused(null);
    this.props.onPlaceHighlight(null);
    this.setState({
      blur: false
    });
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onPlaceFocused: id => dispatch(actions.focusPlace(id)),
    onPlaceHighlight: id => dispatch(actions.highlightPlace(id)),
    showImageViewer: (list, current) =>
      dispatch(actions.showImageViewer(list, current))
  };
}

module.exports = connect(fullscreenMapSelector, mapDispatchToProps)(
  withMQ(StyleGlobals.breakPoints)(PlaceFullscreenUI)
);
