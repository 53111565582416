"use strict";

const React = require("react");

const MapContext = require("./MapContext");
const PropTypes = require("./PropTypes");

class Map extends React.Component {
  static displayName = "Map";

  static propTypes = {
    ...PropTypes.MapOptions,
    ...PropTypes.MapComponentEvents
  };

  state = {
    isReady: false
  };

  componentDidMount() {
    this.checkReady();
  }

  checkReady = () => {
    /* global mapboxgl */
    if (
      !this.state.isReady &&
      typeof mapboxgl !== "undefined" &&
      typeof mapboxgl.Map === "function"
    ) {
      if (
        mapboxgl.accessToken && mapboxgl.accessToken !== this.props.accessToken
      ) {
        /* eslint-disable no-console */
        console.warn(
          "mapboxgl.accessToken was already set. it's a global setting so changing it will override the previous."
        );
      } else {
        mapboxgl.accessToken = this.props.accessToken;
      }
      this.setState({
        isReady: true
      });
    } else {
      setTimeout(this.checkReady, 100);
    }
  };

  render() {
    let { isReady } = this.state;
    return (
      <div ref="map" style={this.props.style}>
        {isReady
          ? <MapContext
              options={this.props}
              div={this.refs.map}
              children={this.props.children}
            />
          : ""}
      </div>
    );
  }
}

module.exports = Map;
