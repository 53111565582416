"use strict";

const { createSelector } = require("reselect");
const featureFilter = require("feature-filter");

const emptyRoutes = {};
const selectedRouteSelector = createSelector(
  state => state.routes.byId || emptyRoutes,
  state => state.routes.selected,
  (byId, id) => byId[id]
);
const selectedRouteFeaturesSelector = createSelector(
  selectedRouteSelector,
  selected => selected && [selected]
);

const emptyFeatures = [];
const routeFeaturesSelector = createSelector(
  state => state.routes.byId,
  state => state.language.id || "en",
  (byId, locale) =>
    byId
      ? Object.keys(byId).map(id => byId[id]).sort(byName(locale))
      : emptyFeatures
);

const byName = locale => {
  const sortKey = `sortName_${locale}`;
  return (a, b) => {
    const aName = (a.properties[sortKey] || a.properties.name).toLowerCase();
    const bName = (b.properties[sortKey] || b.properties.name).toLowerCase();
    return aName < bName ? -1 : 1;
  };
};

const routeSelector = createSelector(
  state =>
    state.routes.selected || state.routes.focused || state.routes.highlighted,
  state => state.routes.byId || emptyRoutes,
  (routeId, byId) => byId[routeId]
);

// create a featureFilter function from the facets object
// i.e.
// {a: true, b: false, c: null} => ['all', ['==', 'a', true], ['==', 'b', false]]
const facetsAsFilterSelector = createSelector(
  state => state.filters.facets,
  facets =>
    featureFilter(
      Object.keys(facets).reduce(
        (filter, facet) => {
          return facets[facet]
            ? filter.concat([["==", facet, facets[facet]]])
            : filter;
        },
        ["all"]
      )
    )
);

const filteredRoutesSelector = createSelector(
  routeFeaturesSelector,
  facetsAsFilterSelector,
  (features, filter) => features.filter(feature => filter(feature))
);

const routesSelector = createSelector(
  filteredRoutesSelector,
  state => state.filters.facets,
  routeFeaturesSelector,
  (filtered, facets, features) => (filtered || features).map(f => {
      f.properties.image = f.properties.image || null;

      f.properties.facets = Object.keys(facets).reduce(
        (b, p) => {
          b[p] = f && f.properties[p] ? true : false;
          return b;
        },
        {}
      );

      return f;
    })
);

const mapRoutesSelector = createSelector(
  selectedRouteFeaturesSelector,
  filteredRoutesSelector,
  (selected, features) => ({
    type: "FeatureCollection",
    features: selected || features
  })
);

const emptyFilter = ["==", "id", ""];
const mapRouteFilterSelector = createSelector(
  routeSelector,
  route => route ? ["==", "id", route.id] : emptyFilter
);

const routeInfoSelector = createSelector(
  selectedRouteSelector,
  state => state.filters.facets,
  state => getFieldByLocale(state.language.id || "en"),
  (route, facets, getField) => ({
    name: getField(route && route.properties, "name"),
    sortName: getField(route && route.properties, "sortName"),
    image: route ? route.properties.image || "/images/routes/temp.png" : "",
    imageCaption: getField(route && route.properties, "image_caption"),
    length: route ? route.properties.length : 1,
    shortDescription: getField(route && route.properties, "short_description"),
    description: getField(route && route.properties, "description"),
    facets: Object.keys(facets).reduce(
      (m, f) => {
        m[f] = route && route.properties[f] ? true : false;
        return m;
      },
      {}
    )
  })
);

const getFieldByLocale = locale =>
  (obj, field) => obj ? obj[`${field}_${locale}`] || obj[field] || "" : "";

exports.routeSelector = routeSelector;
exports.routesSelector = routesSelector;
exports.selectedRouteSelector = selectedRouteSelector;
exports.mapRoutesSelector = mapRoutesSelector;
exports.mapRouteFilterSelector = mapRouteFilterSelector;
exports.routeInfoSelector = routeInfoSelector;
