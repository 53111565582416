exports.lodging = ["lodging", "campground", "rv_park"];
exports.sights = ["museum", "park", "zoo", "amusement_park", "aquarium"];
exports.misc = [
  "bar",
  "cafe",
  "art_gallery",
  "library",
  "laundry",
  "bicycle_store",
  "convenience_store",
  "post_office",
  "train_station",
  "subway_station"
];
