"use strict";

const React = require("react");

const PropTypes = require("./PropTypes");
const dasherize = require("../../../lib/dasherize");
const { compareFilters, compareLayoutOrPaint } = require("./utils");

class Layer extends React.Component {
  static displayName = "Layer";
  static propTypes = PropTypes.LayerOptions;

  static contextTypes = {
    map: React.PropTypes.object
  };

  componentDidMount() {
    let { map } = this.context;
    let { insertBefore } = this.props;
    map.addLayer(dasherizeKeys(this.props), insertBefore);
  }

  componentWillUnmount() {
    let { map } = this.context;
    if (map.getLayer(this.props.id)) {
      map.removeLayer(this.props.id);
    }
  }

  componentWillUpdate(nextProps) {
    let { map } = this.context;
    let { id } = this.props;

    // TODO update paint class properties with `map.setPaintProperty(layer, name, value, [klass])`
    changedKeys(this.props.paint, nextProps.paint).forEach(name =>
      map.setPaintProperty(id, dasherize(name), nextProps.paint[name]));

    // update layout with `map.setLayoutProperty(layer, name, value)`
    changedKeys(this.props.layout, nextProps.layout).forEach(name =>
      map.setLayoutProperty(id, dasherize(name), nextProps.layout[name]));

    // update zoom range with `map.setLayerZoomRange(layerId, minzoom, maxzoom)`
    if (
      this.props.minzoom !== nextProps.minzoom ||
      this.props.maxzoom !== nextProps.maxzoom
    ) {
      map.setLayerZoomRange(id, nextProps.minzoom, nextProps.maxzoom);
    }

    // update filter with `map.setFilter(layer, filter)`
    if (compareFilters(this.props.filter, nextProps.filter)) {
      map.setFilter(id, nextProps.filter);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.id !== nextProps.id) {
      /* eslint-disable no-console */
      console.error(
        "layer id should not be changed. ignoring update for (%s -> %s).",
        this.props.id,
        nextProps.id
      );
      return false;
    }

    // TODO also compare the paint classes
    return compareLayoutOrPaint(this.props.paint, nextProps.paint) ||
      compareLayoutOrPaint(this.props.layout, nextProps.layout) ||
      compareFilters(this.props.filter, nextProps.filter);
  }

  render() {
    return null;
  }
}

function changedKeys(curr = {}, next = {}) {
  let keys = {};
  for (let k in curr)
    keys[k] = 1;
  for (let k in next)
    keys[k] = 1;
  return Object.keys(keys).filter(name => {
    if (Array.isArray(curr[name])) {
      if (curr[name].length !== next[name].length) {
        return true;
      } else {
        let a = curr[name];
        let b = next[name];
        for (let i = 0; i < a.length; i++) {
          if (a[i] !== b[i]) {
            return true;
          }
        }
      }
      return false;
    }
    return curr[name] !== next[name];
  });
}

function dasherizeKeys(obj, replacement = "-") {
  const out = {};
  for (let k in obj) {
    // rewrite "references" prop before passing it into addLayer
    if (k === "references") {
      out["ref"] = obj["references"];
      continue;
    }

    // ignore "insertBefore" as it's a second argument instead
    if (k === "insertBefore") {
      continue;
    }

    // special case for paint keys (dashes are classes which should be separated with .)
    if (k.indexOf("paint") === 0) {
      replacement = ".";
    }
    if (Object.prototype.toString.call(obj[k]) === "[object Object]") {
      out[dasherize(k, replacement)] = dasherizeKeys(obj[k]);
    } else {
      out[dasherize(k, replacement)] = obj[k];
    }
  }
  return out;
}

module.exports = Layer;
