"use strict";

const { createSelector } = require("reselect");
const routes = require("./routes");
const places = require("./places");
const sights = require("./sights");

const bikeRoutePropsSelector = createSelector(
  state => state.places.highlighted,
  state => state.places.focused,
  state => state.views.isAdmin,
  state => state.views.imageViewer,
  state => state.whitelist.placeById,
  state => state.whitelist.isDirty || false,
  state => state.routes.isDirty || false,
  places.placesCount,
  state => Math.min(state.routes.readyState, state.places.readyState),
  (
    highlightedPlace,
    focusedPlace,
    isAdmin,
    imageViewer,
    whitelist,
    isWhitelistDirty,
    isRouteDirty,
    placesCount,
    readyState
  ) => ({
    highlightedPlace,
    focusedPlace,
    isAdmin,
    imageViewer,
    whitelist,
    isWhitelistDirty,
    isRouteDirty,
    readyState,
    placesCount
  })
);

const bikeRoutesPropsSelector = createSelector(
  state => state.filters.facets,
  state => state.routes.error,
  state => state.routes.highlighted,
  state => state.routes.focused,
  state => state.routes.readyState,
  (facets, error, highlightedRoute, focusedRoute, readyState) => ({
    facets,
    error,
    highlightedRoute,
    focusedRoute,
    readyState
  })
);

const bikeMapPropsSelector = createSelector(
  state => state.filters.bbox,
  state => state.routes.highlighted,
  state => state.places.highlighted,
  state => state.routes.focused,
  state => state.places.focused,
  routes.mapRouteFilterSelector,
  places.mapPlaceFilterSelector,
  state => state.views.isAdmin,
  sights.movingSightSelector,
  sights.activeSightSelector,
  (bbox, highlightedRoute, highlightedPlace, focusedRoute, focusedPlace, routeFilter, placeFilter, isAdmin, movingSight, activeSight) => ({
    bbox,
    highlightedRoute,
    highlightedPlace,
    focusedRoute,
    focusedPlace,
    routeFilter,
    placeFilter,
    isAdmin,
    movingSight,
    activeSight
  })
);

exports.mapSelector = createSelector(
  routes.selectedRouteSelector,
  places.placesGeoJSONSelector,
  routes.mapRoutesSelector,
  bikeMapPropsSelector,
  routes.routeSelector,
  sights.sightsSelector,
  (selected, places, routes, props, route, sights) => ({
    ...props,
    places,
    sights,
    routes,
    route,
    selected
  })
);

exports.bikeRouteSelector = createSelector(
  routes.selectedRouteSelector,
  bikeRoutePropsSelector,
  places.placesVisibleSelector,
  routes.routeInfoSelector,
  (route, props, places, routeInfo) => ({ ...props, route, places, routeInfo })
);

exports.bikeRoutesSelector = createSelector(
  routes.routesSelector,
  bikeRoutesPropsSelector,
  (routes, props) => ({ ...props, routes })
);

exports.uploadImageSelector = createSelector(
  state => state.images.readyState,
  readyState => ({ readyState })
);

exports.fullscreenMapSelector = createSelector(
  state => state.whitelist.placeById,
  places.placeSelector,
  (whitelist, place) => ({
    whitelist,
    place
  })
);

exports.placesSelector = createSelector(
  routes.selectedRouteSelector,
  bikeRoutePropsSelector,
  places.placesVisibleSelector,
  (route, props, places) => ({ ...props, route, places })
);

exports.sightsSelector = createSelector(
  sights.sightsSelector,
  routes.selectedRouteSelector,
  state => state.views.isAdmin,
  (sights, route, isAdmin) => ({ sights, route, isAdmin })
);

exports.sightEditSelector = createSelector(
  sights.selectedSightSelector,
  sight => ({ sight })
);
