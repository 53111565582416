"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");

const StyleGlobals = require("../styles/globals");

class Login extends React.Component {
  static displayName = "Login";

  classes = () => {
    return {
      default: {
        form: {
          width: "100%",
          background: StyleGlobals.bgColors.main,
          borderTop: `1px solid ${StyleGlobals.borderColors.grey}`,
          padding: StyleGlobals.dimen24,
          display: "flex",
          alignContent: "stretch",
          flex: "1 0 auto"
        },
        input: {
          padding: StyleGlobals.dimen16,
          border: `1px solid ${StyleGlobals.borderColors.accent}`,
          marginRight: StyleGlobals.dimen4,
          fontSize: "14px",
          fontFamily: StyleGlobals.fonts.default
        },
        btn: {
          background: StyleGlobals.borderColors.accent,
          color: "#FFF",
          height: "100%",
          border: 0,
          outline: 0,
          padding: "18px",
          fontSize: "14px",
          fontFamily: StyleGlobals.fonts.default,
          cursor: "pointer"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes());
  };

  render() {
    return (
      <form style={this.styles().form} onSubmit={this.props.onSubmit}>
        <input
          style={this.styles().input}
          type="text"
          name="accessKey"
          required
          placeholder="Username"
        />
        <input
          style={this.styles().input}
          type="password"
          name="secret"
          required
          placeholder="Password"
          value="SLVd8dfOaXQ9x5nC7r6Ar2gCQL5LcBUyjYj8rRH5"
          readOnly
        />
        <button style={this.styles().btn}>Verify auth</button>
      </form>
    );
  }
}

module.exports = Login;
