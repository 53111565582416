"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");

const withMQ = require("../mixins/mq");
const StyleGlobals = require("../styles/globals");

class ImageViewer extends React.Component {
  static displayName = "ImageViewer";

  state = {
    current: this.props.current
  };

  classes = () => {
    return {
      default: {
        section: {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
          display: "flex",
          flexAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        },
        background: {
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: "rgba(0,0,0,.8)"
        },
        thumbnails: {
          width: "100%",
          bottom: 0,
          zIndex: 1,
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          background: StyleGlobals.bgColors.dark,
          padding: StyleGlobals.dimen12,
          textAlign: "center"
        },
        image: {
          position: "relative",
          zIndex: 1,
          maxHeight: "50vh",
          maxWidth: "100%",
          width: "auto"
        },
        imageContainer: {
          textAlign: "center"
        }
      },
      mobile: {
        closeBtn: {
          top: StyleGlobals.dimen56,
          right: StyleGlobals.dimen16
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), { mobile: this.state.mobile });
  };

  componentDidMount() {
    /* eslint-env browser */
    document.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    /* eslint-env browser */
    document.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = e => {
    switch (e.which) {
      case 65: // a
      case 37: // left
        return this.onPrevThumb(e);
      case 68: // d
      case 39: // right
        return this.onNextThumb(e);
      case 27: // esc
        return this.props.onClose();
    }
  };

  render() {
    let { list, onClose } = this.props;

    return (
      <section style={this.styles().section}>
        <div style={this.styles().background} onClick={onClose} />
        <div style={this.styles().imageContainer}>
          <img src={this.state.current} style={this.styles().image} />
        </div>
        <div style={this.styles().thumbnails}>
          {list.map(src => (
            <ImageViewThumb
              key={src}
              src={src}
              isCurrent={src === this.state.current}
              onClick={this.onThumbClick}
            />
          ))}
        </div>
        <CloseBtn onClick={onClose} style={this.styles().closeBtn} />
      </section>
    );
  }

  onPrevThumb = () => {
    let { list } = this.props;
    let idx = list.indexOf(this.state.current);
    this.setState({
      current: list[idx === 0 ? list.length - 1 : (idx - 1) % list.length]
    });
  };

  onNextThumb = () => {
    let { list } = this.props;
    let idx = list.indexOf(this.state.current);
    this.setState({
      current: list[Math.abs(idx + 1) % list.length]
    });
  };

  onThumbClick = e => {
    this.setState({
      current: e.currentTarget.querySelector("img").getAttribute("src")
    });
  };
}

module.exports = withMQ(StyleGlobals.breakPoints)(ImageViewer);

class ImageViewThumb extends React.Component {
  static displayName = "ImageViewThumb";

  classes = () => {
    return {
      default: {
        thumbnail: {
          height: "50px",
          display: "inline-block",
          cursor: "pointer",
          margin: StyleGlobals.dimen4
        },
        img: {
          height: "100%",
          width: "auto"
        }
      },
      isHover: {
        img: {
          opacity: 0.6
        }
      },
      isCurrent: {
        thumbnail: {
          outline: `3px solid ${StyleGlobals.bgColors.highlighted}`
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state, this.props);
  };

  render() {
    return (
      <div onClick={this.props.onClick} style={this.styles().thumbnail}>
        <img
          style={this.styles().img}
          src={this.props.src}
          onMouseEnter={() => this.setState({ isHover: true })}
          onMouseLeave={() => this.setState({ isHover: false })}
        />
      </div>
    );
  }
}

class CloseBtn extends React.Component {
  static displayName = "CloseBtn";

  classes = () => {
    return {
      default: {
        btn: {
          position: "absolute",
          right: StyleGlobals.dimen36,
          top: StyleGlobals.dimen36,
          background: "transparent",
          border: 0,
          outline: 0,
          width: StyleGlobals.dimen36,
          height: StyleGlobals.dimen36,
          padding: StyleGlobals.dimen8,
          cursor: "pointer",
          opacity: 0.8,
          transition: "opacity .3s"
        },
        btnImg: {
          width: "100%",
          height: "auto",
          transition: "transform .2s ease-out"
        }
      },
      isHover: {
        btn: {
          opacity: 1
        },
        btnImg: {
          transform: "scale(1.2)"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state);
  };

  render() {
    return (
      <button
        style={{ ...this.styles().btn, ...this.props.style }}
        type="button"
        onClick={this.props.onClick}
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
      >
        <img style={this.styles().btnImg} src="/images/svg/close.svg" />
      </button>
    );
  }
}
