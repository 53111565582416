"use strict";

const React = require("react");

const PropTypes = require("./PropTypes");

class Navigation extends React.Component {
  static displayName = "Navigation";

  static propTypes = {
    ...PropTypes.NavigationOptions
  };

  static contextTypes = {
    map: React.PropTypes.object
  };

  state = {
    navigation: new mapboxgl.Navigation(this.props)
  };

  componentDidMount() {
    let { map } = this.context;
    let { navigation } = this.state;
    map.addControl(navigation);
  }

  componentWillUnmount() {
    let { navigation } = this.state;
    navigation.remove();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return null;
  }
}

module.exports = Navigation;
