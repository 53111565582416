"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const { translate } = require("react-translate");
const shallowCompare = require("react-addons-shallow-compare");

const imgRsrv = require("../../lib/img-wesrv");
const StyleGlobals = require("../styles/globals");
const FilterButton = require("./FilterButton");
const UploadImage = require("./../containers/UploadImage");

const withMQ = require("../mixins/mq");

class BikeRouteInfo extends React.Component {
  static displayName = "BikeRouteInfo";

  static contextTypes = {
    locale: React.PropTypes.string
  };

  state = {
    hoveredFacet: null
  };

  classes = () => {
    return {
      default: {
        locationContainer: {
          padding: 0,
          paddingLeft: StyleGlobals.dimen24,
          paddingRight: StyleGlobals.dimen24
        },
        top: {
          position: "relative",
          width: "100%",
          flex: "1 0 auto",
          height: 0,
          paddingTop: 0,
          paddingBottom: "44%",
          marginBottom: StyleGlobals.dimen24
        },
        topImg: {
          width: "100%",
          height: "100%",
          position: "absolute",
          objectFit: "cover",
          opacity: 0,
          animation: "opacityAnimation .3s .5s forwards"
        },
        paragraph: {
          margin: `0 0 1.4em`,
          opacity: 0,
          animation: "opacityAnimation .3s .8s forwards"
        },
        routeName: {
          fontSize: StyleGlobals.dimen48,
          fontWeight: "normal",
          margin: `${StyleGlobals.dimen24} 0 ${StyleGlobals.dimen16}`,
          opacity: 0,
          animation: "topOpacityAnimation .25s .5s forwards"
        },

        facets: {
          width: "100%",
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          paddingTop: StyleGlobals.dimen8,
          paddingLeft: StyleGlobals.dimen24,
          paddingRight: StyleGlobals.dimen12,
          paddingBottom: StyleGlobals.dimen16,
          flexWrap: "wrap"
        },
        arrow: {
          margin: `0 ${StyleGlobals.dimen24} 0`
        },
        locationArticle: {
          display: "flex",
          flexDirection: "column"
        },
        mapContainer: {
          flex: "0 1 auto",
          width: "25%",
          padding: StyleGlobals.dimen24
        },
        mapImg: {
          width: "100%"
        },
        textContainer: {
          padding: `0 0 0`,
          flex: "0 1 auto",
          width: "100%"
        },
        content: {
          padding: `0 0 0`
        },
        description: {
          lineHeight: 1.6,
          width: "100%",
          maxWidth: 550,
          fontSize: StyleGlobals.dimen16
        },
        shortDescription: {
          margin: `${StyleGlobals.dimen12} 0`,
          width: "100%",
          fontSize: StyleGlobals.dimen16
        },
        labelText: {
          display: "block",
          marginBottom: StyleGlobals.dimen8
        },
        editable: {
          border: `1px solid ${StyleGlobals.borderColors.main}`,
          padding: StyleGlobals.dimen12,
          fontFamily: StyleGlobals.fonts.default,
          width: "100%"
        },
        editableContainer: {
          marginBottom: StyleGlobals.dimen36,
          background: StyleGlobals.bgColors.light
        },
        filterButton: {
          margin: StyleGlobals.dimen4
        },
        imageCaption: {
          position: "absolute",
          right: 24,
          bottom: 24,
          color: "#FFF",
          textShadow: "1px 1px 1px #000"
        },
        headerContainer: {
          position: "relative"
        }
      },
      mobile: {
        headerContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        },
        routeName: {
          fontSize: StyleGlobals.dimen24,
          margin: 0
        },
        header: {
          flex: "1 3 auto",
          margin: 0,
          paddingBottom: "25%"
        },
        textContainer: {
          flex: "1 1 auto"
        },
        headerText: {
          display: "block",
          padding: 0
        },
        routeMeta: {
          margin: 0,
          fontSize: StyleGlobals.dimen16
        },
        headerImg: {
          width: "80px",
          height: "80px",
          borderRadius: "40px"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state);
  };

  render() {
    let {
      image,
      imageCaption,
      description,
      shortDescription,
      facets,
      onEdit
    } = this.props;
    let { locale } = this.context;
    let containerStyle = onEdit ? { ...this.styles().editableContainer } : {};
    return (
      <div style={{ ...containerStyle, ...this.styles().locationContainer }}>
        <article style={this.styles().locationArticle}>
          <header style={this.styles().headerContainer}>
            <div style={this.styles().top}>
              {onEdit
                ? <UploadImage
                    target="routes"
                    style={this.styles().topImg}
                    src={image}
                  />
                : <img
                    style={this.styles().topImg}
                    src={imgRsrv(image, "h=500")}
                  />}
            </div>
            {onEdit
              ? <input
                  type="text"
                  style={{
                    ...this.styles().shortDescription,
                    ...this.styles().editable
                  }}
                  onChange={e =>
                    onEdit({
                      [`image_caption_${locale}`]: e.currentTarget.value
                    })}
                  value={imageCaption}
                  placeholder="Photo caption / credit"
                />
              : <p style={this.styles().imageCaption}>{imageCaption}</p>}
            <div style={this.styles().textContainer} />
          </header>
          <div style={this.styles().content}>
            {onEdit
              ? <input
                  type="text"
                  style={{
                    ...this.styles().shortDescription,
                    ...this.styles().editable
                  }}
                  onChange={e =>
                    onEdit({
                      [`short_description_${locale}`]: e.currentTarget.value
                    })}
                  value={shortDescription}
                  placeholder="Short description"
                />
              : ""}
            {onEdit
              ? <textarea
                  style={{
                    ...this.styles().description,
                    ...this.styles().editable
                  }}
                  onChange={e =>
                    onEdit({
                      [`description_${locale}`]: e.currentTarget.value
                    })}
                  value={description}
                  rows="10"
                />
              : <div style={this.styles().description}>
                  {this.renderWithParagraph(description)}
                </div>}
          </div>
          {onEdit
            ? <div style={this.styles().facets}>
                {Object.keys(facets).map(facet => (
                  <FilterButton
                    key={facet}
                    name={facet}
                    isHover={this.state.hoveredFacet === facet}
                    isActive={facets[facet]}
                    onHover={() => this.setState({ hoveredFacet: facet })}
                    onChange={() => onEdit({ [facet]: !facets[facet] })}
                    style={this.styles().filterButton}
                  />
                ))}
              </div>
            : ""}
        </article>
      </div>
    );
  }

  renderWithParagraph = str => {
    let style = this.styles().paragraph;
    let l = str.length;
    return str.split("\n").map(function(item, index) {
      return <p key={l * index} style={style}>{item}</p>;
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }
}

module.exports = translate("BikeRouteInfo")(
  withMQ(StyleGlobals.breakPoints)(BikeRouteInfo)
);
