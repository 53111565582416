/* eslint-env browser */
"use strict";

const React = require("react");
const { render } = require("react-dom");
const { Provider } = require("react-redux");
const { syncHistoryWithStore } = require("react-router-redux");
const { Router, Route, IndexRoute, browserHistory } = require("react-router");

const configure = require("./store");
const availableLocales = require("./data/locales");
const App = require("./containers/App");
const About = require("./containers/About");
const BikeRoute = require("./containers/BikeRoute");
const BikeRoutes = require("./containers/BikeRoutes");
const PlaceList = require("./containers/PlaceList");
const ReactGA = require("react-ga");

function getLocale(defaultLocale = "en") {
  /* eslint-env browser */
  if (window.localStorage.language) {
    // saved in the "update language" action
    return window.localStorage.language;
  } else if (window.navigator.languages) {
    for (let i = 0; i < window.navigator.languages.length; i++) {
      let lang = window.navigator.languages[i];
      if (lang.slice(0, 2) in availableLocales) {
        return lang.slice(0, 2);
      }
    }
  }
  return defaultLocale;
}

const store = configure({
  language: {
    id: getLocale()
  }
});
const history = syncHistoryWithStore(browserHistory, store);

if ("production" !== "production") {
  global.Perf = require("react-addons-perf");
  global.store = store;
}

ReactGA.initialize("UA-96655988-1");
ReactGA.pageview(window.location.pathname);

render(
  <Provider store={store}>
    <Router history={history}>
      <Route
        path="/"
        component={App}
        onChange={e => ReactGA.pageview(window.location.pathname)}
      >
        <IndexRoute component={BikeRoutes} />
        <Route path="/routes/:id" component={BikeRoute}>
          <Route path="/routes/:id/map" />
          <Route path="/routes/:id/lodging" component={PlaceList} />
        </Route>
        <Route path="/about" component={About} />
      </Route>
    </Router>
  </Provider>,
  document.getElementById("app")
);
