module.exports={
  "locale": "en",
  "About": {
    "title": "Bed and Bike is a collaborative effort between Cykelfrämjandet and selected hotels, hostels and other types of holiday accommodations",
    "intro": " <h3>Our partners offer their cycling guests services to meet their special needs.</h3> <p>If you are a cyclist, Bed & Bike is a fantastic service to ensure that both you and your bike are pampered during your overnight visit. At night, your bike will be kept safe and secure. You will have access to maintenance tools and a pump. You can make a packed lunch in the morning.</p><p>You want to be able to stay at quality accommodations without needing to plan your trip in detail well in advance. Weather and whimsy can affect your bike holiday. And perhaps you would like to meet others as interested in cycling as you… </p>",
    "partners": "<h3>Would you like to join us as a partner?</h3><p>If you run a holiday accommodation featured here and would like to change the information presented on this site, please update your location on Google Places. To influence your users' rating, ask cycling guests to rate you on <a href='https://www.google.se/intl/sv/business/' target='_blank'> Google places </a>.</p><p>Certified accommodations gain publicity through this website and offer assurances for cycling tourists. Bed and Bike claims no responsibility for the validity of the information related to your accommodation.</p> <p>For more information and to inquire about becoming certified, please contact us:</p> <ul> <li> E-mail: <a href='mailto:info@bedandbike.se'>info@bedandbike.se</a> </li> <li> Phone: <a href='tel:+46812151331'>+46 8-121 513 31</a>, Phone hours weekdays 10-13 </li> </ul>",
    "cykelframjandetTitle": "Cykelfrämjandet is the principal of Bed & Bike in Sweden.",
    "cykelframjandetBody": "<p><a href='http://cykelframjandet.se/' target='_blank'>Cykelfrämjandet</a> is Sweden’s largest cycling organization. We are an apolitical nonprofit membership organization that has existed for over 80 years. We are established nationwide with 30 local chapters. Our goal is to make Sweden a real cycling nation.</p> <p>We work locally, regionally and nationally to promote cycling to improve the environmentl and health and to promote safe roads. Cykelfrämjandet started Bed & Bike in Sweden in 2012.</p><p>The idea is borrowed from the German organization <a href='http://www.bettundbike.de/' target='_blank'>Bett und Bike</a> which this year includes more than 5400 accommodations. Since our launch we have cooperated with the Swedish Transport Administration to develop Sweden’s rapidly growing bicycle tourism.</p>",
    "tech": "<h3>Technical information</h3> <p>This service is developed by <a href='http://department.se' >Department</a>. The service uses <a href='https://www.google.se/intl/sv/business/' target='_blank'>Google Places</a> for location information and route information from <a href='https://www.openstreetmap.org/' target='_blank'>OpenStreetMaps</a>.</p><p>All map information is downloaded from the <a href='https://www.openstreetmap.org/' target='_blank'>OpenStreetMaps</a> where users can help to maintain accurate data.</p>",

  },
  "FilterOptions": {
    "showFilter": "Show filters",
    "hideFilter": "Hide filters"
  },
  "BikeRouteList": {
    "noMatch": "No routes are matching your critera and map bounds.",
    "noMatchAgain": "Change you selection to see routes in the area."
  },
  "BikePlaceList":{
    "totalLodging": "Lodgings along the route",
    "visibleLodging": "Lodgings visible on map"
  },
  "BikePlaceListItem":{
    "toggleInfo": "Read more"
  },
  "BikeRoute": {
    "backButton": "Back to list view",
    "lodging": "Lodging",
    "description": "Description",
    "sights": "Sights"
  },
  "Header": {
    "aboutBedAndBike": "About",
    "intro": "Intro",
    "locale": "en"
  },
  "LanguageSelector":{
    "en":"English",
    "de": "Deutsch",
    "sv": "Svenska"
  },
  "StartContainer":{
    "title": "Your bike journey in Sweden starts here",
    "description": "Guides and inspires. The complete site for everyone - from curious beginners to experienced adventurers.",
    "continueButton": "Explore the routes"
  },
  "BikePlaceNavButton": {
    "lodging": "Lodging",
    "sights": "Sights",
    "misc": "Misc"
  },
  "FilterButton": {
    "childFriendly": "Child friendly",
    "railway": "railway embankment",
    "gravel": "Gravel",
    "camping": "Camping",
    "trafficFree": "No traffic",
    "tarmac": "Tarmac",
    "circular": "Circular",
    "national": "National route"
  },
  "SaveBar": {
    "whitelistChanged": "Whitelist has been changed, continue making changes or save changes.",
    "saveWhitelist": "Save whitelist",
    "routeChanged": "Route information has been changed, continue making changes or save them.",
    "saveRoute": "Save route",
    "placeChanged": "Places are changed",
    "savePlace": "Save updates"
  },
  "SightList":{
    "header": "Sights"
  }
}
