"use strict";

// ready states
const { INITIAL, PENDING, SUCCESS, FAILURE } = require("./ready-states");

module.exports = function whitelist(state = { readyState: INITIAL }, action) {
  switch (action.type) {
    case "toggle whitelist":
      return {
        ...state,
        isDirty: true,
        placeById: {
          ...state.placeById,
          [action.payload.placeId]: action.payload.whitelisted ? 1 : 0
        }
      };

    case "toggle certified":
      return {
        ...state,
        isDirty: true,
        placeById: {
          ...state.placeById,
          [action.payload.placeId]: action.payload.certified ? 2 : 1
        }
      };

    case "save whitelist pending":
      return {
        ...state,
        readyState: PENDING
      };

    case "save whitelist failure":
      return {
        ...state,
        ...action.payload,
        readyState: FAILURE
      };

    case "save whitelist success":
      return {
        ...state,
        isDirty: false,
        readyState: SUCCESS
      };

    case "load whitelist success":
      return {
        ...state,
        placeById: action.payload
      };

    default:
      return state;
  }
};
