const React = require("react");
const { ReactCSS } = require("reactcss");
const { Link } = require("react-router");

const StyleGlobals = require("../styles/globals");

class Footer extends React.Component {
  static displayName = "Footer";

  classes = () => {
    return {
      default: {
        footer: {
          background: StyleGlobals.bgColors.main,
          display: "block",
          padding: `${StyleGlobals.dimen24} ${StyleGlobals.dimen24} ${StyleGlobals.dimen16}`,
          flex: "0 0 auto",
          order: 2,
          borderTop: `1px solid ${StyleGlobals.borderColors.main}`,
          fontSize: StyleGlobals.dimen12
        },
        links: {
          padding: StyleGlobals.dimen24,
          textAlign: "center"
        },
        link: {
          marginRight: StyleGlobals.dimen16,
          color: "inherit",
          textDecoration: "none"
        },
        meta: {
          margin: `${StyleGlobals.dimen16} 0`,
          fontSize: StyleGlobals.dimen12,
          lineHeight: 1.3,
          textAlign: "center"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes());
  };

  render() {
    let year = new Date().getFullYear();
    return (
      <footer style={this.styles().footer}>
        <div style={this.styles().links}>
          <Link style={this.styles().link} to="/about">Om Bed & Bike</Link>
        </div>
        <div style={this.styles().meta}>
          <div>
            Bilder, betyg och boendeinformation tillhandahålls av Google Places API
          </div>
          <div>{`Copyright Bed and Bike ${year}, all rights reserved `}</div>
        </div>
      </footer>
    );
  }
}

module.exports = Footer;
