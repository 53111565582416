"use strict";

const { LOCATION_CHANGE } = require("react-router-redux");

const { INITIAL, PENDING, SUCCESS, FAILURE } = require("./ready-states");

module.exports = function routes(state = { readyState: INITIAL }, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (/^\/routes\/(\d+)/g.test(action.payload.pathname)) {
        let id = RegExp.$1;
        return {
          ...state,
          readyState: state.byId && state.byId[id] ? SUCCESS : PENDING,
          selected: id,
          highlighted: null,
          focused: null
        };
      } else if (state.selected) {
        return {
          ...state,
          readyState: state.byId ? SUCCESS : PENDING,
          selected: null,
          highlighted: null,
          focused: null
        };
      }
      return state;
    case "highlight route":
      if (state.highlighted === action.payload.id) {
        return state;
      }
      return {
        ...state,
        highlighted: action.payload.id
      };
    case "focus route":
      if (state.focused === action.payload.id) {
        return state;
      }
      return {
        ...state,
        focused: action.payload.id
      };
    case "load routes pending":
      return {
        ...state,
        readyState: PENDING
      };
    case "load routes failure":
      return {
        ...state,
        ...action.payload,
        readyState: FAILURE
      };
    case "load routes success":
      return {
        ...state,
        byId: action.payload.features.reduce(
          (byId, feature) => {
            // strip altitude from bbox so we don't have to care about that during intersection testing
            feature.bbox = [
              feature.bbox[0],
              feature.bbox[1],
              feature.bbox[3],
              feature.bbox[4]
            ];

            // copy the feature id into properties so it's available for filtering and `map.featuresAt()`
            feature.properties.id = feature.id;

            // only store the sight ids
            // (the sights reducer will store the rest)
            if (feature.properties.sights) {
              feature.properties.sights = feature.properties.sights
                .map(sight => sight.id)
                .reduce(unique, []);
            }

            byId[feature.id] = feature;

            return byId;
          },
          {}
        ),
        readyState: SUCCESS
      };

    case "update route info":
      let { routeId, info } = action.payload;
      return {
        ...state,

        // mark the routes as dirty
        isDirty: true,

        // update the route info of the "detailed" route feature
        byId: {
          ...state.byId,
          [routeId]: {
            ...state.byId[routeId],
            properties: {
              ...state.byId[routeId].properties,
              ...info
            }
          }
        }
      };

    case "save images success":
      let { Location, target } = action.payload[0];
      let id = state.selected;

      if (target === "routes") {
        return {
          ...state,

          // mark the routes as dirty
          isDirty: true,

          // update the route image of the selected route
          byId: {
            ...state.byId,
            [id]: {
              ...state.byId[id],
              properties: {
                ...state.byId[id].properties,
                image: Location
              }
            }
          }
        };
      } else if (target === "sights") {
        // only mark as dirty, the rest is done in the sights reducer
        return {
          ...state,
          isDirty: true
        };
      }
      return state;

    case "save routes info pending":
      return {
        ...state,
        readyState: PENDING
      };

    case "save routes info failure":
      return {
        ...state,
        ...action.payload,
        readyState: FAILURE
      };

    case "save routes info success":
      return {
        ...state,
        isDirty: false,
        readyState: SUCCESS
      };

    case "update selected sight property":
      return {
        ...state,
        isDirty: true
      };

    case "update sight":
      if (state.selected) {
        return {
          ...state,
          isDirty: true,
          byId: {
            ...state.byId,
            [state.selected]: {
              ...state.byId[state.selected],
              properties: {
                ...state.byId[state.selected].properties,
                sights: (state.byId[state.selected].properties.sights || [])
                  .concat([action.payload.id])
                  .reduce(unique, [])
              }
            }
          }
        };
      }
      return state;

    case "remove sight":
      if (state.selected) {
        return {
          ...state,
          isDirty: true,
          byId: {
            ...state.byId,
            [state.selected]: {
              ...state.byId[state.selected],
              properties: {
                ...state.byId[state.selected].properties,
                sights: (state.byId[state.selected].properties.sights || [])
                  .reduce(unique, [])
                  .filter(id => id != action.payload.id)
              }
            }
          }
        };
      }
      return state;

    default:
      return state;
  }
};

function unique(arr, item) {
  return arr.indexOf(item) === -1 ? arr.concat(item) : arr;
}
