'use strict';

module.exports = intersects;

// checks if bbox `a` intersects with bbox `b`
// bbox = [minA, minB, minN..., maxA, maxB, maxN...]
// NOTE: does NOT deal with the international date line...
// NOTE: inclusive intersect check (i.e. touching = intersecting)
function intersects(a, b) {
  // ignoring features without bboxes
  if (!Array.isArray(a) || !Array.isArray(b)) {
    /* eslint-disable no-console */
    console.warn('trying to check intersection without a bbox array', a, b);
    return true;
  }

  if (a.length !== b.length) {
    throw new Error('bbox lengths does not match');
  }

  switch(a.length) {
    case 4: // [west, south, east, north]
      return (
        // west - east
        (((a[0] >= b[0] && a[0] <= b[2]) || (b[0] >= a[0] && b[0] <= a[2])) || ((a[2] >= b[0] && a[2] <= b[2]) || (b[2] >= a[0] && b[2] <= a[2])))
        &&
        // north - south
        (((a[1] >= b[1] && a[1] <= b[3]) || (b[1] >= a[1] && b[1] <= a[3])) || ((a[3] >= b[1] && a[3] <= b[3]) || (b[3] > a[1] && b[3] < a[3])))
      );
    case 6: // [west, south, down, east, north, up]
      throw new Error('TODO supported bbox incl. altitude');
    default:
      throw new Error('unsupported bbox length: ' + a.length);
  }
}

if (!module.parent && !process.browser) {
  var routes = require('../app/data/routes');
  var assert = require('assert');
  assert(intersects([0,0,1,1], [0,0,.5,.5]));
  assert(intersects([-1,-1,2,2], [0,0,1,1]));
  assert(intersects([0,0,.5,.5], [0,0,1,1]));
  assert(intersects([.25,.25,.75,.75], [0,0,1,1]));
  assert(intersects([0,0,1,1], [1,1,1.5,1.5]));
  assert(intersects([1,1,1.5,1.5], [0,0,1,1]));
  assert(!intersects([-2,-2,-1,-1], [0,0,1,1]));

  const sweden = [10.7817703, 55.167409, 24.450951, 69];
  const london = [-0.489, 51.28, 0.236, 51.686];
  assert(!intersects(sweden, london));
  assert(!intersects(london, sweden));

  // nynashamns boende (single point, in view) vs ekeby gard (single point, out of view)
  const boende = [17.9516927, 58.90023129999999, 17.9516927, 58.90023129999999];
  const ekeby = [17.9670071, 59.00865619999999, 17.9670071, 59.00865619999999];
  const view = [17.6478936511719, 58.38069766856228, 18.65039609257815, 58.96828122908201];
  assert(intersects(boende, view));
  assert(intersects(view, boende));
  assert(!intersects(ekeby, view));
  assert(!intersects(view, ekeby));

  // gotland route (w. altitude stripped) vs 3 gotland views that all should work
  const route = [18.2942561, 57.3426481, 19.1609131, 57.9821231];
  const gotland1 = [18.1697442371094, 56.933590084067035, 19.01843808476565, 57.54516870432052];
  const gotland2 = [18.50757382695315, 56.933590084067035, 19.3562676746094, 57.54516870432052];
  const gotland3 = [18.353193473604392, 56.86372991842973, 20.050581168916892, 58.07907832857651];
  const gotland4 = [19.44444251015625, 56.80240841197367, 21.14183020546875, 58.019762380075605]; // east of gotland
  assert(intersects(route, gotland1));
  assert(intersects(gotland1, route));
  assert(intersects(route, gotland2));
  assert(intersects(gotland2, route));
  assert(intersects(route, gotland3));
  assert(intersects(gotland3, route));
  assert(!intersects(route, gotland4));
  assert(!intersects(gotland4, route));

  // test that all routes should intersect a bbox around sweden
  routes.load().then(geojson => {
    geojson.features.forEach(feature => {
      assert(intersects(feature.bbox, sweden));
    });
    return true;
  }).catch(error => {
    console.error(error.stack);
    process.exit(1);
  });
}
