const React = require("react");
const { ReactCSS } = require("reactcss");
const { IndexLink, Link } = require("react-router");
const { translate } = require("react-translate");

const StyleGlobals = require("../styles/globals");
const LanguageSelector = require("./LanguageSelector");

const withMQ = require("../mixins/mq");

class Header extends React.Component {
  static displayName = "Header";

  classes = () => {
    return {
      default: {
        header: {
          color: StyleGlobals.textColors.accent,
          background: StyleGlobals.bgColors.main,
          borderBottom: `1px solid ${StyleGlobals.borderColors.main}`,
          flex: "0 0 auto",
          height: StyleGlobals.headerHeight,
          fontSize: "16px",
          position: "relative",
          zIndex: 100
        },
        nav: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%"
        },
        linkContainer: {
          display: "flex",
          alignItems: "inherit",
          width: "100%",
          height: "100%"
        },
        logoImg: {
          height: 25,
          width: 111,
          display: "block"
        },
        langBtn: {
          padding: `${StyleGlobals.dimen24} ${StyleGlobals.dimen8}`
        },
        languageContainer: {
          width: "40%",
          justifyContent: "flex-end",
          padding: `0 ${StyleGlobals.dimen16} 0 0`
        },
        title: {
          margin: 0,
          height: "100%"
        },
        line: {
          display: "block",
          alignSelf: "stretch",
          background: StyleGlobals.borderColors.main,
          width: "1px",
          opacity: ".5"
        },
        link: {
          paddingLeft: `${StyleGlobals.dimen24}`,
          paddingRight: `${StyleGlobals.dimen24}`
        }
      },
      mobile: {
        header: {
          height: StyleGlobals.mobileHeaderHeight,
          fontSize: "12px"
        },
        logoImg: {
          height: "20px"
        },
        headerLink: {
          paddingLeft: `${StyleGlobals.dimen12}`,
          paddingRight: `${StyleGlobals.dimen12}`
        },
        link: {
          paddingLeft: `${StyleGlobals.dimen12}`,
          paddingRight: `${StyleGlobals.dimen12}`
        },
        introBtn: {
          display: "none"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.props);
  };

  render() {
    let { t } = this.props;
    return (
      <header style={this.styles().header}>
        <nav style={this.styles().nav}>
          <div style={this.styles().linkContainer}>
            <h1 style={this.styles().title}>
              <HeaderLink to="/" style={this.styles().headerLink}>
                <img
                  style={this.styles().logoImg}
                  src="/images/svg/bedbike_logo.svg"
                  alt="Bed &amp; Bike"
                />
              </HeaderLink>
            </h1>
          </div>
          <div
            style={{
              ...this.styles().linkContainer,
              ...this.styles().languageContainer
            }}
          >
            <span style={this.styles().line} />
            <HeaderLink to="/about" style={this.styles().link}>
              {t("aboutBedAndBike")}
            </HeaderLink>
            <span style={this.styles().line} />
            <LanguageSelector
              value={t("locale")}
              items={["en", "de", "sv"]}
              changeLanguage={this.props.changeLanguage}
            />
          </div>
        </nav>
      </header>
    );
  }
}

class HeaderLink extends React.Component {
  static displayName = "HeaderLink";

  classes = () => {
    return {
      default: {
        link: {
          color: StyleGlobals.textColors.main,
          textDecoration: "none",
          display: "flex",
          paddingLeft: `${StyleGlobals.dimen24}`,
          paddingRight: `${StyleGlobals.dimen24}`,
          cursor: "pointer",
          position: "relative",
          height: "100%",
          overflow: "hidden",
          alignContent: "center",
          ...this.props.style
        },
        linkBg: {
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: StyleGlobals.borderColors.main,
          transform: "translateY(100%)",
          opacity: 0,
          transition: "transform .2s",
          transitionProperties: "transform opacity"
        },
        content: {
          position: "relative",
          zIndex: "2",
          alignSelf: "center"
        },
        resetLink: {
          textDecoration: "none",
          height: "100%"
        }
      },
      isHover: {
        linkBg: {
          transform: "translateY(0)",
          opacity: 0.3
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state);
  };

  render() {
    let { to, onClick } = this.props;
    if (to == "/") {
      return (
        <IndexLink to="/" style={this.styles().resetLink}>
          {this.renderContent()}
        </IndexLink>
      );
    } else if (onClick) {
      return (
        <a style={this.styles().resetLink} onClick={onClick}>
          {this.renderContent()}
        </a>
      );
    }
    return (
      <Link to={to} style={this.styles().resetLink}>
        {this.renderContent()}
      </Link>
    );
  }

  renderContent = () => {
    return (
      <div
        style={this.styles().link}
        onMouseOver={e => this.setState({ isHover: true })}
        onMouseOut={e => this.setState({ isHover: false })}
      >
        <div style={this.styles().linkBg} />
        <div style={this.styles().content}>
          {this.props.children}
        </div>
      </div>
    );
  };
}

module.exports = translate("Header")(withMQ(StyleGlobals.breakPoints)(Header));
