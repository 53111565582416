module.exports = function language(state = { id: "en" }, action) {
  switch (action.type) {
    case "update language":
      return {
        ...state,
        id: action.payload
      };
    default:
      return state;
  }
};
