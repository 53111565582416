function initialFilterState() {
  return {
    facets: {
      childFriendly: null,
      railway: null,
      gravel: null,
      camping: null,
      trafficFree: null,
      tarmac: null,
      circular: null,
      national: null
    },

    // default to sweden bbox
    bbox: [10.7817703, 55.167409, 24.450951, 69]
  };
}

module.exports = function filters(state = initialFilterState(), action) {
  switch (action.type) {
    case "update filter":
      let { name, value } = action.payload;
      let newState = { ...state };
      if (name == "facet") {
        newState.facets = {
          ...newState.facets,
          ...value // = {railway: true}
        };
      }
      if (name == "bbox") {
        newState.bbox = value;
      }
      return newState;
    default:
      return state;
  }
};
