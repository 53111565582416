"use strict";

const query = require("querystring");
const fetch = require("isomorphic-fetch");

const API_KEY = "AIzaSyCkTWMLa8h0vJyUwKiB8WXDK2CvSnvukAc";
const API_URL = "https://bed-and-bike-cache.dept.co";
const WHITELIST_BUCKET = "www.bedandbike.se";
const WHITELIST_KEY = "whitelist.json";
const WHITELIST_URL = "production" !== "production"
  ? `http://${WHITELIST_BUCKET}.s3-website.eu-central-1.amazonaws.com/${WHITELIST_KEY}`
  : `/${WHITELIST_KEY}`;

const PLACE_INFO_BUCKET = WHITELIST_BUCKET;
const PLACE_INFO_KEY = "places-info.json";
const PLACE_INFO_URL = "production" !== "production"
  ? `http://${PLACE_INFO_BUCKET}.s3-website.eu-central-1.amazonaws.com/${PLACE_INFO_KEY}`
  : `/${PLACE_INFO_KEY}`;

exports.load = function load(feature, { types, whitelist }) {
  let queue = types.reduce(
    (all, type) => all.concat(feature.properties.places[type] || []),
    []
  );

  // remove the places that are not whitelisted (unless admin)
  if (whitelist) {
    queue = queue.filter(id => whitelist[id]);
  }

  // load all in one go and let the promise respond when all
  // N places has been loaded.
  // Ignoring any failed places (most likely a place which is in the whitelist
  // which has been removed)
  let requests = queue.map(id => getPlace(id).catch(err => null));
  return Promise.all(requests).then(places =>
    places.filter(place => place !== null));

  // return a stream which emits each place as
  // they are loaded.
  //return concurrent(getPlace, queue, 20);
};

exports.placeImage = function(img) {
  let baseURL = API_URL + "/maps/api/place/photo";
  return baseURL +
    "?" +
    query.stringify({
      key: API_KEY,
      maxwidth: 800,
      photoreference: img
    });
};

exports.loadPlacesInfo = function() {
  return fetch(PLACE_INFO_URL).then(res => res.json()).catch(() => ({}));
};

exports.savePlacesInfo = function(info) {
  return saveToS3(
    PLACE_INFO_BUCKET,
    PLACE_INFO_KEY,
    JSON.stringify(stripEmptyKeys(info), null, 2)
  );
};

exports.loadWhitelist = function() {
  return fetch(WHITELIST_URL).then(res => res.json()).catch(() => ({}));
};

// this assumes AWS has already been injected in the `loginAWS()` action
// and the credentials are correct.
exports.saveWhitelist = function(whitelist) {
  return saveToS3(
    WHITELIST_BUCKET,
    WHITELIST_KEY,
    JSON.stringify(stripEmptyKeys(whitelist), null, 2)
  );
};

function saveToS3(bucket, key, body) {
  return new Promise((resolve, reject) => {
    /* global AWS */
    const s3 = new AWS.S3();
    const params = {
      Bucket: bucket,
      Key: key,
      Body: body,
      ACL: "public-read",
      ContentType: "application/json"
    };
    s3.putObject(params, (err, data) => err ? reject(err) : resolve(data));
  });
}

function stripEmptyKeys(obj) {
  let o = {};
  for (let k in obj) {
    if (obj[k]) {
      o[k] = obj[k];
    }
  }
  return o;
}

function getPlace(id) {
  let baseURL = API_URL + "/maps/api/place/details/json";
  return fetch(
    baseURL +
      "?" +
      query.stringify({
        key: API_KEY,
        placeid: id
      })
  )
    .then(res => res.json())
    .then(
      json =>
        json.status === "OK"
          ? json.result
          : Promise.reject(
              new Error('Failed to load place "' + id + "': " + json.status)
            )
    );
}
