"use strict";

const { LOCATION_CHANGE } = require("react-router-redux");

module.exports = function views(state = {}, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (action.payload.query.admin === "1") {
        return {
          ...state,
          showLogin: true
        };
      }
      return state;

    case "toggle admin":
      if (state.showLogin && action.payload) {
        // coming from the login screen with a positive admin
        return {
          ...state,
          showLogin: false,
          isAdmin: true
        };
      } else if (state.isAdmin && !action.payload) {
        // "logging out"
        return {
          ...state,
          isAdmin: false
        };
      }
      return state;

    case "info visibility":
      return {
        ...state,
        infoVisible: action.payload
      };
    case "show image viewer":
      return {
        ...state,
        imageViewer: action.payload.list ? action.payload : null
      };
    default:
      return state;
  }
};
