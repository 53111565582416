"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const { translate } = require("react-translate");
const ReactGA = require("react-ga");

const StyleGlobals = require("../styles/globals");
const FilterButton = require("./FilterButton");

const withMQ = require("../mixins/mq");

class FilterOptions extends React.Component {
  static displayName = "FilterOptions";

  state = {
    hoveredFacet: null,
    isOpen: false
  };

  classes = () => {
    return {
      default: {
        filters: {
          paddingTop: StyleGlobals.dimen8,
          paddingLeft: StyleGlobals.dimen24,
          paddingRight: StyleGlobals.dimen12,
          paddingBottom: StyleGlobals.dimen16,
          display: "flex",
          flexFlow: "row",
          flexWrap: "wrap",
          minHeight: "48px"
        },
        filter: {
          display: "flex",
          width: "100%",
          flexFlow: "row",
          flexWrap: "wrap",
          marginTop: "10px"
        },
        filterButton: {
          width: "calc(24.9% - 8px)",
          margin: `${StyleGlobals.dimen8} ${StyleGlobals.dimen8} 0 0`,
          // float: "left",
          opacity: 0
        },
        toggleBtn: {
          textAlign: "center",
          padding: StyleGlobals.dimen24
        },
        toggleBtnText: {
          margin: `0 ${StyleGlobals.dimen8} 0 0`,
          fontSize: StyleGlobals.dimen12,
          textTransform: "uppercase"
        }
      },
      threeColumn: {
        filterButton: {
          width: "calc(33% - 4px)",
          margin: `${StyleGlobals.dimen4} ${StyleGlobals.dimen4} 0 0`
        }
      },
      mobile: {
        filters: {
          paddingLeft: StyleGlobals.dimen16,
          paddingLeft: StyleGlobals.dimen16,
          paddingRight: StyleGlobals.dimen12,
          paddingBottom: 0
        },
        filterButton: {
          width: "calc(50% - 4px)"
        },
        toggleBtn: {
          background: StyleGlobals.bgColors.light,
          margin: `0 0 0 0`
          // margin: `${StyleGlobals.dimen4} ${StyleGlobals.dimen4} 0 0`
        }
      },
      isOpen: {
        toggleBtn: {
          margin: `${StyleGlobals.dimen8} 0 0 0`,
          width: "100%"
        },
        arrow: {
          transform: "rotateZ(180deg)"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state, this.props);
  };

  render() {
    let { mobile } = this.props;
    return (
      <div className="filters">
        {mobile && !this.state.isOpen ? this.renderClosed() : this.renderOpen()}
      </div>
    );
  }

  renderOpen = () => {
    let { facets, mobile, t } = this.props;
    let { hoveredFacet } = this.state;

    let fi = Object.keys(facets).map((facet, i) => (
      <FilterButton
        key={facet}
        name={facet}
        isActive={facets[facet] ? true : false}
        isHover={mobile ? false : hoveredFacet === facet}
        onChange={this.onChange}
        onHover={this.onHover}
        style={this.styles().filterButton}
        index={i}
      />
    ));
    return (
      <div style={this.styles().filters}>
        {fi}
        {mobile
          ? <div
              className="toggleBtn"
              style={this.styles().toggleBtn}
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            >
              <span style={this.styles().toggleBtnText}>{t("hideFilter")}</span>
              <img
                src="/images/svg/filterOptionsArrow.svg"
                style={this.styles().arrow}
              />
            </div>
          : ""}
      </div>
    );
  };

  renderClosed = () => {
    let { t } = this.props;
    return (
      <div
        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        style={this.styles().toggleBtn}
      >
        <span style={this.styles().toggleBtnText}>{t("showFilter")}</span>
        <img src="/images/svg/filterOptionsArrow.svg" />
      </div>
    );
  };

  onHover = facet => {
    this.setState({ hoveredFacet: facet });
  };

  onChange = (facet, value) => {
    ReactGA.event({
      category: "click",
      action: "filter",
      label: facet
    });
    let filter = {
      name: "facet",
      value: {
        [facet]: value
      }
    };
    this.props.onChange(filter);
  };
}

function shouldComponentUpdate(nextProps, nextState, nextContext) {
  if (nextContext.locale !== this.context.locale) {
    return true;
  }
  if (nextProps.facets !== this.props.facets) {
    return true;
  }
  return false;
}

module.exports = translate("FilterOptions", shouldComponentUpdate)(
  withMQ({
    threeColumn: { maxWidth: 980 },
    ...StyleGlobals.breakPoints
  })(FilterOptions)
);
