module.exports={
  "locale": "sv",
  "About": {
    "title": "Bed and Bike är ett samarbete mellan Cykelfrämjandet och utvalda hotell, vandrarhem och andra typer av semesterboenden",
    "intro": " <h3>Våra samarbetspartners erbjuder sina cyklande gäster specialservice.</h3> <p> Bed & Bike är en fantastisk idé för dig som gillar att cykla och vill ha både dig och din cykel väl omhändertagen där du övernattar. På natten ska din cykel kunna förvaras på ett bra sätt. Du vill ha tillgång till verktyg och luft. Du vill kunna göra en matsäck på morgonen. </p> <p> Du vill kunna stanna där det är fint, utan att behöva planera resan i detalj långt i förväg. Väder och lust kan få styra din cykelsemester. Och du vill kanske gärna träffa andra som tycker lika mycket om att cykla som du… </p>",
    "partners": "<h3>Vill du bli en samarbetspartner?</h3> <p> Om du anvsvarar för ett boende och vill förändra bild och information, uppdatera informationen på <a href='https://www.google.se/intl/sv/business/' target='_blank'> Google places </a> . För att påverka användarnas betyg, be de cyklande gästerna att bedöma er på Google. </p> <p> Certifierade boende får mer publicitet via hemsidan och är en trygghet för cyklande turister. Bed and bike ansvarar inte för att informationen kring ert boende är korrekt. </p> <p> För att bli mer information och om du vill bli certifierad, kontakta </p> <ul> <li> E-post: <a href='mailto:info@bedandbike.se'>info@bedandbike.se</a> </li> <li>Telefon: <a href='tel:+46812151331'>08-121 513 31</a>, Telefontid vardagar kl.10-13 </li> </ul>",
    "cykelframjandetTitle": "Cykelfrämjandet är huvudman för Bed & Bike i Sverige",
    "cykelframjandetBody": "<p><a href='http://cykelframjandet.se/' target='_blank'>Cykelfrämjandet</a> är Sveriges största cykelorganisation. Vi är en opolitisk ideell medlemsorganisation som funnits i över 80 år. Vi finns hela landet med 30 lokala kretsar. Vi har som mål att göra Sverige till en verklig cykelnation. </p> <p> Därför arbetar vi lokalt, regionalt och på riksnivå med att främja cykling för bättre miljö, hälsa och för att få en säker trafikmiljö. Cykelfrämjandet startade Bed&Bike i Sverige 2012. </p> <p> Idén kommer från den tyska organisationen <a href='http://www.bettundbike.de/' target='_blank'>Bett und Bike</a> som i år omfattar mer än 5 400 boenden. Vi samarbetar sedan starten med Trafikverket för att utveckla den snabbt växande cykelturismen. </p>",
    "tech": "<h3>Teknik</h3> <p> Tjänsten är utvecklad av <a href='http://department.se'>Department</a> . Tjänsten använder <a href='https://www.google.se/intl/sv/business/' target='_blank'>Google Places</a> för platsinformation och ledinformation från <a href='https://www.openstreetmap.org/' target='_blank'>Openstreetmap</a>. </p> <p> Om du vill hjälpa till att korrigera data, all kartinformation hämtas från in på <a href='https://www.openstreetmap.org/' target='_blank'>Openstreetmap</a>. </p> <p>Kontakta oss för mer information.</p>",

  },
  "FilterOptions": {
    "showFilter": "Visa filter",
    "hideFilter": "Dölj filter"
  },
  "BikeRoute": {
    "backButton": "Tillbaka till ruttlistan",
    "saveWhitelist": "Spara vitlistningen",
    "whitelistChanged": "Vitlistningen har ändrats"
  },
  "BikeRouteList": {
    "noMatch": "boenden längs rutten",
    "noMatchAgain": "boenden synliga på kartan"
  },
  "BikeRoute": {
    "backButton": "Översikt av leder",
    "lodging": "Boenden",
    "description": "Ledbeskrivning",
    "sights": "Sevärdheter"
  },
  "BikePlaceList": {
    "totalLodging": "boenden längs leden",
    "visibleLodging": "synliga på kartan"
  },
  "BikePlaceListItem":{
    "toggleInfo": "Läs mer"
  },
  "BikeRouteList": {
    "noMatch": "Inga leder matchar nuvarande kriterier",
    "noMatchAgain": "Ändra kartans zoom och rutt-filter för att överblicka leder."
  },
  "Header": {
    "aboutBedAndBike": "Om tjänsten",
    "intro": "Intro",
    "locale": "sv"
  },
  "LanguageSelector":{
    "en": "English",
    "de": "Deutsch",
    "sv": "Svenska"
  },
  "StartContainer":{
    "title": "Utvalda boenden längs<br/ >svenska cykelleder",
    "description": "Vägleder och inspirerar. Den kompletta sajten för alla – <br/ >från nyfikna nybörjare till erfarna äventyrare.",
    "continueButton": "Utforska lederna"
  },
  "BikePlaceNavButton": {
    "lodging": "Boende",
    "sights": "Sevärdheter",
    "misc": "Annat"
  },
  "FilterButton": {
    "childFriendly": "Barnvänlig",
    "railway": "Banvall",
    "gravel": "Grus",
    "camping": "Campingvänlig",
    "trafficFree": "Biltrafik fri",
    "tarmac": "Asfalt",
    "circular": "Rund rutt",
    "national": "Nationell led"
  },
  "SaveBar": {
    "whitelistChanged": "Whitelist has been changed, continue making changes or save changes.",
    "saveWhitelist": "Save whitelist",
    "routeChanged": "Route information has been changed, continue making changes or save them.",
    "saveRoute": "Save route",
    "placeChanged": "Places are changed",
    "savePlace": "Save updates"
  },
  "SightList": {
    "header": "Sevärdheter"
  }

}
