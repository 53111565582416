"use strict";

exports.two = two;
exports.compareLngLat = compareLngLat;
exports.compareBounds = compareBounds;
exports.compareFilters = compareFilters;
exports.compareLayoutOrPaint = compareLayoutOrPaint;

// returns true if changed
function compareBounds(a, b) {
  /* global mapboxgl */
  // both null or same object, no change
  if (a === b) {
    return false;
  }

  // added / removed, always changed
  if ((!a && b) || (a && !b)) {
    return true;
  }

  // only compare the bounds (for now) in case of bounds options
  // TODO also compare the options
  if (a.bounds) {
    a = a.bounds;
  }
  if (b.bounds) {
    b = b.bounds;
  }

  // convert bbox with altitude to bbox without
  if (a.length > 4) {
    a = two(a);
  }
  if (b.length > 4) {
    b = two(b);
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    return a.join(",") !== b.join(",");
  }

  // otherwise compare the strings
  var al = mapboxgl.LngLatBounds.convert(a);
  var bl = mapboxgl.LngLatBounds.convert(b);
  return al.toString() !== bl.toString();
}

function two(a) {
  let n = a.length / 2; // 6 / 2 = 3
  return [[a[0], a[1]], [a[n], a[n + 1]]]; // 0, 1, 3, 4
}

// returns true if changed
function compareLngLat(a, b) {
  /* global mapboxgl */
  // both null or same object
  if (a === b) {
    return false;
  }

  // added / removed
  if ((!a && b) || (a && !b)) {
    return true;
  }

  var al = mapboxgl.LngLat.convert(a);
  var bl = mapboxgl.LngLat.convert(b);
  return al.toString() !== bl.toString();
}

// returns true if changed
function compareFilters(a, b) {
  // both null or same object
  if (a === b) {
    return false;
  }

  // added / removed
  if ((!a && b) || (a && !b)) {
    return true;
  }

  // check each nested filter recursively
  if (Array.isArray(a)) {
    // check lengths
    if (a.length !== b.length) {
      return true;
    }

    for (let i = 0; i < a.length; i++) {
      if (compareFilters(a[i], b[i])) {
        return true;
      }
    }
    return false;
  }

  // otherwise just compare the values
  return a !== b;
}

function compareLayoutOrPaint(a, b) {
  // both null or same object
  if (a === b) {
    return false;
  }

  // added / removed
  if ((!a && b) || (a && !b)) {
    return true;
  }

  // check values
  if (typeof a === "object") {
    for (let k in a) {
      if (a[k] !== b[k]) {
        // a non-array property changed (always return true)
        if (!Array.isArray(a[k])) {
          return true;
          // or check the length
        } else if (a.length !== b.length) {
          return true;
        } else {
          // otherwise try one more level (such as text-font, text-offset etc)
          for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  return a !== b;
}

//
//if (!module.parent) {
//  const assert = require('assert');
//  assert(compareFilters(['all'], ['all']) === false);
//  assert(compareFilters(['all', ['==', 'a', 1]], ['all', ['==', 'a', 1]]) === false);
//  assert(compareFilters(['all', ['==', 'a', null]], ['all', ['==', 'a', null]]) === false);
//  assert(compareFilters(['all', ['==', 'a', 1]], ['all', ['==', 'a', 2]]) === true);
//  assert(compareFilters(['all', ['==', 'a', 1]], ['all', ['==', 'b', 1]]) === true);
//  assert(compareFilters(['all', ['==', 'a', 1]], ['any', ['==', 'a', 1]]) === true);
//  assert(compareFilters(['all', ['==', 'a', 1]], ['all']) === true);
//
//}
