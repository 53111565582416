const React = require("react");
const { ReactCSS } = require("reactcss");
const { connect } = require("react-redux");
const { translate } = require("react-translate");
const shallowCompare = require("react-addons-shallow-compare");

const actions = require("../actions");
const { placesSelector } = require("../selectors");

const {
  INITIAL,
  PENDING,
  PAYLOAD,
  SUCCESS,
  FAILURE
} = require("../reducers/ready-states");
const StyleGlobals = require("../styles/globals");
const withMQ = require("../mixins/mq");
const BikePlaceListItem = require("../components/BikePlaceListItem");
const Loader = require("../components/Loader");

class PlaceList extends React.Component {
  static displayName = "PlaceList";

  classes = () => {
    return {
      default: {
        routesHeader: {
          padding: `0 ${StyleGlobals.dimen24} ${StyleGlobals.dimen16}`,
          fontSize: StyleGlobals.dimen16,
          fontWeight: "bold"
        },
        listItem: {
          opacity: 0,
          animation: "topOpacityAnimation .4s cubic-bezier(0.770, 0.000, 0.175, 1.000) forwards"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes());
  };

  render() {
    switch (this.props.readyState) {
      case INITIAL:
      case PENDING:
        return this.renderLoading();
      case PAYLOAD:
      case SUCCESS:
        return this.renderSuccess();
      case FAILURE:
        return this.renderFailure();
      default:
        /* eslint-disable no-console */
        console.error("unknown ready state!", this.props);
        return null;
    }
  }

  renderLoading = () => {
    return (
      <div style={this.styles().listContainer}>
        <Loader />
      </div>
    );
  };

  renderFailure = () => {
    return <p>ERROR WHILE LOADING...{this.props.error}</p>;
  };

  renderSuccess = () => {
    let {
      places,
      placesCount,
      whitelist,
      toggleWhitelisted,
      showImageViewer,
      isAdmin,
      t
    } = this.props;
    return (
      <div style={this.styles().container}>
        <div style={this.styles().routesHeader}>
          {`${placesCount} ${t("totalLodging")}`}
          {placesCount !== places.length
            ? ` (${places.length} ${t("visibleLodging")})`
            : ""}
        </div>
        {places.map((place, i) => (
          <BikePlaceListItem
            key={place.place_id}
            place={place}
            isWhitelisted={whitelist[place.place_id] ? true : false}
            isCertified={whitelist[place.place_id] === 2}
            isHighlighted={this.props.highlighted == place.place_id}
            isFocused={this.props.focused == place.place_id}
            toggleCertified={this.props.toggleCertified}
            toggleWhitelisted={isAdmin && toggleWhitelisted}
            onEdit={isAdmin && this.props.updatePlaceInfo}
            focusPlace={this.props.focusPlace}
            highlightPlace={this.props.highlightPlace}
            isMobile={this.props.mobile}
            style={{
              ...this.styles().listItem,
              animationDelay: `${i * 0.1}s`,
              borderColor: `${i == places.length - 1 ? "transparent" : StyleGlobals.borderColors.lightGrey}`
            }}
            showImageViewer={showImageViewer}
          />
        ))}
      </div>
    );
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onPlaceHighlight: id => dispatch(actions.highlightPlace(id)),
    onPlaceFocused: id => dispatch(actions.focusPlace(id)),
    toggleWhitelisted: (id, whitelisted) =>
      dispatch(actions.toggleWhitelist(id, whitelisted)),
    toggleCertified: (id, certified) =>
      dispatch(actions.toggleCertified(id, certified)),
    saveWhitelist: () => dispatch(actions.saveWhitelistIfNeeded()),
    updateRouteInfo: (id, info) => dispatch(actions.updateRouteInfo(id, info)),
    updatePlaceInfo: (id, info) => dispatch(actions.updatePlaceInfo(id, info)),
    showImageViewer: (list, current) =>
      dispatch(actions.showImageViewer(list, current))
  };
}

module.exports = connect(placesSelector, mapDispatchToProps)(
  withMQ(StyleGlobals.breakPoints)(translate("BikePlaceList")(PlaceList))
);
