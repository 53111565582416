/* eslint-env browser */
var w = null;
var h = null;

if (typeof window !== 'undefined') {
  window.addEventListener('resize', updateSize);
  updateSize();
}

function updateSize() {
    w = (window.innerHeight || document.documentElement.clientHeight);
    h = (window.innerWidth || document.documentElement.clientWidth);
}

function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
    var vertInView = (rect.top <= w) && ((rect.top + rect.height) >= 0);
    var horInView = (rect.left <= h) && ((rect.left + rect.width) >= 0);
    return (vertInView && horInView);
}

module.exports = isElementInViewport;