"use strict";

const React = require("react");
const { translate } = require("react-translate");

const StyleGlobals = require("../styles/globals");

const styles = {
  bar: {
    position: "fixed",
    width: "100%",
    bottom: "0px",
    background: StyleGlobals.bgColors.accent,
    zIndex: 4,
    padding: StyleGlobals.dimen24,
    color: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  btn: {
    display: "block",
    background: StyleGlobals.bgColors.main,
    padding: StyleGlobals.dimen16,
    fontSize: StyleGlobals.dimen16,
    border: 0,
    fontFamily: StyleGlobals.fonts.default,
    cursor: "pointer"
  }
};

function SaveBar({ titleKey, submitKey, onSave, t }) {
  return (
    <div style={styles.bar}>
      <span>{t(titleKey)}</span>
      <button style={styles.btn} onClick={onSave}>{t(submitKey)}</button>
    </div>
  );
}
SaveBar.displayName = "SaveBar";

module.exports = translate(SaveBar.displayName)(SaveBar);
