"use strict";

const React = require("react");
const shallowCompare = require("react-addons-shallow-compare");

const PropTypes = require("./PropTypes");

class Source extends React.Component {
  static displayName = "Source";
  static propTypes = PropTypes.SourceOptions;

  static contextTypes = {
    map: React.PropTypes.object
  };

  componentDidMount() {
    let { map } = this.context;
    let { id, type } = this.props;

    // special case with geojson which can use `source.setData()`
    if (type === "geojson") {
      /* global mapboxgl */
      let {
        data,
        buffer,
        maxzoom,
        tolerance,
        cluster,
        clusterMaxZoom,
        clusterRadius
      } = this.props;
      this.source = new mapboxgl.GeoJSONSource({
        data,
        buffer,
        maxzoom,
        tolerance,
        cluster,
        clusterMaxZoom,
        clusterRadius
      });
      map.addSource(id, this.source);
    } else {
      throw new Error('only "geojson" source type is currently supported.');
    }
  }

  componentWillUnmount() {
    let { map } = this.context;
    if (map.getSource(this.props.id)) {
      map.removeSource(this.props.id);
    }
  }

  componentWillUpdate(nextProps) {
    if (this.source) {
      this.source.setData(nextProps.data);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.id !== nextProps.id) {
      /* eslint-disable no-console */
      console.error("do not change id of a source plz. ignoring changes.");
      return false;
    }

    return shallowCompare(this, nextProps);
  }

  render() {
    return null;
  }
}

module.exports = Source;
