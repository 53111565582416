// ready states
exports.INITIAL = 0;
exports.PENDING = 1;
exports.PAYLOAD = 2;
exports.SUCCESS = 3;
exports.FAILURE = 4;

exports.readyStateAction = readyStateAction;

// generate an action thunk which goes transitions a state:
//
//  initial -> pending -> [updates] -> success | failure
//
// `payload` is a state which may be dispatched multiple times as
// more results come in. it is triggered when the pending result is
// a stream instead of a promise.
function readyStateAction(name, fn) {
  /* eslint-disable promise/always-return */
  name = name.trim();
  return (...args) => {
    return dispatch => {
      dispatch({
        type: name + " pending",
        payload: args
      });
      return fn(...args).then(
        payload => {
          if (typeof payload.pipe == "function") {
            payload.on("readable", () => {
              let data;
              while ((data = payload.read()) !== null) {
                dispatch({
                  type: name + " payload",
                  payload: data
                });
              }
            });
            payload.on("end", data => {
              dispatch({
                type: name + " success",
                payload: data
              });
            });
            return payload;
          } else {
            return dispatch({
              type: name + " success",
              payload
            });
          }
        },
        error => {
          return dispatch({
            type: name + " failure",
            payload: { error }
          });
        }
      );
    };
  };
}
