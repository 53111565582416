const WERSRV_URL = "https://images.weserv.nl/?url=";
function convert(url, extras = "") {
  url = url.replace("https://", "");
  url = url.replace("http://", "");
  url = url.replace(
    "www.bedandbike.se.s3.eu-central-1.amazonaws.com",
    "www.bedandbike.se"
  );
  extras += "&v=1";
  return `${WERSRV_URL}${encodeURIComponent(url)}&${extras}`;
}

module.exports = convert;
