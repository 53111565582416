"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const { connect } = require("react-redux");
const { browserHistory } = require("react-router");

const { mapSelector } = require("../selectors");
const actions = require("../actions");
const { Map, Source, Layer, Navigation } = require("../components/mapbox");
const SightPopup = require("../components/SightPopup");
const StyleGlobals = require("../styles/globals");
const isElementInViewport = require("../../lib/is-element-in-viewport");

class BikeMap extends React.Component {
  static displayName = "BikeMap";

  static contextTypes = {
    locale: React.PropTypes.string
  };

  state = {
    zoom: 5
  };

  classes = () => {
    return {
      default: {
        container: {
          width: "100%",
          flex: "1 1 auto",
          position: "relative",
          transition: ".3s width, .3s height ease-out",
          backfaceVisibility: "hidden",
          transform: "translateZ(0)"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes());
  };

  shouldComponentUpdate() {
    return !this.refs.map || isElementInViewport(this.refs.map);
  }

  render() {
    const {
      routes,
      places,
      route,
      sights,
      placeFilter,
      routeFilter,
      selected,
      isAdmin,
      activeSight
    } = this.props;
    return (
      <div
        className="BikeMap"
        style={{ ...this.styles().container, ...this.props.style }}
      >
        <Map
          accessToken="pk.eyJ1Ijoic3RvbmVicmFuY2giLCJhIjoiY2lsa25kMTN6MDA2dW5ja3F3YnhtcXJ3eCJ9.5fnyHv32bK-uZAxgSvtnkw"
          style={{
            width: "100%",
            position: "absolute",
            top: 0,
            bottom: 0
          }}
          styleURI="mapbox://styles/stonebranch/cij04ccpi00tg2lknk9k4wvku"
          center={[18.0735799, 59.3078387]}
          zoom={5}
          getBounds={this.getBounds}
          classes={route ? ["hide"] : selected ? ["selected"] : []}
          onClick={this.onClick}
          onMouseDown={isAdmin ? this.onMouseDown : noop}
          onMouseMove={this.onMouseMove}
          onMouseUp={this.onMouseUp}
          onMoveEnd={this.onMoveEnd}
          onZoomEnd={this.onZoomEnd}
        >

          {!this.props.isMobile ? <Navigation position="top-right" /> : null}
          <span ref="map" />
          <Source id="routes" type="geojson" data={routes} />
          <Source id="places" type="geojson" data={places} cluster={true} />
          <Source id="sights" type="geojson" data={sights} />
          <Layer
            id="routes-base"
            type="line"
            source="routes"
            interactive={true}
            layout={{
              lineJoin: "round",
              lineCap: "round"
            }}
            paint={{
              lineColor: StyleGlobals.borderColors.focus,
              lineWidth: {
                stops: [[1, 2], [8, 4], [10, 10]]
              },
              lineOpacity: 1
            }}
            paint-selected={{
              lineColor: StyleGlobals.borderColors.focus,
              lineWidth: 6,
              lineOpacity: 1
            }}
            paint-hide={{
              lineColor: StyleGlobals.borderColors.focus,
              lineOpacity: 1,
              lineWidth: 1
            }}
            insertBefore="housenum-label"
          />
          <Layer
            id="routes-over"
            references="routes-base"
            paint={{
              lineColor: "#FFF",
              lineWidth: {
                stops: [[8, 0.6], [10, 2]]
              }
            }}
            paint-hide={{
              lineWidth: 0
            }}
            insertBefore="housenum-label"
          />
          <Layer
            id="routes-selected"
            type="line"
            source="routes"
            layout={{
              lineJoin: "round",
              lineCap: "round"
            }}
            paint={{
              lineColor: StyleGlobals.borderColors.focus,
              lineWidth: 4
            }}
            insertBefore="housenum-label"
            filter={routeFilter}
          />
          <Layer
            id="places-base"
            type="symbol"
            source="places"
            interactive={true}
            layout={{
              iconImage: "lodging",
              textField: "{name}",
              textFont: ["Open Sans Semibold", "Arial Unicode MS Bold"],
              textSize: 12,
              textOffset: [0, 2],
              textAnchor: "top",
              textOptional: true
            }}
            paint={{
              textColor: "#000",
              textHaloColor: StyleGlobals.toRgba("#FFFFFF", 0.7),
              textHaloWidth: 1.5
            }}
          />

          <Layer
            id="places-cluster-all-fill"
            type="circle"
            source="places"
            paint={{
              circleColor: StyleGlobals.bgColors.lodging,
              circleRadius: 20
            }}
            filter={["<", "point_count", 30]}
          />
          <Layer
            id="places-cluster-all"
            type="circle"
            source="places"
            paint={{
              circleColor: StyleGlobals.bgColors.lodging,
              circleRadius: 25,
              circleOpacity: 0.4
            }}
            filter={["<", "point_count", 30]}
          />

          <Layer
            id="places-cluster-10"
            type="circle"
            source="places"
            paint={{
              circleColor: StyleGlobals.bgColors.lodging,
              circleRadius: 30,
              circleOpacity: 0.3
            }}
            filter={[
              "all",
              [">=", "point_count", 10],
              ["<", "point_count", 30]
            ]}
          />

          <Layer
            id="places-cluster-count"
            type="symbol"
            source="places"
            layout={{
              textField: "{point_count}",
              textFont: ["Open Sans Semibold", "Arial Unicode MS Bold"],
              textSize: 12
            }}
            paint={{
              textColor: "#FFF"
            }}
          />

          <Layer
            id="places-selected"
            type="symbol"
            source="places"
            layout={{
              iconImage: "lodging-focus",
              textField: "{name}",
              textFont: ["Open Sans Semibold", "Arial Unicode MS Bold"],
              textAnchor: "top",
              textSize: 12,
              textOffset: [0, 2]
            }}
            paint={{
              textColor: "#FFF",
              textHaloColor: StyleGlobals.borderColors.focus,
              textHaloWidth: 2,
              textHaloBlur: 0.6
            }}
            filter={placeFilter}
          />

          <Layer
            id="sights-base"
            type="symbol"
            source="sights"
            layout={{
              iconImage: "star-11"
            }}
            interactive={true}
          />

          <SightPopup {...activeSight} />
        </Map>
        {this.props.children}
        <a
          href="http://mapbox.com/about/maps"
          className="mapbox-maplogo"
          target="_blank"
        >
          Mapbox
        </a>
      </div>
    );
  }

  getBounds = () => {
    return {
      // zoom into selected place
      // or show all of sweden
      bounds: this.props.selected
        ? this.props.selected.bbox
        : [10.7817703, 55.167409, 24.450951, 69],
      padding: this.props.isMobile ? 20 : 100,
      speed: 3
    };
  };

  onMouseDown = e => {
    let radius = 0;
    let bbox = makeBbox(e.point, radius);
    let features = e.target.queryRenderedFeatures(bbox, {
      layers: ["sights-base"]
    });

    let feature = features[0];

    if (feature) {
      this.props.onSightMoving(feature.properties.id);
    }
  };

  onMouseUp = () => {
    if (this.props.movingSight) {
      this.props.onSightMoving(null);
    }
  };

  onMouseMove = e => {
    let { movingSight } = this.props;
    if (movingSight) {
      e.originalEvent.preventDefault();
      e.originalEvent.stopImmediatePropagation();
      let changedFeature = {
        ...movingSight,
        geometry: {
          ...movingSight.geometry,
          coordinates: e.lngLat.toArray()
        }
      };
      this.props.onSightUpdate(changedFeature);
      return;
    }

    let { selected, activeSight, onSightActive } = this.props;
    let map = e.target;
    let canvas = map.getCanvas();
    let radius = selected ? 30 : 10;
    let bbox = makeBbox(e.point, radius);
    let features = map.queryRenderedFeatures(bbox, {
      layers: [selected ? "places-base" : "routes-base"]
    });

    if (!features.length) {
      canvas.style.cursor = null;
      if (selected && this.props.highlightedPlace) {
        this.props.onPlaceHighlight(null);
      } else if (!selected && this.props.highlightedRoute) {
        this.props.onRouteHighlight(null);
      }
    } else {
      canvas.style.cursor = "pointer";
      let feature = features[0];
      if (
        selected && this.props.highlightedPlace !== feature.properties.place_id
      ) {
        this.props.onPlaceHighlight(feature.properties.place_id);
      } else if (
        !selected && this.props.highlightedRoute !== feature.properties.id
      ) {
        this.props.onRouteHighlight(feature.properties.id);
      }
    }

    let sightFeatures = map.queryRenderedFeatures(bbox, {
      layers: ["sights-base"]
    });
    let sightFeature = sightFeatures[0];
    if (sightFeature) {
      canvas.style.cursor = "pointer";
      if (!activeSight || activeSight.id !== sightFeature.properties.id) {
        onSightActive(sightFeature.properties.id);
      }
    } else if (activeSight) {
      canvas.style.cursor = null;
      onSightActive(null);
    }
  };

  onClick = e => {
    let { selected } = this.props;
    let bbox = makeBbox(e.point, selected ? 30 : 10);
    let features = e.target.queryRenderedFeatures(bbox, {
      layers: ["places-cluster-count", selected ? "places-base" : "routes-base"]
    });

    if (!features.length) {
      if (selected && this.props.focusedPlace) {
        this.props.onPlaceFocus(null);
      } else if (!selected && this.props.focusedRoute) {
        this.props.onRouteFocus(null);
      }
    } else {
      let feature = features[0];

      // zoom into clusters
      if (feature.properties.cluster) {
        e.target.flyTo({
          center: e.lngLat,
          zoom: e.target.getZoom() + 1
        });
      } else if (
        selected && this.props.highlightedPlace !== feature.properties.id
      ) {
        this.props.onPlaceFocus(feature.properties.place_id);
      } else if (!selected) {
        browserHistory.push(`/routes/${feature.properties.id}`);
      }
    }
  };

  onMoveEnd = e => {
    // update the route/places bbox filters
    let bounds = e.target.getBounds();
    this.props.updateFilter({
      name: "bbox",
      value: [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth()
      ]
    });
  };

  onZoomEnd = e => {
    this.setState({
      zoom: e.target.getZoom()
    });
  };
}

function noop() {}

function makeBbox(point, radius) {
  let offset = { x: radius, y: radius };
  return [point.sub(offset), point.add(offset)];
}

function mapDispatchToProps(dispatch) {
  return {
    updateFilter: filter => dispatch(actions.updateFilter(filter)),
    onRouteHighlight: id => dispatch(actions.highlightRoute(id)),
    onPlaceHighlight: id => dispatch(actions.highlightPlace(id)),
    onRouteFocus: id => dispatch(actions.focusRoute(id)),
    onPlaceFocus: id => dispatch(actions.focusPlace(id)),
    onSightUpdate: feature => dispatch(actions.updateSight(feature)),
    onSightMoving: id => dispatch(actions.movingSight(id)),
    onSightActive: id => dispatch(actions.activeSight(id))
  };
}

module.exports = connect(mapSelector, mapDispatchToProps)(BikeMap);
