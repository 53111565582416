const React = require("react");
const { ReactCSS } = require("reactcss");
const { translate } = require("react-translate");

const fixOrphans = require("../../lib/no-more-orphans");

const StyleGlobals = require("../styles/globals");

const withMQ = require("../mixins/mq");

class StartContainer extends React.Component {
  static displayName = "StartContainer";

  state = {
    negativeHeight: 0,
    isVisible: this.props.visible
  };

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate() {
    if (this.props.visible) {
      this.addListeners();
    } else {
      this.removeListeners();
    }
  }

  addListeners = () => {
    document.addEventListener("click", this.hideHeader);
    document.addEventListener("wheel", this.hideHeader);
    document.addEventListener("keydown", this.hideHeader);
    document.addEventListener("touchmove", this.hideHeader);
  };

  removeListeners = () => {
    document.removeEventListener("click", this.hideHeader);
    document.removeEventListener("wheel", this.hideHeader);
    document.removeEventListener("keydown", this.hideHeader);
    document.removeEventListener("touchmove", this.hideHeader);
  };

  classes = () => {
    let { negativeHeight } = this.state;
    return {
      default: {
        container: {
          padding: 0,
          color: StyleGlobals.textColors.inverted,
          borderBottom: `1px solid ${StyleGlobals.borderColors.main}`,
          flex: "0 1",
          position: "relative",
          width: "100%",
          minHeight: 0,
          backgroundImage: "url(/images/intro-top.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          textAlign: "left",
          overflow: "auto",
          transition: "all .3s cubic-bezier(0.230, 1.000, 0.320, 1.000)",
          backfaceVisibility: "hidden",
          transform: "translateZ(0)"
        },
        center: {
          position: "relative",
          zIndex: 2,
          maxWidth: 1024,
          width: "100%",
          paddingTop: 160
        },
        maxWidth: {
          position: "absolute",
          maxWidth: 1024,
          width: "100%",
          margin: "0 24px",
          height: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center"
        },
        title: {
          fontSize: "48px",
          margin: "0 0 16px",
          paddinBottom: 16
        },
        paragraph: {
          fontSize: 18,
          // marginTop: StyleGlobals.dimen16,
          lineHeight: "1.5",
          fontWeight: "400",
          margin: 0
        },
        btn: {
          font: "inherit",
          background: "transparent",
          color: "#fff",
          fontSize: StyleGlobals.dimen16,
          border: 0,
          padding: `${StyleGlobals.dimen16} ${StyleGlobals.dimen24} ${StyleGlobals.dimen16} 0`,
          marginTop: StyleGlobals.dimen16,
          outline: 0,
          fontWeight: "bold",
          WebkitAppearance: "inherit",
          cursor: "pointer",
          display: "flex",
          alignItems: "center"
        },
        arrowSvg: {
          display: "inline-block",
          marginRight: 16
        },
        gradient: {
          width: "100%",
          height: "80%",
          position: "absolute",
          bottom: 0,
          backgroundImage: "linear-gradient(-180deg, rgba(29,175,136,0.00) 0%, rgba(35,126,102,0.59) 100%)"
        },
        logo: {
          position: "absolute",
          top: 0,
          left: 0,
          width: 144,
          zIndex: 3
        }
      },
      isVisible: {
        container: {
          minHeight: `75vh`
        }
      },
      mobile: {
        maxWidth: {
          margin: 0,
          paddingRight: StyleGlobals.dimen12,
          paddingLeft: StyleGlobals.dimen12
        },
        logo: {
          left: StyleGlobals.dimen12
        },
        title: {
          fontSize: 24
        },
        paragraph: {
          fontSize: 14
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), {
      isVisible: this.props.visible,
      ...this.props
    });
  };

  render() {
    let { t } = this.props;
    return (
      <div
        className="StartContainer"
        ref="StartContainer"
        id="StartContainer"
        style={this.styles().container}
      >
        <div style={this.styles().gradient} />
        <div style={this.styles().maxWidth}>
          <img style={this.styles().logo} src="/images/logo-bg.svg" />
          <div style={this.styles().center}>
            <h1
              style={this.styles().title}
              dangerouslySetInnerHTML={{ __html: fixOrphans(t("title")) }}
            />
            <p
              style={this.styles().paragraph}
              dangerouslySetInnerHTML={{ __html: t("description") }}
            />
            <button style={this.styles().btn} type="button">
              <svg
                width="45"
                height="45"
                viewBox="341 541 45 45"
                style={this.styles().arrowSvg}
              >
                <g
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(342 542)"
                  stroke="#FFF"
                  strokeWidth="1.2"
                >
                  <path
                    d="M21.3 12.46v15.66m-5.45-3.05l5.74 5.18 5.9-5.34"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <circle cx="21.5" cy="21.5" r="21.5" />
                </g>
              </svg>
              {t("continueButton")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  hideHeader = () => {
    if (this.props.visible) {
      this.props.hide();
    }
  };
}

module.exports = translate("StartContainer")(
  withMQ(StyleGlobals.breakPoints)(StartContainer)
);
