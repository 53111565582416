const React = require("react");
const { ReactCSS } = require("reactcss");
const StyleGlobals = require("../styles/globals");
const { translate } = require("react-translate");
const ReactGA = require("react-ga");

class LanguageSelector extends React.Component {
  static displayName = "LanguageSelector";

  state = {
    isOpen: false
  };

  classes = () => {
    return {
      default: {
        container: {
          position: "relative",
          display: "inline-block",
          padding: `0 0 0 ${StyleGlobals.dimen16}`,
          flex: "0 0 auto"
        },
        current: {
          cursor: "pointer",
          display: "block",
          textAlign: "right",
          width: "100%",
          padding: `${StyleGlobals.dimen16} ${StyleGlobals.dimen24} ${StyleGlobals.dimen16} ${StyleGlobals.dimen16}`,
          position: "relative",
          borderBottomWidth: "2px"
        },
        arrow: {
          width: 0,
          height: 0,
          borderLeft: `${StyleGlobals.dimen4} solid transparent`,
          borderRight: `${StyleGlobals.dimen4} solid transparent`,
          borderTop: `${StyleGlobals.dimen4} solid ${StyleGlobals.bgColors.dark}`,
          position: "absolute",
          right: StyleGlobals.dimen4,
          top: "50%",
          transform: "translateY(-2px)"
        },
        list: {
          position: "absolute",
          maxHeight: "50vh",
          overflowY: "auto",
          right: StyleGlobals.dimen4,
          background: StyleGlobals.bgColors.main,
          boxShadow: `0 0 11px 1px rgba(0,0,0,.4)`,
          zIndex: 1000
        }
      }
    };
  };

  componentDidUpdate() {
    document
      .getElementById("languageDropdown")
      .addEventListener("click", this.onClick);
    if (this.state.isOpen) {
      /* eslint-env browser */
      document.addEventListener("mouseup", this.closeList, true);
    }
  }

  componentWillLeave = () => {
    this.closeList();
  };

  styles = () => {
    return ReactCSS(this.classes());
  };

  render() {
    let { value, t } = this.props;
    let { isOpen } = this.state;

    return (
      <div className="LanguageSelector" style={this.styles().container}>
        {isOpen
          ? <div style={this.styles().list} ref="list">
              {this.props.items.map(item => (
                <ListItem key={item} name={item} onSelect={this.onSelect}>
                  {t(item)}
                </ListItem>
              ))}
            </div>
          : ""}
        <div id="languageDropdown" style={this.styles().current}>
          {value ? t(value) : this.props.placeholder}
          <div style={this.styles().arrow} />
        </div>
        <div style={this.styles().border} />
      </div>
    );
  }

  onClick = event => {
    event.stopPropagation();
    this.setState({ isOpen: true });
  };

  onSelect = val => {
    this.props.changeLanguage(val);
    ReactGA.event({
      category: "click",
      action: "select language",
      label: val
    });
  };

  closeList = () => {
    document.removeEventListener("mouseup", this.closeList, true);
    this.setState({ isOpen: false });
    return true;
  };
}

class ListItem extends React.Component {
  static displayName = "SelectLocationBoundsListItem";

  classes = () => {
    return {
      default: {
        listItem: {
          padding: `${StyleGlobals.dimen16} ${StyleGlobals.dimen24}`,
          borderBottom: `1px solid ${StyleGlobals.borderColors.main}`,
          backgroundColor: "transparent",
          transition: "background-color .2s",
          cursor: "pointer"
        }
      },
      isHover: {
        listItem: {
          backgroundColor: StyleGlobals.bgColors.light
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state);
  };

  render() {
    let { name } = this.props;
    return (
      <div
        key={name}
        style={this.styles().listItem}
        onMouseOver={e => this.setState({ isHover: true })}
        onMouseOut={e => this.setState({ isHover: false })}
        onMouseDown={this.onMouseDown}
      >
        {this.props.children}
      </div>
    );
  }

  onMouseDown = event => {
    event.stopPropagation();
    let { name } = this.props;
    this.props.onSelect(name);
  };
}

module.exports = translate("LanguageSelector")(LanguageSelector);
