const React = require("react");
const { ReactCSS } = require("reactcss");
const { Link } = require("react-router");
const { connect } = require("react-redux");
const { TranslatorProvider } = require("react-translate");

const actions = require("../actions");
const BikeMap = require("./BikeMap");
const Login = require("../components/Login");
const Header = require("../components/Header");
const SVGIcons = require("../components/SVGIcons");
const Start = require("../components/StartContainer");
const Footer = require("../components/Footer");
const SaveBar = require("../components/SaveBar");
const StyleGlobals = require("../styles/globals");
const availableLocales = require("../data/locales");

const withMQ = require("../mixins/mq");

class App extends React.Component {
  static displayName = "App";

  state = {
    showIntro: true
  };

  componentDidMount() {
    /* eslint-env browser */
    const showIntro = (localStorage && localStorage.visited == "true") ||
      localStorage.visited == true
      ? false
      : true;
    localStorage.setItem("visited", true);
    this.setState({ showIntro: showIntro });
    this.props.toggleStartArea(showIntro);
  }

  componentWillUpdate() {
    this.refs.content.scrollTop = 0;
  }

  classes = () => {
    return {
      default: {
        app: {
          fontFamily: StyleGlobals.fonts.default,
          color: StyleGlobals.textColors.main,
          lineHeight: "1",
          fontSize: StyleGlobals.dimen16,
          fontWeight: "normal",
          padding: 0,
          display: "flex",
          flexFlow: "column",
          height: "100vh",
          overflow: "hidden"
        },
        main: {
          display: "flex",
          height: "100%",
          flex: "0 1 auto",
          position: "relative"
        },
        container: {
          height: "100%",
          width: "100%",
          display: "flex",
          position: "absolute"
        },
        content: {
          flex: "1 1 auto",
          overflowY: "auto",
          width: "100%",
          display: "flex",
          flexFlow: "column",
          minHeight: "100%"
        },
        map: {
          order: 3
        },
        fullscreenLink: {
          width: "100%",
          height: "100%",
          position: "absolute",
          background: "rgba(0,0,0,0)",
          zIndex: 2
        }
      },
      mobile: {
        main: {
          overflowY: "auto",
          display: "block",
          height: "auto"
        },
        container: {
          flexDirection: "column",
          position: "relative",
          height: "auto"
        },
        content: {
          overflowY: "hidden"
        },
        map: {
          height: "40vh",
          display: "none"
        }
      },
      isIndex: {
        map: {
          flex: this.props.wide ? "1 1 auto" : "1 2 auto",
          display: this.props.mobile ? "none" : "inherit"
        }
      },
      isAbout: {
        map: {
          flex: "1 1 auto",
          width: 0
        }
      },
      isRoute: {
        container: {
          display: this.props.mobile ? "flex" : "inherit",
          flexFlow: this.props.mobile ? "column" : "row"
        },
        map: {
          display: this.props.mobile ? "block" : "inherit",
          order: this.props.mobile ? -1 : 1,
          height: this.props.mobile ? "40vh" : "100%"
        }
      },
      isFullscreenMap: {
        map: {
          display: "block",
          flex: "0 0 auto",
          height: `calc(100vh - ${StyleGlobals.mobileHeaderHeight})`
        },
        container: {
          display: "flex",
          overflow: "hidden"
        },
        content: {}
      },
      wide: {}
    };
  };

  styles = () => {
    let style = {
      ...this.props,
      isAbout: this.props.location.pathname == "/about",
      isIndex: this.props.location.pathname == "/",
      isRoute: this.props.location.pathname.indexOf("/routes/") > -1,
      isFullscreenMap: this.props.location.pathname.indexOf("/map") > 0
    };
    return ReactCSS(this.classes(), style);
  };

  render() {
    let {
      toggleStartArea,
      showLogin,
      infoVisible,
      changeLanguage,
      translations
    } = this.props;
    const { showIntro } = this.state;
    const { mobile } = this.props;
    const isFullscreenMap = this.props.location.pathname.indexOf("/map") > 0;
    return (
      <TranslatorProvider translations={translations}>
        <div id="app" style={this.styles().app}>
          {showIntro
            ? <Start
                visible={infoVisible}
                hide={e => toggleStartArea(false)}
                show={e => toggleStartArea(true)}
              />
            : null}
          <Header
            showIntro={e => toggleStartArea(true)}
            changeLanguage={changeLanguage}
          />
          <main ref="main" style={this.styles().main}>
            <SVGIcons />
            <div style={this.styles().container}>
              <div ref="content" style={this.styles().content}>
                {this.props.children || null}
                <Footer />
              </div>
              <BikeMap
                location={this.props.location}
                style={this.styles().map}
                isMobile={mobile}
              >
                {mobile && !isFullscreenMap
                  ? <Link
                      style={this.styles().fullscreenLink}
                      to={
                        `${this.props.location.pathname.replace("/lodging", "")}/map`
                      }
                    />
                  : null}
              </BikeMap>
            </div>
          </main>
          {showLogin ? <Login onSubmit={this.onLoginSubmit} /> : ""}
          {this.renderSaveBar()}
        </div>
      </TranslatorProvider>
    );
  }

  renderSaveBar = () => {
    let {
      isWhitelistDirty,
      saveWhitelist,
      isPlacesDirty,
      savePlaces,
      isRouteDirty,
      saveRoute
    } = this.props;
    if (isWhitelistDirty) {
      return (
        <SaveBar
          titleKey="whitelistChanged"
          submitKey="saveWhitelist"
          onSave={saveWhitelist}
        />
      );
    } else if (isRouteDirty) {
      return (
        <SaveBar
          titleKey="routeChanged"
          submitKey="saveRoute"
          onSave={saveRoute}
        />
      );
    } else if (isPlacesDirty) {
      return (
        <SaveBar
          titleKey="placeChanged"
          submitKey="savePlace"
          onSave={savePlaces}
        />
      );
    }
    return null;
  };

  onLoginSubmit = event => {
    event.preventDefault();
    this.props.onLogin({
      accessKey: event.target.accessKey.value,
      secret: event.target.secret.value
    });
  };
}

function mapStateToProps(state) {
  return {
    showLogin: state.views.showLogin,
    infoVisible: state.views.infoVisible,
    translations: availableLocales[state.language.id] || availableLocales["en"],
    isWhitelistDirty: state.whitelist.isDirty,
    isPlacesDirty: state.places.isDirty,
    isRouteDirty: state.routes.isDirty
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleStartArea: visible => dispatch(actions.updateInfoArea(visible)),
    onLogin: ({ accessKey, secret }) =>
      dispatch(actions.loginAWS(accessKey, secret)),
    changeLanguage: lang => dispatch(actions.updateLanguage(lang)),
    saveWhitelist: () => dispatch(actions.saveWhitelistIfNeeded()),
    savePlaces: () => dispatch(actions.savePlacesInfoIfNeeded()),
    saveRoute: () => dispatch(actions.saveRoutesInfoIfNeeded())
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(
  withMQ({
    ...StyleGlobals.breakPoints,
    wide: {
      minWidth: 1400
    }
  })(App)
);
