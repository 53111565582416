const React = require("react");
const { ReactCSS } = require("reactcss");
const { Link } = require("react-router");
const shallowCompare = require("react-addons-shallow-compare");

const imgRsrv = require("../../lib/img-wesrv");
const StyleGlobals = require("../styles/globals");

class BikeRouteListItem extends React.Component {
  static displayName = "BikeRouteListItem";

  static contextTypes = {
    locale: React.PropTypes.string
  };

  state = { isLoaded: false };

  classes = () => {
    return {
      default: {
        link: {
          display: "flex",
          flexDirection: "column",
          width: `calc(50% - ${StyleGlobals.dimen24})`,
          margin: StyleGlobals.dimen12,
          color: StyleGlobals.textColors.inverted,
          position: "relative",
          opacity: 0,
          textDecoration: "none",
          verticalAlign: "top",
          transition: "box-shadow .35s ease-in-out",
          outline: "1px solid transparent",
          animation: `topOpacityAnimation .3s ${this.props.index * 0.03}s forwards ease-out`
        },
        figure: {
          margin: 0,
          border: "0 solid transparent",
          lineHeight: 0,
          height: 0,
          width: "100%",
          paddingBottom: "55%",
          overflow: "hidden",
          position: "relative",
          transition: "outline-color .2s"
        },
        textContainer: {
          position: "relative",
          flex: "1 2 auto",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          color: StyleGlobals.textColors.main,
          background: StyleGlobals.bgColors.main,
          padding: StyleGlobals.dimen16,
          transition: "color .25s ease-out"
        },
        name: {
          display: "flex",
          justifyContent: "space-between",
          whiteSpace: "nowrap",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: 1.5,
          paddingBottom: "4px",
          opacity: 0
        },
        desc: {
          color: StyleGlobals.textColors.lighten,
          fontSize: 16,
          display: "block",
          opacity: 0
        },
        distance: {
          position: "absolute",
          bottom: StyleGlobals.dimen16,
          right: 0,
          marginTop: StyleGlobals.dimen4,
          display: "block",
          padding: "16px 14px",
          fontWeight: "bold",
          fontSize: 13,
          backgroundColor: StyleGlobals.toRgba("#000000", 0.7)
        },
        img: {
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "scale(1) translate3d(0,0,0)"
        },
        facets: {
          alignSelf: "flex-end",
          display: "block"
        },
        facetIcon: {
          display: "inline-block",
          height: "16px",
          margin: "0 2px"
        },
        facetIconUse: {
          fill: StyleGlobals.textColors.lighten,
          stroke: StyleGlobals.textColors.lighten
        }
      },
      isLoaded: {
        name: {
          animation: "topOpacityAnimation .25s .1s forwards"
        },
        desc: {
          animation: "topOpacityAnimation .25s .3s forwards"
        }
      },
      isHighlighted: {
        link: {
          opacity: 1,
          transitionTimingFunction: "ease-out",
          boxShadow: `0 0 15px 1px rgba(0,0,0,.2)`,
          transitionDelay: "0.5s",
          outline: `1px solid ${StyleGlobals.textColors.focus}`
        },
        textContainer: {
          color: StyleGlobals.textColors.focus
        }
      },
      isHover: {},
      isFocused: {
        link: {
          opacity: 1,
          outlineColor: StyleGlobals.borderColors.focus
        },
        textContainer: {
          color: StyleGlobals.textColors.focus
        }
      },
      notFocused: {
        link: {
          backgroundColor: "",
          opacity: 0.5
        }
      },
      isMobile: {
        link: {
          width: "100%",
          margin: `${StyleGlobals.dimen12} 0 0`
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(
      this.classes(),
      {
        isLoaded: this.state.isLoaded
      },
      this.props
    );
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.context.locale !== nextContext.locale) {
      return true;
    }
    if (this.state.isLoaded !== nextState.isLoaded) {
      return true;
    }
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    let { route } = this.props;
    let { locale } = this.context;

    let facetIcons = Object.keys(route.properties.facets)
      .filter(f => route.properties.facets[f])
      .map(f => (
        <svg
          key={f}
          style={this.styles().facetIcon}
          viewBox="0 0 64 64"
          enableBackground="new 0 0 64 64"
        >
          <use style={this.styles().facetIconUse} xlinkHref={`#${f}`} />
        </svg>
      ));

    return (
      <Link
        to={`/routes/${route.id}`}
        id={route.id}
        data-id={route.id}
        className="BikeRouteListItem"
        style={this.styles().link}
        onMouseEnter={this.onMouseOver}
        onMouseLeave={this.onMouseOut}
      >
        <div style={this.styles().media}>
          <figure style={this.styles().figure}>
            <img
              src={imgRsrv(route.properties.image, "h=300")}
              width={400}
              height={300}
              style={this.styles().img}
              onLoad={() => this.setState({ isLoaded: true })}
              onError={() => this.setState({ isLoaded: true })}
            />
            <figcaption>
              <span style={this.styles().distance}>
                {Math.round(route.properties.length / 1000)}km
              </span>
            </figcaption>
          </figure>
        </div>
        <div style={this.styles().textContainer}>
          <div style={this.styles().name}>
            <span>
              {route.properties["name_" + locale] ||
                route.properties.name ||
                "<Namn Saknas>"}
            </span>
            <span style={this.styles().facets}>
              {facetIcons}
            </span>
          </div>
          <span style={this.styles().desc}>
            {route.properties["short_description_" + locale] ||
              route.properties.short_description ||
              ""}
          </span>
        </div>
      </Link>
    );
  }

  onMouseOver = () => {
    let { route } = this.props;
    this.props.highlightRoute(route.id);
  };

  onMouseOut = () => {
    if (this.props.isHighlighted) {
      this.props.highlightRoute(null);
    }
  };
}

module.exports = BikeRouteListItem;
