"use strict";

const { PropTypes } = require("react");
const { number, shape, string, bool, func, object, array } = PropTypes;

const POSITIONS = PropTypes.oneOf([
  "top",
  "bottom",
  "left",
  "right",
  "top-left",
  "top-right",
  "bottom-left",
  "bottom-right"
]);

exports.Position = PropTypes.oneOfType([
  PropTypes.arrayOf(number),
  shape({
    lat: number.isRequired,
    lng: number.isRequired
  })
]);

exports.Filter = array;

exports.MapOptions = {
  accessToken: string.isRequired,
  styleURI: string.isRequired,
  style: object,
  center: exports.Position,
  zoom: number,
  classes: PropTypes.arrayOf(string),
  interactive: bool
};

exports.MapComponentOptions = {
  getBounds: func
};

exports.MapComponentEvents = {
  onLoad: func,
  onClick: func,
  onDoubleClick: func,
  onMouseDown: func,
  onMouseUp: func,
  onMouseMove: func,
  onBoxZoomStart: func,
  onBoxZoomCancel: func,
  onBoxZoomEnd: func,
  onZoomStart: func,
  onZoomEnd: func,
  onZoom: func,
  onDragStart: func,
  onDragEnd: func,
  onDrag: func,
  onMoveStart: func,
  onMoveEnd: func,
  onMove: func,
  onRotateStart: func,
  onRotateEnd: func,
  onRotate: func,
  onContextMenu: func
};

exports.PopupOptions = {
  closeButton: bool,
  closeOnClick: bool,
  offset: object,
  anchor: POSITIONS
};

exports.PopupComponentOptions = {
  lngLat: exports.Position
};

exports.NavigationOptions = {
  position: POSITIONS
};

exports.SourceOptions = PropTypes.oneOfType([
  shape(exports.GeoJSONSourceOptions),
  shape(exports.VectorSourceOptions),
  shape(exports.RasterSourceOptions),
  shape(exports.ImageSourceOptions),
  shape(exports.VideoSourceOptions)
]).isRequired;

exports.GeoJSONSourceOptions = {
  id: string.isRequired,
  type: PropTypes.oneOf(["geojson"]).isRequired,
  data: PropTypes.oneOfType([string, object]).isRequired,
  buffer: number,
  maxzoom: number,
  tolerance: number,
  cluster: bool,
  clusterMaxZoom: number,
  clusterRadius: number
};

exports.VectorSourceOptions = {
  id: string.isRequired,
  type: PropTypes.oneOf(["vector"]).isRequired,
  url: string.isRequired,
  tileSize: number
};

exports.RasterSourceOptions = {
  id: string.isRequired,
  type: PropTypes.oneOf(["raster"]).isRequired,
  url: string.isRequired,
  tileSize: number
};

exports.ImageSourceOptions = {
  id: string.isRequired,
  type: PropTypes.oneOf(["image"]).isRequired,
  url: string.isRequired,
  coordinates: PropTypes.arrayOf(array).isRequired
};

exports.VideoSourceOptions = {
  id: string.isRequired,
  type: PropTypes.oneOf(["video"]).isRequired,
  urls: PropTypes.arrayOf(string).isRequired,
  coordinates: PropTypes.arrayOf(array).isRequired
};

exports.LayerOptions = {
  id: string.isRequired,
  type: PropTypes.oneOf([
    "fill",
    "line",
    "symbol",
    "circle",
    "raster",
    "background"
  ]),
  references: string, // "ref" is reserved by React,
  insertBefore: string, // "before" is second arg. set which layer it should be added before
  metadata: object,
  source: string,
  sourceLayer: string,
  minzoom: number,
  maxzoom: number,
  interactive: bool,
  filter: exports.Filter,
  layout: object,
  paint: object
};
