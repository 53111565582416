module.exports = {
  fonts: {
    default: "Lato"
  },

  //colors
  textColors: {
    main: "#5E5E5E",
    accent: "#1BAF88",
    grey: "#666666",
    lighten: "#bbb",
    inverted: "#FFFFFF",
    focus: "#1BAF88",
    blue: "#4572CC"
  },
  bgColors: {
    main: "#FFF",
    accent: "#1BAF88",
    highlighted: "#1BAF88",
    focus: "#F38787",
    route: "#228625",
    dark: "#3B3B3B",
    light: "#EEEEEE",
    stages: "#EC6033",
    lodging: "#4572CC",
    sights: "#6DD08F",
    misc: "#FFF",
    superLight: "#fafafa"
  },
  borderColors: {
    main: "#D8D8D8",
    accent: "#1BAF88",
    focus: "#F38787",
    grey: "#C6C6C6",
    route: "#F38787",
    lightGrey: "#E7E7E7"
  },

  //dimens
  dimen2: "2px",
  dimen4: "4px",
  dimen8: "8px",
  dimen12: "12px",
  dimen16: "16px",
  dimen24: "24px",
  dimen36: "36px",
  dimen48: "48px",
  dimen56: "56px",
  dimen64: "64px",
  dimen72: "72px",
  dimen88: "88px",
  dimen96: "96px",

  navWidth: "288px",
  headerHeight: "70px",
  mobileHeaderHeight: "50px",

  contentPadding: "64px",

  pageStyle: {
    display: "block",
    minHeight: "100%",
    width: "100%",
    padding: `0 0 0 0`,
    background: "#FFF"
  },

  breakPoints: {
    smallerScreens: {
      maxWidth: 1024
    },
    mobile: {
      maxWidth: 800
    }
  },

  toRgba(hex, a) {
    hex = hex.replace("#", "");
    let r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${a})`;
  }
};
