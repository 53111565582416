"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const { connect } = require("react-redux");

const { uploadImageSelector } = require("../selectors");
const actions = require("../actions");
const StyleGlobals = require("../styles/globals");
const File = require("./../components/File");

const { PENDING, FAILURE } = require("../reducers/ready-states");

class UploadImage extends React.Component {
  static displayName = "UploadImage";

  classes = () => {
    return {
      default: {
        File: {
          width: 150,
          height: 150,
          padding: StyleGlobals.dimen16,
          background: "transparent",
          border: "1px dashed " + StyleGlobals.borderColors,
          display: "inline-block",
          verticalAlign: "top",
          cursor: "pointer"
        },
        image: {
          objectFit: "cover",
          width: "100%",
          height: "100%"
        },
        input: {
          top: "-1000px",
          position: "absolute",
          visibility: "hidden"
        },
        btn: {
          padding: StyleGlobals.dimen24,
          textAlign: "center"
        }
      },
      isOver: {
        File: {
          background: "green"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state);
  };

  render() {
    return (
      <File
        onChange={this.props.onFileSelect}
        multiple={false}
        accept="image/*"
      >
        {this.renderFiles()}
      </File>
    );
  }

  renderFiles = () => {
    let { src, readyState } = this.props;
    if (readyState === FAILURE) {
      return <p style={this.styles().btn}>Failed</p>;
    } else if (readyState === PENDING) {
      return <p style={this.styles().btn}>Uploading...</p>;
    } else if (src) {
      return <img src={src} style={this.props.style} />;
    } else {
      return (
        <p style={{ ...this.styles().btn, ...this.props.style }}>
          Click here to upload img
        </p>
      );
    }
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onFileSelect: files => dispatch(actions.saveImages(files, ownProps.target))
  };
}

module.exports = connect(uploadImageSelector, mapDispatchToProps)(UploadImage);
