"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const normalize = require("component-normalized-upload");

const StyleGlobals = require("../styles/globals");

class File extends React.Component {
  static displayName = "File";

  static propTypes = {
    multiple: React.PropTypes.bool,
    accept: React.PropTypes.string,

    onChange: React.PropTypes.func.isRequired
  };

  static defaultProps = {
    multiple: false,
    accept: "*/*"
  };

  state = {
    isOver: 0,
    files: []
  };

  classes = () => {
    return {
      default: {
        File: {
          background: "transparent",
          border: "1px dashed " + StyleGlobals.borderColors,
          display: "inline-block",
          verticalAlign: "top",
          cursor: "pointer"
        },
        image: {
          objectFit: "cover",
          width: "100%",
          height: "100%"
        },
        input: {
          top: "-1000px",
          position: "absolute",
          visibility: "hidden"
        }
      },
      isOver: {
        File: {
          background: "green"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state);
  };

  componentDidMount() {
    let input = this.refs.file;
    // FIXME: when directory is enabled it doesn't allow picking a file...
    // input.webkitdirectory = this.props.multiple;
    // input.mozdirectory = this.props.multiple;
    // input.directory = this.props.multiple;
    input.multiple = this.props.multiple;
    input.accept = this.props.accept;
  }

  render() {
    return (
      <div
        style={this.styles().File}
        onClick={this.onFileClick}
        onDrop={this.onFileDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        ref="drop"
      >
        {this.renderChildrenWithFiles()}
        <form ref="form">
          <input
            ref="file"
            type="file"
            style={this.styles().input}
            aria-hidden="true"
            onChange={this.onFileChange}
          />
        </form>
      </div>
    );
  }

  renderChildrenWithFiles = () => {
    let { files } = this.state;
    return React.Children.map(this.props.children, child => {
      return React.isValidElement(child)
        ? React.cloneElement(child, { files })
        : child;
    });
  };

  onFileClick = () => {
    this.refs.form.reset();
    this.refs.file.click();
  };

  onFileChange = () => {
    this.setState({ isOver: false });

    this.onChange([].slice.call(this.refs.file.files));
  };

  onFileDrop = (event) => {
    this.setState({ isOver: false });

    event.stopPropagation();
    event.preventDefault();
    normalize(event, e => this.onChange(e.items));
  };

  onDragEnter = (event) => {
    if (this.refs.drop.contains(event.target)) {
      this.setState({ isOver: this.state.isOver + 1 });
    }
  };

  onDragLeave = (event) => {
    if (this.refs.drop.contains(event.target)) {
      this.setState({ isOver: this.state.isOver - 1 });
    }
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onChange = (files) => {
    // only pick the files that match the accept property
    // (since the filter does not apply to the dropped files)
    let accept = new RegExp(
      "^" +
        this.props.accept
          .toLowerCase()
          .replace(/\n|\r/g, "")
          .replace(/\*/g, ".+?")
          .replace(/\//g, "\\/")
          .split(/\s*,\s*/)
          .map(type => "(?:" + type + ")")
          .join("|") +
        "$"
    );
    files = files.filter(file => accept.test(file.type));

    if (files.length) {
      this.setState({ files }, () => {
        this.props.onChange(files);
      });
    }
  };
}

module.exports = File;
