"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");

const { Popup } = require("./mapbox");

const StyleGlobals = require("../styles/globals");

class SightPopup extends React.Component {
  static displayName = "SightPopup";

  static contextTypes = {
    locale: React.PropTypes.string
  };

  classes = () => {
    return {
      default: {
        img: {
          maxWidth: "100%",
          height: "auto",
          display: "block"
        },
        title: {
          fontSize: StyleGlobals.dimen16,
          fontWeight: "bold",
          color: StyleGlobals.textColors.grey,
          margin: `${StyleGlobals.dimen8} 0 0`,
          width: "100%",
          textAlign: "center"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes());
  };

  render() {
    let { geometry, properties } = this.props, { locale } = this.context;

    if (!properties || !geometry) {
      return null;
    }

    return (
      <Popup lngLat={geometry.coordinates} closeButton={false} maxWidht={250}>
        <div style={{ width: "250px" }}>
          <img style={this.styles().img} src={properties.image} />
          <h1 style={this.styles().title}>{properties[`title_${locale}`]}</h1>
          {/*<p>{properties[`description_${locale}`]}</p>*/}
        </div>
      </Popup>
    );
  }
}

module.exports = SightPopup;
