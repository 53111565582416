"use strict";

const React = require("react");
const { ReactCSS } = require("reactcss");
const { Link } = require("react-router");

const StyleGlobals = require("../styles/globals");
const withMQ = require("../mixins/mq");

class BackToList extends React.Component {
  static displayName = "BackToList";
  state = {};

  classes = () => {
    return {
      default: {
        backLink: {
          display: "flex",
          alignContent: "flex-start",
          textDecoration: "none",
          fontSize: StyleGlobals.dimen16,
          transition: "transform .2s ease-out, background-color .2s ease-out",
          position: "relative",
          zIndex: 3,
          paddingLeft: StyleGlobals.dimen24,
          background: "rgb(250, 250, 250)",
          height: StyleGlobals.dimen56,
          borderRadius: 0,
          fontWeight: 400,
          backfaceVisibility: "hidden"
        },
        backIcon: {
          display: "inline-block",
          width: "10px",
          margin: 0,
          height: "17px",
          verticalAlign: "middle",
          transition: "transform .18s ease-out",
          alignSelf: "center",
          transform: "translateZ(0)",
          backfaceVisibility: "hidden",
          animation: "topOpacityAnimation .2s forwards"
        },
        backIconStroke: {
          transition: ".2s fill ease-out",
          fill: StyleGlobals.textColors.accent
        },
        desc: {
          alignSelf: "center",
          padding: StyleGlobals.dimen16,
          color: StyleGlobals.toRgba(StyleGlobals.textColors.grey, 0.9),
          transition: ".2s color ease-out",
          animation: "topOpacityAnimation .2s forwards"
        }
      },
      isHover: {
        backLink: {
          transform: this.state.mobile ? "scale(1.2)" : ""
        },
        backIcon: {
          transform: !this.state.mobile ? "scale(1.3) translateZ(0)" : ""
        },
        backIconStroke: {
          fill: StyleGlobals.toRgba(StyleGlobals.textColors.accent, 1)
        },
        desc: {
          color: StyleGlobals.toRgba(StyleGlobals.textColors.accent, 1)
        }
      },
      mobile: {
        backLink: {
          top: StyleGlobals.dimen8,
          left: StyleGlobals.dimen8,
          position: "absolute",
          borderRadius: 100,
          boxShadow: `0 2px 5px ${StyleGlobals.toRgba(StyleGlobals.bgColors.dark, 0.7)}`,
          color: StyleGlobals.toRgba("#FFFFFF", 0.6),
          background: StyleGlobals.bgColors.dark,
          transform: "1",
          width: StyleGlobals.dimen48,
          height: StyleGlobals.dimen48,
          justifyContent: "center",
          paddingLeft: 0
        },
        desc: {
          display: "none"
        },
        backIconStroke: {
          fill: StyleGlobals.toRgba("#FFFFFF", 1)
        },
        backIcon: {
          margin: 0,
          position: "relative"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.props, this.state);
  };

  render() {
    return (
      <Link
        style={this.styles().backLink}
        to={this.props.to}
        onMouseEnter={() => this.setState({ isHover: true })}
        onTouchStart={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}
        onTouchEnd={() => this.setState({ isHover: false })}
      >
        <svg
          style={this.styles().backIcon}
          width="11px"
          height="19px"
          viewBox="0 0 11 19"
        >
          <g
            style={this.styles().backIconStroke}
            stroke="none"
            strokeWidth="1"
            fill="#FFF"
            fillRule="evenodd"
          >
            <path
              d="M0.746146861,8.6250925 L9.02068275,0.558161344 C9.41613642,0.172629449 10.0492503,0.180672634 10.4347822,0.5761263 C10.8203141,0.971579967 10.8122709,1.60469386 10.4168172,1.99022575 L2.71377686,9.5 L10.4168172,17.0097742 C10.8122709,17.3953061 10.8203141,18.02842 10.4347822,18.4238737 C10.0492503,18.8193274 9.41613642,18.8273706 9.02068275,18.4418387 L0.746146861,10.3749075 C0.674677939,10.3317609 0.607484618,10.2788924 0.546488584,10.2163267 C0.352327436,10.0171687 0.25798952,9.75773255 0.262687409,9.5 C0.25798952,9.24226745 0.352327436,8.98283127 0.546488584,8.78367333 C0.607484618,8.72110755 0.674677939,8.66823914 0.746146861,8.6250925 Z"
            />
          </g>
        </svg>
        <span style={this.styles().desc}>
          {this.props.linkName}
        </span>
      </Link>
    );
  }
}

module.exports = withMQ(StyleGlobals.breakPoints)(BackToList);
