const React = require("React");

module.exports = BikeIcon;

function BikeIcon() {
  return (
    <svg style={{ position: "absolute", visibility: "hidden" }}>
      <def>
        {/* BIKE ICONS */}
        <g id="bikes">
          <g id="touring">
            <path
              d="M49.5,63.4h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4V63
C49.2,63.2,49.3,63.4,49.5,63.4z"
            />
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="90.5"
              cy="50.4"
              r="19.5"
            />
            <polygon
              fill="none"
              stroke="#000000"
              strokeLinecap="square"
              strokeMiterlimit="10"
              points="41.4,8.1
54.7,49.8 80.5,22.6 79.7,19.9 53.8,20 45.2,20 "
            />

            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
44.6,18.2 26.9,49.8 54.7,49.8 "
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
M89.8,20.4c0,0,5.2-0.6,5.2-4.8S90.5,11,90.5,11H77.3v0.9l6.1,20.3l2.2,5.5"
            />
            <circle cx="90.5" cy="50.4" r="2.3" />
            <circle cx="26.9" cy="50.4" r="2.3" />
            <circle cx="54.7" cy="50.1" r="4" />
            <path
              d="M42.2,6c-3.7,0-7-0.2-7.6-0.2c-0.6,0-1.9-0.1-2,1.3c-0.1,1.4,0.3,1.7,0.9,2.1c0.5,0.4,2.2,1.5,2.5,1.8
c0.4,0.3,2.3,1.7,3.6,1.2c1.4-0.4,3.6-2.1,5-2.6s2.8-0.9,3.9-0.9s2,0,2-1.4s-1.1-1.5-1.7-1.5C48.2,5.8,42.2,6,42.2,6z"
            />
            <path
              d="M54.2,39h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9C53.8,38.8,54,39,54.2,39
z"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              x1="56.6"
              y1="37.5"
              x2="51.9"
              y2="62.1"
            />
            <polygon points="90.2,50.6 84.2,38.3 87,37.1 91,50.1 " />
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              d="M30.9,31.3c-2,4.2-4.2,8.4-4.2,8.4
c-1.1,2.3-2.8,4.1-5.1,4.1h-4c-2.3,0-4.2-1.9-4.2-4.2v-3.3c-3.7,3.5-6,8.5-6,14.1c0,10.8,8.7,19.5,19.5,19.5
c10.8,0,19.5-8.7,19.5-19.5C46.4,41,39.7,33.2,30.9,31.3z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              d="M30.9,31.3c1.3-2.7,2.8-6.2,3.3-7.7
c0.8-2.2-3.2-2-5.5-2H17.6c-2.3,0-4.2,1.9-4.2,4.2v10.5"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              d="M13.4,36.3v3.3c0,2.3,1.9,4.2,4.2,4.2h4
c2.3,0,4-1.9,5.1-4.1c0,0,2.2-4.2,4.2-8.4"
            />
          </g>
          <g id="mtb">
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="92.7"
              cy="49.2"
              r="19.5"
            />
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="29.1"
              cy="49.2"
              r="19.5"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="square"
              strokeMiterlimit="10"
              d="M49.5,25.2"
            />
            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="square"
              strokeMiterlimit="10"
              points="43.6,7
56.9,48.6 82.8,21.5 81.9,18.7 "
            />

            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
46.8,17 29.1,48.6 56.9,48.6 "
            />

            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
92.7,9.8 79.5,9.8 79.5,10.7 85.7,31 87.9,36.6 "
            />
            <circle cx="92.7" cy="49.2" r="2.3" />
            <circle cx="29.1" cy="49.2" r="2.3" />
            <circle cx="56.9" cy="48.9" r="4" />
            <path
              d="M44.4,4.8c-3.7,0-7-0.2-7.6-0.2c-0.6,0-1.9-0.1-2,1.3c-0.1,1.4,0.3,1.7,0.9,2.1c0.5,0.4,2.2,1.5,2.5,1.8
c0.4,0.3,2.3,1.7,3.6,1.2c1.4-0.4,3.6-2.1,5-2.6s2.8-0.9,3.9-0.9s2,0,2-1.4S51.5,4.6,51,4.6C50.4,4.6,44.4,4.8,44.4,4.8z"
            />
            <path
              d="M56.4,37.8h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
C56,37.6,56.2,37.8,56.4,37.8z"
            />
            <path
              d="M51.7,62.2h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
C51.4,62,51.5,62.2,51.7,62.2z"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              x1="58.9"
              y1="36.3"
              x2="54.1"
              y2="60.9"
            />
            <polygon points="92.4,49.4 86.5,37.1 89.3,36 93.3,48.9 " />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="square"
              strokeMiterlimit="10"
              x1="81.9"
              y1="18.7"
              x2="50.4"
              y2="26.8"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              d="M19.5,26.3"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="29.1"
              y1="29.7"
              x2="29.1"
              y2="27.4"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="20.9"
              y1="31.5"
              x2="19.9"
              y2="29.4"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="14.2"
              y1="36.7"
              x2="12.4"
              y2="35.2"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="10.3"
              y1="44.2"
              x2="8"
              y2="43.6"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="9.9"
              y1="52.6"
              x2="7.6"
              y2="53"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="13.1"
              y1="60.4"
              x2="11.2"
              y2="61.7"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="19.4"
              y1="66.1"
              x2="18.2"
              y2="68.1"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="27.4"
              y1="68.6"
              x2="27.2"
              y2="71"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="35.8"
              y1="67.5"
              x2="36.6"
              y2="69.7"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="42.9"
              y1="63"
              x2="44.5"
              y2="64.6"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="47.4"
              y1="55.9"
              x2="49.6"
              y2="56.7"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="48.5"
              y1="47.5"
              x2="50.8"
              y2="47.3"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="46"
              y1="39.5"
              x2="48"
              y2="38.3"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="40.3"
              y1="33.2"
              x2="41.6"
              y2="31.3"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="92.6"
              y1="29.4"
              x2="92.6"
              y2="27"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="84.3"
              y1="31.2"
              x2="83.4"
              y2="29.1"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="77.6"
              y1="36.3"
              x2="75.9"
              y2="34.8"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="73.8"
              y1="43.8"
              x2="71.5"
              y2="43.2"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="73.4"
              y1="52.3"
              x2="71.1"
              y2="52.7"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="76.6"
              y1="60"
              x2="74.7"
              y2="61.4"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="82.8"
              y1="65.8"
              x2="81.7"
              y2="67.8"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="99.3"
              y1="67.2"
              x2="100.1"
              y2="69.4"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="106.4"
              y1="62.7"
              x2="108"
              y2="64.3"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="110.9"
              y1="55.5"
              x2="113.1"
              y2="56.3"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="112"
              y1="47.2"
              x2="114.3"
              y2="47"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="109.5"
              y1="39.1"
              x2="111.5"
              y2="38"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="103.8"
              y1="32.9"
              x2="105.1"
              y2="31"
            />
            <polygon points="86.5,25 91.3,39.5 87.5,40.8 82.3,25.8 " />
          </g>
          <g id="cross">
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="91.4"
              cy="49.2"
              r="19.5"
            />
            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="square"
              strokeMiterlimit="10"
              points="42.4,6.9
55.6,48.5 81.5,21.4 80.6,18.7 54.8,18.8 47.3,18.8 "
            />

            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
45.6,17 27.8,48.5 55.6,48.5 "
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
M90.7,19.2c0,0,5.2-0.6,5.2-4.8s-4.5-4.7-4.5-4.7H78.2v0.9L84.4,31l2.2,5.5"
            />
            <circle cx="91.4" cy="49.2" r="2.3" />
            <circle cx="27.8" cy="49.2" r="2.3" />
            <circle cx="55.6" cy="48.9" r="4" />
            <path
              d="M43.1,4.8c-3.7,0-7-0.2-7.6-0.2c-0.6,0-1.9-0.1-2,1.3c-0.1,1.4,0.3,1.7,0.9,2.1c0.5,0.4,2.2,1.5,2.5,1.8
c0.4,0.3,2.3,1.7,3.6,1.2c1.4-0.4,3.6-2.1,5-2.6s2.8-0.9,3.9-0.9s2,0,2-1.4s-1.1-1.5-1.7-1.5C49.1,4.6,43.1,4.8,43.1,4.8z"
            />
            <path
              d="M55.1,37.8H60c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
C54.8,37.6,54.9,37.8,55.1,37.8z"
            />
            <path
              d="M50.5,62.1h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
C50.1,62,50.3,62.1,50.5,62.1z"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              x1="57.6"
              y1="36.3"
              x2="52.8"
              y2="60.8"
            />
            <polygon points="91.1,49.3 85.2,37.1 88,35.9 92,48.9 " />
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="27.8"
              cy="49.2"
              r="19.5"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="27.8"
              y1="29.7"
              x2="27.8"
              y2="27.4"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="19.6"
              y1="31.5"
              x2="18.6"
              y2="29.4"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="12.9"
              y1="36.7"
              x2="11.1"
              y2="35.2"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="9"
              y1="44.2"
              x2="6.7"
              y2="43.6"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="8.6"
              y1="52.6"
              x2="6.3"
              y2="53"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="11.8"
              y1="60.4"
              x2="9.9"
              y2="61.7"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="18.1"
              y1="66.1"
              x2="16.9"
              y2="68.1"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="26.1"
              y1="68.6"
              x2="25.9"
              y2="71"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="34.5"
              y1="67.5"
              x2="35.3"
              y2="69.7"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="41.6"
              y1="63"
              x2="43.2"
              y2="64.6"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="46.1"
              y1="55.9"
              x2="48.3"
              y2="56.7"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="47.2"
              y1="47.5"
              x2="49.5"
              y2="47.3"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="44.7"
              y1="39.5"
              x2="46.7"
              y2="38.3"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="39"
              y1="33.2"
              x2="40.3"
              y2="31.3"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="91.3"
              y1="29.7"
              x2="91.3"
              y2="27.4"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="83.1"
              y1="31.5"
              x2="82.1"
              y2="29.4"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="76.4"
              y1="36.7"
              x2="74.6"
              y2="35.2"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="72.5"
              y1="44.2"
              x2="70.2"
              y2="43.6"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="72.1"
              y1="52.6"
              x2="69.8"
              y2="53"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="75.3"
              y1="60.4"
              x2="73.4"
              y2="61.7"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="81.5"
              y1="66.1"
              x2="80.4"
              y2="68.1"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="89.6"
              y1="68.6"
              x2="89.4"
              y2="71"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="98"
              y1="67.5"
              x2="98.8"
              y2="69.7"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="105.1"
              y1="63"
              x2="106.7"
              y2="64.6"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="109.6"
              y1="55.9"
              x2="111.8"
              y2="56.7"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="110.7"
              y1="47.5"
              x2="113"
              y2="47.3"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="108.2"
              y1="39.5"
              x2="110.2"
              y2="38.3"
            />

            <line
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="102.5"
              y1="33.2"
              x2="103.8"
              y2="31.3"
            />
          </g>
          <g id="city">
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="90.7"
              cy="50.2"
              r="19.5"
            />
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="27.1"
              cy="50.2"
              r="19.5"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="square"
              strokeMiterlimit="10"
              d="M41.6,8l13.2,41.6l27-24.1
l-2-5.8c0,0-13.5,12.6-30.1,12.6"
            />
            <path
              fill="none"
              stroke="#231F20"
              strokeMiterlimit="10"
              d="M120.6,29.4"
            />

            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
44.8,18 27.1,49.6 54.9,49.6 "
            />
            <circle cx="90.7" cy="50.2" r="2.3" />
            <circle cx="27.1" cy="50.2" r="2.3" />
            <circle cx="54.9" cy="49.9" r="4" />
            <path
              d="M42.4,5.9c-3.7,0-7-0.2-7.6-0.2c-0.6,0-1.9-0.1-2,1.3c-0.1,1.4,0.3,1.7,0.9,2.1c0.5,0.4,2.2,1.5,2.5,1.8
c0.4,0.3,2.3,1.7,3.6,1.2c1.4-0.4,3.6-2.1,5-2.6s2.8-0.9,3.9-0.9s2,0,2-1.4s-1.1-1.5-1.7-1.5C48.4,5.6,42.4,5.9,42.4,5.9z"
            />
            <path
              d="M54.4,38.8h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
C54,38.7,54.2,38.8,54.4,38.8z"
            />
            <path
              d="M49.7,63.2h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
C49.4,63,49.5,63.2,49.7,63.2z"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              x1="56.8"
              y1="37.4"
              x2="52.1"
              y2="61.9"
            />
            <polygon points="90.4,50.4 84.4,38.1 87.2,37 91.2,49.9 " />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
M80.4,1.5l5.7,18.6c0,0,10,0.6,10-10.7c0-5.9,0.1-7.9,0.1-7.9H80.4z"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
M39.5,27.6c-3.7-2-7.9-3.2-12.4-3.2C12.8,24.4,1.3,36,1.3,50.2"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
M81.9,25.6c2.4-0.8,5-1.2,7.7-1.2c14.3,0,25.8,11.6,25.8,25.8"
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
M68.4,1.5c0,0,3.5,0,5.8,0L83.6,32l2.2,5.5"
            />
          </g>
          <g id="racer">
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="90.4"
              cy="48.9"
              r="19.5"
            />
            <circle
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              cx="26.8"
              cy="48.9"
              r="19.5"
            />
            <polyline
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              points="41.4,6.6 54.6,48.2 80.5,21.1 79.6,18.4
  44,15 "
            />
            <polyline
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
  44.6,16.7 26.8,48.2 54.6,48.2 "
            />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
  M89.7,18.9c0,0,5.2-0.6,5.2-4.8s-4.5-4.7-4.5-4.7H77.2v0.9l6.1,20.3l2.2,5.5"
            />
            <circle cx="90.4" cy="48.9" r="2.3" />
            <circle cx="26.8" cy="48.9" r="2.3" />
            <circle cx="54.6" cy="48.5" r="4" />
            <path
              d="M42.1,4.5c-3.7,0-7-0.2-7.6-0.2c-0.6,0-1.9-0.1-2,1.3c-0.1,1.4,0.3,1.7,0.9,2.1c0.5,0.4,2.2,1.5,2.5,1.8
  c0.4,0.3,2.3,1.7,3.6,1.2c1.4-0.4,3.6-2.1,5-2.6s2.8-0.9,3.9-0.9s2,0,2-1.4s-1.1-1.5-1.7-1.5C48.1,4.3,42.1,4.5,42.1,4.5z"
            />
            <path
              d="M54.1,37.5h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
  C53.8,37.3,53.9,37.5,54.1,37.5z"
            />
            <path
              d="M49.4,61.8h4.9c0.2,0,0.4-0.2,0.4-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-4.9c-0.2,0-0.4,0.2-0.4,0.4v1.9
  C49,61.7,49.2,61.8,49.4,61.8z"
            />
            <line
              fill="none"
              stroke="#000000"
              strokeMiterlimit="10"
              x1="56.6"
              y1="36"
              x2="51.8"
              y2="60.5"
            />
            <polygon points="90.2,49 84.2,36.7 87,35.6 91,48.5 " />
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="
  M90.4,9.4c0,0,4.5,0,6.3-0.4c1.2-0.3,2.1-1.9,2.1-1.9"
            />
          </g>
        </g>

        {/* FILTER ICONS */}
        <g id="childFriendly">
          <path
            d="M32,0C14.3,0,0,14.3,0,32s14.3,32,32,32c17.7,0,32-14.3,32-32S49.7,0,32,0z M32,60C16.6,60,4,47.4,4,32
    S16.6,4,32,4s28,12.6,28,28S47.4,60,32,60z"
          />
          <circle cx="46.5" cy="32" r="4" />
          <circle cx="17.5" cy="32" r="4" />
          <path d="M45.1,42.8H18.9C18.9,42.8,32.8,57.1,45.1,42.8z" />
        </g>
        <g id="railway">
          <path
            d="M32,0C14.3,0,0,14.3,0,32s14.3,32,32,32c17.7,0,32-14.3,32-32S49.7,0,32,0z M55.6,17l-7.2,7.2l-9.6-9.6
    l6.9-6.9C49.7,9.9,53.1,13.1,55.6,17z M28,25.3l7.9-7.9l9.6,9.6l-7.9,7.9L28,25.3z M34.8,37.8l-8.6,8.6l-9.6-9.6l8.6-8.6L34.8,37.8
    z M32,4c3.1,0,6,0.5,8.8,1.4L35.2,11L32.3,8l-2.9,2.9l2.9,2.9l-7.9,7.9l-2.9-2.9l-2.9,2.9l2.9,2.9l-8.6,8.6L10,30.3l-2.9,2.9
    l2.9,2.9l-4.7,4.7C4.5,38,4,35.1,4,32C4,16.6,16.6,4,32,4z M7.6,45.7l6-6l9.6,9.6L17,55.6C13.1,53.1,9.9,49.7,7.6,45.7z M32,60
    c-3.6,0-7.1-0.7-10.3-2l5.1-5.1l2.9,2.9l2.9-2.9L29.7,50l8.6-8.6l2.9,2.9l2.9-2.9l-2.9-2.9l7.9-7.9l2.9,2.9l2.9-2.9L52,27.8l6-6
    c1.3,3.2,2,6.6,2,10.3C60,47.4,47.4,60,32,60z"
          />
        </g>
        <g id="gravel">
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -11.2818 6.5446)"
            cx="16.2"
            cy="41"
            rx="2"
            ry="2"
          />
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -8.6657 8.4804)"
            cx="24"
            cy="33.2"
            rx="2"
            ry="2"
          />
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -6.0491 10.4156)"
            cx="31.8"
            cy="25.4"
            rx="2"
            ry="2"
          />
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -3.4328 12.3514)"
            cx="39.6"
            cy="17.7"
            rx="2"
            ry="2"
          />
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -11.6142 9.9788)"
            cx="27.6"
            cy="43.8"
            rx="2"
            ry="2"
          />
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -8.9979 11.9143)"
            cx="35.3"
            cy="36"
            rx="2"
            ry="2"
          />
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -6.382 13.8512)"
            cx="43.1"
            cy="28.3"
            rx="2"
            ry="2"
          />
          <ellipse
            transform="matrix(0.9562 -0.2926 0.2926 0.9562 -3.7653 15.7862)"
            cx="50.9"
            cy="20.5"
            rx="2"
            ry="2"
          />
          <path
            d="M63.3,25.3c-0.2-0.7-0.3-1.4-0.5-2.1c-1.4-5-4.2-9.8-8.1-13.7c-4-4-8.7-6.7-13.7-8.1
    c-0.7-0.2-1.4-0.4-2.1-0.5C28.5-1.5,17.3,1.4,9.4,9.4S-1.5,28.5,0.7,38.7c0.2,0.7,0.3,1.4,0.5,2.1c1.4,5,4.2,9.8,8.1,13.7
    c4,4,8.7,6.7,13.7,8.1c0.7,0.2,1.4,0.4,2.1,0.5c10.3,2.2,21.4-0.7,29.4-8.6C62.6,46.7,65.5,35.5,63.3,25.3z M12.2,12.2
    c6.3-6.3,14.8-8.9,23.1-8l-31,31C3.3,27,5.9,18.5,12.2,12.2z M23,58.5c-3.9-1.3-7.6-3.6-10.8-6.7C9.1,48.7,6.8,45,5.5,41L41,5.5
    c3.9,1.3,7.6,3.6,10.8,6.7c3.1,3.1,5.4,6.8,6.7,10.8L23,58.5z M51.8,51.8c-6.3,6.3-14.8,8.9-23.1,8l31-31
    C60.7,37,58.1,45.5,51.8,51.8z"
          />
        </g>
        <g id="camping">
          <path
            d="M32,0c-1.1,0-2.2,0.1-3.3,0.2c-1.1,0.1-2.1,0.3-3.2,0.5c-2.1,0.4-4.1,1.1-6,1.9c-0.8,0.3-1.6,0.7-2.3,1.1
    c-0.6,0.3-1.2,0.7-1.8,1c-0.4,0.3-0.9,0.5-1.3,0.8c-0.5,0.3-1,0.7-1.5,1.1c-0.5,0.4-1,0.8-1.5,1.2c-0.6,0.5-1.2,1-1.7,1.6
    c-0.5,0.5-1,1.1-1.5,1.7c-0.5,0.6-1,1.2-1.5,1.8c-1.1,1.5-2.1,3.1-3,4.8C3.2,18,3,18.4,2.9,18.8C1,22.8,0,27.3,0,32
    c0,5,1.1,9.7,3.2,13.9c0.9,1.9,2,3.6,3.2,5.3c0.6,0.8,1.3,1.6,2,2.4c0.7,0.8,1.4,1.5,2.2,2.2c0.5,0.4,1,0.9,1.5,1.3
    c0.7,0.6,1.4,1.1,2.1,1.6c0.4,0.3,0.9,0.6,1.3,0.8c0.9,0.5,1.8,1,2.7,1.5c0.5,0.2,0.9,0.4,1.4,0.6c1.1,0.5,2.2,0.8,3.3,1.2
    c0.9,0.3,1.8,0.5,2.7,0.7c1,0.2,2.1,0.4,3.2,0.5c1.1,0.1,2.2,0.2,3.3,0.2c3.2,0,6.3-0.5,9.2-1.3c4.3-1.3,8.2-3.4,11.5-6.2
    C59.6,50.5,64,41.8,64,32C64,14.3,49.7,0,32,0z M37.9,54.8H27.1c-0.9,0-1.4-1-1-1.7l5.4-9.3c0.4-0.8,1.6-0.8,2,0l5.4,9.3
    C39.3,53.8,38.8,54.8,37.9,54.8z M50.6,52.9L38.1,31.1l-3.3-6l4.5-8.1c0.5-1,0.2-2.2-0.8-2.7c-1-0.5-2.2-0.2-2.7,0.8l-3.3,6l-3.3-6
    c-0.5-1-1.8-1.3-2.7-0.8c-1,0.5-1.3,1.8-0.8,2.7l4.5,8.1l-4.5,8.1c0,0,0,0,0,0L14,53.4C7.9,48.3,4,40.6,4,32C4,16.6,16.6,4,32,4
    s28,12.6,28,28C60,40.3,56.4,47.7,50.6,52.9z"
          />
        </g>
        <g id="trafficFree">
          <path
            d="M32,0C14.3,0,0,14.3,0,32s14.3,32,32,32s32-14.3,32-32S49.7,0,32,0z M4,32C4,16.6,16.6,4,32,4
    c6.5,0,12.5,2.3,17.3,6l-6.8,6.8c-0.9-0.5-2-0.8-3.4-0.8c-4.7,0-9.5,0-14.2,0c-3.1,0-5,1.5-5.7,4.5c-0.3,1.4-0.6,2.9-0.9,4.3
    c-0.5,2.1-0.5,4.4-2.7,5.8c-0.7,0.4-1.1,1.7-1.2,2.6c-0.2,1.7-0.3,3.4,0,5c0.2,1.2,0.9,2.4,1.7,3.2c0.3,0.3,0.6,0.6,0.8,0.9
    L10,49.3C6.3,44.5,4,38.5,4,32z M43.1,39.3c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7S44.6,39.3,43.1,39.3z
     M43,28.8c-2.3,0-4.5,0-6.8,0l5.7-5.7C42.3,24.9,42.6,26.8,43,28.8z M17.5,36.6c0-1.5,1.2-2.7,2.7-2.7c1.4,0,2.6,1.1,2.7,2.6
    l-2.8,2.8C18.6,39.2,17.5,38,17.5,36.6z M21,28.8c0.5-2.6,0.9-5.2,1.6-7.7c0.2-0.7,1.4-1.5,2.1-1.5c4.8-0.1,9.6-0.1,14.4,0
    c0.1,0,0.3,0,0.5,0.1l-9.1,9.1C27.4,28.8,24.2,28.8,21,28.8z M29.7,35.2h7.7c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1h-9.8L29.7,35.2z
     M32,60c-7.5,0-14.3-2.9-19.3-7.7l5.8-5.8c0.4,0.8,1.2,1.3,2.1,1.3c1.3,0,2.3-1,2.3-2.3v-1.8c0-0.3-0.1-0.6-0.2-0.9h18.4
    C41.1,43,41,43.3,41,43.6v1.8c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3v-1.8c0-0.3-0.1-0.6-0.2-0.9h1.4c0.4-0.6,0.9-1.1,1.5-1.6
    c0.7-0.6,1.2-1.7,1.3-2.6c0.2-1.9,0.2-3.8,0-5.6c-0.1-0.9-0.6-2-1.2-2.4c-2.3-1.3-2.2-3.6-2.7-5.7c-0.3-1.4-0.5-2.9-0.9-4.3
    c0-0.2-0.1-0.3-0.1-0.4l7.5-7.5c4.8,5,7.7,11.8,7.7,19.3C60,47.4,47.4,60,32,60z"
          />
        </g>
        <path
          id="tarmac"
          d="M32,0C14.3,0,0,14.3,0,32s14.3,32,32,32s32-14.3,32-32S49.7,0,32,0z M47.4,19.3l-5.7,5.7
  l-2.1-2.1l5.7-5.7L47.4,19.3z M5.2,40C4.4,37.4,4,34.8,4,32C4,16.6,16.6,4,32,4c2.8,0,5.4,0.4,8,1.2L5.2,40z M36.1,30.6l-5.7,5.7
  l-2.1-2.1l5.7-5.7L36.1,30.6z M19.1,47.6L17,45.4l5.7-5.7l2.1,2.1L19.1,47.6z M32,60c-2.3,0-4.5-0.3-6.6-0.8l33.8-33.8
  c0.5,2.1,0.8,4.4,0.8,6.6C60,47.4,47.4,60,32,60z"
        />
        <g id="circular">
          <path
            d="M32,0C14.3,0,0,14.3,0,32s14.3,32,32,32s32-14.3,32-32S49.7,0,32,0z M32,60C16.6,60,4,47.4,4,32
    S16.6,4,32,4s28,12.6,28,28S47.4,60,32,60z"
          />
          <path
            d="M48.3,19.5c-0.2-0.6-0.8-0.9-1.4-0.9h-6.3c-0.8,0-1.5,0.7-1.5,1.5v6.3c0,0.6,0.4,1.2,0.9,1.4
    c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.2,1.1-0.4l1.8-1.8c1.1,1.9,1.6,4.1,1.6,6.4c0,7.3-6,13.3-13.3,13.3c-2.4,0-4.8-0.7-6.8-1.9
    c0,0,0,0,0,0c-2-1.2-3.7-2.9-4.8-5c-1.1-2-1.7-4.2-1.7-6.4c0-7.3,6-13.3,13.3-13.3c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5
    c-9,0-16.3,7.3-16.3,16.3c0,2.8,0.7,5.5,2,7.9c1.4,2.5,3.4,4.6,5.9,6.1c0,0,0,0,0,0c2.5,1.5,5.4,2.3,8.4,2.3
    c9,0,16.3-7.3,16.3-16.3c0-3.1-0.9-6-2.5-8.6l2.3-2.3C48.4,20.7,48.5,20.1,48.3,19.5z"
          />
        </g>
        <g id="national">
          <path
            d="M59,49.2c1.1-1.7,2-3.6,2.8-5.5c0,0,0,0,0,0c0.8-1.9,1.3-4,1.7-6.1c0,0,0,0,0,0h0c0.3-1.8,0.5-3.7,0.5-5.6
    c0-2-0.2-4-0.6-6h0c0,0,0,0,0,0c-0.6-3-1.5-5.8-2.8-8.4c0,0,0,0,0-0.1c-0.2-0.5-0.5-1-0.8-1.4c0,0,0-0.1-0.1-0.1
    c-0.3-0.5-0.5-0.9-0.8-1.4c0,0,0-0.1-0.1-0.1c-0.3-0.5-0.6-0.9-1-1.4c0,0,0,0,0,0c-1.4-1.9-2.9-3.6-4.6-5.1c0,0-0.1-0.1-0.1-0.1
    c-0.4-0.3-0.8-0.7-1.2-1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.3-0.8-0.6-1.2-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.3-0.8-0.6-1.2-0.8
    c-0.1,0-0.1-0.1-0.2-0.1c-0.5-0.3-1-0.6-1.4-0.9c0,0,0,0,0,0h0C42.9,1.5,37.6,0,32,0c-6.2,0-12,1.8-16.9,4.9
    c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.3-0.8,0.6-1.2,0.9c-0.1,0-0.1,0.1-0.2,0.1
    c-0.4,0.3-0.8,0.6-1.2,1c0,0-0.1,0.1-0.1,0.1c-2.1,1.9-4,4.1-5.6,6.5c0,0,0,0.1-0.1,0.1c-0.3,0.4-0.6,0.9-0.8,1.4
    c0,0,0,0.1-0.1,0.1c-0.3,0.5-0.5,1-0.8,1.5c0,0,0,0,0,0.1C1.2,22,0,26.8,0,32c0,7.4,2.5,14.3,6.8,19.7c0,0,0,0,0,0
    c3.2,4,7.2,7.3,12,9.4c0,0,0,0,0,0l0,0C22.8,63,27.3,64,32,64c3.9,0,7.7-0.7,11.2-2h0c0,0,0,0,0,0c0.6-0.2,1.2-0.5,1.8-0.7
    c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,1-0.5,1.6-0.8c0.1,0,0.1-0.1,0.2-0.1c1.2-0.6,2.3-1.3,3.3-2.1c0.1-0.1,0.2-0.1,0.3-0.2
    c0.5-0.4,1-0.7,1.4-1.1c0,0,0.1,0,0.1-0.1c1-0.8,2-1.7,2.9-2.7c0.1-0.1,0.1-0.1,0.2-0.2C56.6,52.6,57.8,51,59,49.2
    C58.9,49.3,58.9,49.2,59,49.2z M4.6,37.6C4.2,35.8,4,33.9,4,32c0-2,0.2-4,0.6-6h14.1V7.3c3.5-1.9,7.4-3,11.6-3.3v22h29
    C59.8,28,60,30,60,32c0,1.9-0.2,3.8-0.6,5.6H30.4v22.3c-4.2-0.2-8.1-1.4-11.6-3.3v-19H4.6z"
          />
        </g>

        /* Route place icons */
        <g id="place_camping">
          <polygon
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
    775.7,485.6 707.7,401.4 759.4,401.4 698.7,327.1 742.1,327.1 679.6,250.1 619.8,327.1 661.5,327.1 603.5,401.4 651.5,401.4
    586.3,485.6   "
          />

          <polyline
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
    288.2,10.9 531.9,577.2 379.7,577.2 332.6,429.5 288.2,577.2  "
          />

          <polyline
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
    681.4,498.3 681.4,584.4 724,584.4 65.3,584.4 135.1,584.4 379.7,10.9   "
          />
        </g>
        <g id="place_hotel">
          <line
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="289"
            y1="568.4"
            x2="289"
            y2="368.6"
          />
          <polyline
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
    289,482.5 567.5,482.5 567.5,520.8 567.5,564.3   "
          />
          <path
            d="M552,458.7H370.8c-12.4,0-22.8-10.4-22.8-22.8l0,0c0-12.4,10.4-22.8,22.8-22.8H552c12.4,0,22.8,10.4,22.8,22.8l0,0
    C574.8,448.3,564.4,458.7,552,458.7z"
          />
          <circle cx="326.3" cy="439" r="15.5" />

          <polygon
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
    224.8,11.4 416.4,11.4 614.1,11.4 614.1,580.8 223.8,580.8  "
          />
          <path
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
    M479.5,11.4"
          />
          <path
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
    M390.5,11.4"
          />
        </g>
        <g id="place_hostel">
          <polygon
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
    14.2,236.8 245,39.7 483.2,229.3 483.2,551.1 13,551.1  "
          />
          <line
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="91.5"
            y1="534.9"
            x2="91.5"
            y2="294.1"
          />
          <polyline
            fill="none"
            strokeWidth="14"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
    91.5,432.6 427.1,432.6 427.1,477.5 427.1,529.9  "
          />
          <path
            d="M408.3,402.7H190.1c-15,0-27.4-12.5-27.4-27.4l0,0c0-15,12.5-27.4,27.4-27.4h218.3c15,0,27.4,12.5,27.4,27.4l0,0
    C435.8,390.2,423.3,402.7,408.3,402.7z"
          />
          <circle cx="136.4" cy="379" r="18.7" />
          <g>
            <polygon
              fill="none"
              strokeWidth="14"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              points="
      824.9,415.1 730.1,299.1 801.2,299.1 717.7,195.6 777.5,195.6 691.5,90.8 609.1,195.6 666.5,195.6 586.7,299.1 652.8,299.1
      563,415.1     "
            />
            <line
              fill="none"
              strokeWidth="14"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="694"
              y1="432.6"
              x2="694"
              y2="552.3"
            />
          </g>
        </g>
      </def>
    </svg>
  );
}

BikeIcon.displayName = "BikeIcon";
