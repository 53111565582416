"use strict";

const React = require("react");

const PropTypes = require("./PropTypes");
const { compareLngLat } = require("./utils");

class Popup extends React.Component {
  static displayName = "Popup";

  static propTypes = {
    ...PropTypes.PopupOptions,
    ...PropTypes.PopupComponentOptions
  };

  static contextTypes = {
    map: React.PropTypes.object
  };

  constructor(props) {
    /* global mapboxgl */
    super(props);
    let offset = new mapboxgl.Point(0, -100);

    this.state = {
      popup: new mapboxgl.Popup({ ...props, offset: offset })
    };
  }

  componentDidMount() {
    let { map } = this.context;
    let { popup } = this.state;
    if (this.props.lngLat) {
      popup.setLngLat(this.props.lngLat);
    }
    if (this.refs.content.innerHTML) {
      popup.setHTML(this.refs.content.innerHTML);
    }
    popup.addTo(map);
    map.resize(); // force a re-render
  }

  componentWillUnmount() {
    let { popup } = this.state;

    popup.remove();
  }

  componentWillReceiveProps(nextProps) {
    let { popup } = this.state;

    if (this.props.lngLat !== nextProps.lngLat) {
      popup.setLngLat(nextProps.lngLat);
    }
  }

  componentDidUpdate() {
    let { popup } = this.state;
    this.html = this.refs.content.innerHTML;
    popup.setHTML(this.html);
  }

  shouldComponentUpdate(nextProps) {
    return this.html !== this.refs.content.innerHTML ||
      compareLngLat(this.props.lngLat, nextProps.lngLat);
  }

  render() {
    return <noscript ref="content" children={this.props.children} />;
  }
}

module.exports = Popup;
