module.exports={
  "locale": "de",
  "FilterOptions": {
    "showFilter": "Filter anzeigen",
    "hideFilter": "Filter verbergen"
  },
  "About": {
    "title": "Bed & Bike ist eine Zusammenarbeit zwischen Schwedens größter Interessensvertretung für Radfahrerinnen und Radfahrer (Cyckelfrämjandet) und ausgewählten Hotels, Jugendherbergen und weiteren Ferienunterkünften",
    "intro": " <h3>Unsere Partner bieten besonderen Service für fahrradfahrende Gäste.</h3> <p>Bed & Bike ist eine fantastische Idee für Dich, wenn du auf Deiner Reise Dich und dein Fahrrad bestens versorgt wissen willst.</p><p>Nachts soll Dein Fahrrad sicher untergestellt sein. Du willst Zugang zu Werkzeug und Luft für die Reifen. Du möchtest morgens Proviant für den Tag einpacken können. Du möchtest spontan einen Stop einlegen können, wenn es dir einem Ort gefällt. Du willst dem Wetter und der eigenen Lust folgen können. Und du willst unterwegs gerne andere enthusiastische Fahrradfahrer treffen…</p><h3>Bist Du an einer Zusammenarbeit interessiert?</h3><p>Wenn du eine Unterkunft entlang einer unserer Routen besitzt und an einer Zusammenarbeit interessiert bist, schicke eine E-Mail an: <a href='mailto:info@bedandbike.se'>info@bedandbike.se</a></p>",
    "partners": "<h3>Du bist bereits Partner von uns?</h3><p>Wenn du eine Unterkunft besitzt, die auf unserer Website gelistet ist, und deren Beschreibung und Bilder ändern möchtest, aktualisiere bitte die entsprechenden Einträge bei <a href='https://www.google.se/intl/sv/business/' target='_blank'> Google places</a>. Um Bewertungen Deiner Gäste zu bekommen, bitte sie, diese auf Google abzugeben.</p><p>Von uns zertifizierte Unterkünfte erhalten mehr Publizität auf unserer Website, weil sie besonderen Service für fahrradfahrende Touristen bieten. Bed & Bike ist nicht verantwortlich für die Richtigkeit der zur Verfügung gestellten Informationen über die Unterkünfte.</p><p>Für weitere Informationen über die Zertifizierung kontaktiere uns per E-Mail:</p><ul> <li> E-post: <a href='mailto:info@bedandbike.se'>info@bedandbike.se</a> </li> <li> Telefon: <a href='tel:+46812151331'>+46 8-121 513 31</a>, Telefonzeiten Montag-Donnerstag 8:30 Uhr - 12.00 Uhr, Freitag 8:30 Uhr - 10.45 Uhr</li></ul>",
    "cykelframjandetTitle": "Cykelfrämjandet ist hauptverantwortlich für Bed & Bike in Schweden",
    "cykelframjandetBody": "<p><a href='http://cykelframjandet.se/' target='_blank'>Cykelfrämjandet</a>  ist Schwedens größte Fahrradorganisation. Wir sind eine politisch unabhängige, ideelle Organisation, die seit über 80 Jahren existiert. Die rund 30 lokalen Verbände verteilen sich über das ganze Land. Unser Ziel ist es, Schweden zu einer Fahrradnation zu machen.</p><p>Um dies zu erreichen, arbeiten wir auf lokaler, regionaler und landesweiter Ebene und kämpfen für eine bessere Umwelt, Gesundheit und eine bessere Verkehrssicherheit. Cykelfrämjandet startete Bed&Bike in Schweden 2012.</p><p>Die Idee zu Bed&Bike stammt von der deutschen Organisation Bett und Bike, die heute mehr als 5400 Unterkünfte umfasst. Seit dem Beginn arbeiten wir mit der schwedischen Verkehrsbehörde zusammen, um den schnell wachsenden Fahrradtourismus weiterzuentwickeln.</p>",
    "tech": "<h3>Technik</h3><p>Der Dienst wurde entwickelt von <a href='http://department.se'>Department</a>. Der Dienst verwendet ortsgebundene Informationen von <a href='https://www.google.se/intl/sv/business/' target='_blank'>Google Places</a> nd Kartenmaterial von <a href='https://www.openstreetmap.org/' target='_blank'>Openstreetmap</a>.</p><p>Möchtest Du uns dabei helfen, das Kartenmaterial zu verbessern? Nimm gerne Kontakt zu uns auf! Das Kartenmaterial wird von <a href='https://www.openstreetmap.org/' target='_blank'>Openstreetmap</a> bereitgestellt.</p> <p>Kontaktiere uns, wenn du mehr Informationen erhalten möchtest.</p>",
  },
  "BikeRouteList": {
    "noMatch": "boenden längs rutten",
    "noMatchAgain": "boenden synliga på kartan"
  },
  "BikeRoute": {
    "backButton": "Routenübersicht",
    "lodging": "Unterkünfte",
    "description": "Routenbeschreibung",
    "sights": "Sehenswürdigkeiten",
    "saveWhitelist": "Spara vitlistningen",
    "whitelistChanged": "Vitlistningen har ändrats"
  },
  "BikePlaceList": {
    "totalLodging": "unterkünfte entlang der Route",
    "visibleLodging": "sichtbar auf der Karte"
  },
  "BikePlaceListItem":{
    "toggleInfo": "Weitere Informationen"
  },
  "BikeRouteList": {
    "noMatch": "Keine Route entspricht den ausgewählten Kriterien",
    "noMatchAgain": "Vergrößere den Kartenausschnitt und/oder ändere die gewählten Filtereinstellungen, um eine Übersicht aller Routen zu erhalten."
  },
  "Header": {
    "aboutBedAndBike": "Über Bed&Bike",
    "intro": "intro",
    "locale": "de"
  },
  "LanguageSelector":{
    "en": "English",
    "de": "Deutsch",
    "sv": "Svenska"
  },
  "StartContainer":{
    "title": "Fahrrad-Tourismus in Schweden beginnt hier",
    "description": "Bed & Bike inspiriert und bietet Hilfe. Die komplette Webseite für alle – vom neugierigen Anfänger bis zum fortgeschrittenen Abenteurer.",
    "continueButton": "Entdecke die Routen"
  },
  "BikePlaceNavButton": {
    "lodging": "Unterkünfte",
    "sights": "Sehenswürdigkeiten",
    "misc": "Sonstiges"
  },
  "FilterButton": {
    "childFriendly": "Kinderfreundlich",
    "railway": "Bahndamm",
    "gravel": "Kies/Schotter",
    "camping": "Camping",
    "trafficFree": "Wenig Verkehr",
    "tarmac": "Asphalt",
    "circular": "Rundreise",
    "national": "National-Fahrradweg"
  },
  "SaveBar": {
    "whitelistChanged": "Whitelist has been changed, continue making changes or save changes.",
    "saveWhitelist": "Save whitelist",
    "routeChanged": "Route information has been changed, continue making changes or save them.",
    "saveRoute": "Save route",
    "placeChanged": "Places are changed",
    "savePlace": "Save updates"
  },
}
