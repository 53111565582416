const React = require("react");
const { ReactCSS } = require("reactcss");
const { connect } = require("react-redux");

const actions = require("../actions");
const { bikeRoutesSelector } = require("../selectors");
const {
  INITIAL,
  PENDING,
  PAYLOAD,
  SUCCESS,
  FAILURE
} = require("../reducers/ready-states");

const FilterOptions = require("../components/FilterOptions");
const BikeRouteList = require("../components/BikeRouteList");
const Loader = require("../components/Loader");

const StyleGlobals = require("../styles/globals");

class BikeRoutes extends React.Component {
  static displayName = "BikeRoutes";

  componentWillMount() {
    this.props.fetchRoutesIfNeeded();
  }

  styles = () => {
    return ReactCSS(this.classes());
  };

  classes = () => {
    return {
      default: {
        listContainer: {
          position: "relative",
          width: "100%",
          background: StyleGlobals.bgColors.superLight,
          flex: "1 0 auto",
          backfaceVisibility: "hidden",
          transform: "translateZ(0)"
        }
      }
    };
  };

  render() {
    switch (this.props.readyState) {
      case INITIAL:
        return null;
      case PENDING:
        return this.renderPending();
      case PAYLOAD:
      case SUCCESS:
        return this.renderSuccess();
      case FAILURE:
        return this.renderFailure();
    }
  }

  renderPending = () => {
    return (
      <div style={this.styles().listContainer}>
        <Loader />
      </div>
    );
  };

  renderFailure = () => {
    return <p>ERROR WHILE LOADING...{this.props.error}</p>;
  };

  renderSuccess = () => {
    let { facets, routes, highlightedRoute, focusedRoute } = this.props;
    return (
      <div style={this.styles().listContainer} ref="listContainer">
        <FilterOptions
          facets={facets}
          onChange={this.props.onFilterChange}
          isOpen={true}
        />
        <BikeRouteList
          highlighted={highlightedRoute}
          focused={focusedRoute}
          routes={routes}
          highlightRoute={this.props.onRouteHighlight}
          scrollElement={this.refs.listContainer}
        />
      </div>
    );
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRoutesIfNeeded: () => dispatch(actions.fetchRoutesIfNeeded()),
    onFilterChange: filter => dispatch(actions.updateFilter(filter)),
    onRouteHighlight: id => dispatch(actions.highlightRoute(id))
  };
}

module.exports = connect(bikeRoutesSelector, mapDispatchToProps)(BikeRoutes);
