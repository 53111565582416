const fixOrphans = (txt = "", n = 1) => {
  var sp = " ";
  var nbsp = " ";
  var parts = txt.split(sp);
  if (parts.length <= n) {
    return parts.join(nbsp);
  }
  return parts.slice(0, -n).join(sp) + nbsp + parts.slice(-n).join(nbsp);
};

module.exports = fixOrphans;
