/* eslint-env browser */
const thunk = require("redux-thunk");
const { createStore, applyMiddleware } = require("redux");

const reducer = require("./reducer");

const noop = () => next => action => next(action);

function configure(initialState = {}) {
  const DEV = "production" !== "production";
  const create = DEV &&
    typeof window !== "undefined" &&
    window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore;

  const createStoreWithMiddleware = applyMiddleware(
    thunk,
    DEV ? require("./middleware/logger") : noop
    //DEV ? require('./middleware/benchmark') : noop,
  )(create);

  let store = createStoreWithMiddleware(reducer, initialState);

  if (module.hot) {
    module.hot.accept("./reducer", () => {
      const nextReducer = require("./reducer");
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

module.exports = configure;
