"use strict";

const React = require("react");
const ReactResizeDetector = require("react-resize-detector").default;

const PropTypes = require("./PropTypes");
const { compareBounds, two } = require("./utils");
const events = Object.keys(PropTypes.MapComponentEvents);

class MapContext extends React.Component {

  constructor(props) {
    super(props);
    /* global mapboxgl */
    let { options } = props;
    let map = new mapboxgl.Map({
      container: props.div,
      style: options.styleURI,
      center: options.center,
      zoom: options.zoom,
      classes: options.classes,
      interactive: options.interactive || true
    });

    if ("production" !== "production") {
      global.ReactMapbox = map;
    }

    this.state = {
      isReady: false,
      bounds: null,
      map
    };


    this.onMapResize = () => {
      this.setState({ didResize: true });
    };
  }

  getChildContext() {
    return {
      map: this.state.map,
      batch: this.state.batch
    };
  }

  componentDidMount() {
    let { map } = this.state;
    let { options } = this.props;

    // set bounds to null by default
    this.bounds = null;

    map.once("style.load", () => this.setState({ isReady: true }));

    events.forEach(event => {
      if (event in options) {
        map.on(event.slice(2).toLowerCase(), options[event]);
      }
    });
  }

  componentWillUnmount() {
    let { map } = this.state;
    map.off();
  }

  componentWillUpdate(nextProps) {
    let { map } = this.state;
    let curr = this.props.options;
    let next = nextProps.options;

    // TODO set zoom, pitch, center, bearing (use `map.jumpTo()` if smooth == false and `map.flyTo()` otherwise)

    // update any event listeners that changed
    events.forEach(event => {
      let had = event in curr && typeof curr[event] === "function";
      let have = event in next && typeof next[event] === "function";
      let name = event.slice(2).toLowerCase();
      if (had && have && curr[event] !== next[event]) {
        map.off(name);
        map.on(name, next[event]);
      } else if (had && !have) {
        map.off(name);
      } else if (!had && have) {
        map.on(name, next[event]);
      }
    });

    // update classes that changed
    if (curr.classes && next.classes) {
      curr.classes.concat(next.classes).forEach(c => {
        let had = curr.classes.indexOf(c) > -1;
        let have = next.classes.indexOf(c) > -1;
        if (had && !have) {
          map.removeClass(c);
        } else if (!had && have) {
          map.addClass(c);
        }
      });

      // remove all old classes
    } else if (curr.classes) {
      curr.classes.forEach(c => map.removeClass(c));

      // add all new classes
    } else if (next.classes) {
      next.classes.forEach(c => map.addClass(c));
    }

    let nextBounds = next.getBounds ? next.getBounds() : null;
    if (compareBounds(this.bounds, nextBounds) && nextBounds) {
      if (nextBounds.bounds) {
        let { bounds, ...options } = nextBounds;
        map.fitBounds(two(bounds), options);
      } else {
        map.fitBounds(two(nextBounds));
      }
      this.bounds = nextBounds;
    }
  }

  componentDidUpdate() {
    let { map, didResize } = this.state;
    if (didResize) {
      this.setState({ didResize: false }, () => {
        map.resize();
      });
    }
  }

  shouldComponentUpdate() {
    return this.state.isReady;
  }

  render() {
    return (
      <div
        style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
      >
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.onMapResize}
        />
        {this.state.isReady ? this.props.children : null}
      </div>
    );
  }

}

MapContext.displayName = "MapContext";

MapContext.propTypes = {
  div: React.PropTypes.object.isRequired,
  options: React.PropTypes.shape({
    ...PropTypes.MapOptions,
    ...PropTypes.MapComponentOptions,
    ...PropTypes.MapComponentEvents
  }).isRequired
};

MapContext.childContextTypes = {
  map: React.PropTypes.object,
  batch: React.PropTypes.object
};


module.exports = MapContext;
