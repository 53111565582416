const React = require("react");
const { ReactCSS } = require("reactcss");
const { translate } = require("react-translate");

const StyleGlobals = require("../styles/globals");
const withMQ = require("../mixins/mq");

class About extends React.Component {
  static displayName = "About";

  classes = () => {
    return {
      default: {
        container: {
          lineHeight: 1.4,
          width: "100%",
          // minHeight: "100%",
          padding: StyleGlobals.dimen24
        },
        article: {
          maxWidth: "800px",
          margin: `0 auto ${StyleGlobals.dimen48}`,
          lineHeight: 1.6
        },
        title: {
          fontSize: StyleGlobals.dimen36,
          margin: "0 0 24px",
          lineHeight: 1.2
        },
        h3: {
          fontSize: StyleGlobals.dimen16,
          margin: 0
        },
        flex: {
          display: "flex",
          alignItems: "center"
        },
        flex1: {
          flex: "1 1 auto",
          width: "100%",
          order: 1
        },
        flex2: {
          flex: "1 2 auto",
          width: "100%",
          order: 1
        },
        flex3: {
          flex: "1 3 auto",
          width: "100%",
          order: 1
        },
        cykelframjandet: {
          width: "100%",
          height: "auto",
          alignSelf: "center",
          padding: StyleGlobals.dimen36
        },
        logo: {
          order: 2
        },
        img: {
          width: "100%",
          margin: "24px 0 48px",
          display: "block"
        }
      },
      mobile: {
        title: {
          fontSize: StyleGlobals.dimen24
        },
        flex: {
          flexWrap: "wrap"
        },
        flex1: {
          flex: "1 1 auto"
        },
        flex2: {
          flex: "1 1 auto"
        },
        logo: {
          order: 0,
          maxWidth: 330
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.props);
  };

  render() {
    const { t } = this.props;
    return (
      <section style={this.styles().container} className="html-field">
        <article style={this.styles().article}>

          <img style={this.styles().img} src="/images/intro-top.jpg" />
          <h1 style={this.styles().title}>{t("title")}</h1>
          <div dangerouslySetInnerHTML={{ __html: t("intro") }} />
        </article>
        <article
          style={this.styles().article}
          dangerouslySetInnerHTML={{ __html: t("partners") }}
        />
        <article style={this.styles().article}>
          <h3 style={this.styles().h3}>{t("cykelframjandetTitle")}</h3>
          <div style={this.styles().flex}>
            <div style={{ ...this.styles().flex3, ...this.styles().logo }}>
              <img
                style={this.styles().cykelframjandet}
                src="http://cykelframjandet.se/wp-content/uploads/2015/02/cykelframjandet-logga-webb.png"
              />
            </div>
            <div
              style={this.styles().flex2}
              dangerouslySetInnerHTML={{ __html: t("cykelframjandetBody") }}
            />
          </div>
        </article>
        <article
          style={this.styles().article}
          dangerouslySetInnerHTML={{ __html: t("tech") }}
        />
      </section>
    );
  }
}

module.exports = translate("About")(withMQ(StyleGlobals.breakPoints)(About));
