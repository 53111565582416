const React = require("react");
const { ReactCSS } = require("reactcss");
const ReactDOM = require("react-dom");
const { translate } = require("react-translate");

const StyleGlobals = require("../styles/globals");
const BikeRouteListItem = require("./BikeRouteListItem");

const withMQ = require("../mixins/mq");

class BikeRouteList extends React.Component {
  static displayName = "BikeRouteList";

  state = {
    focusedListItemId: null
  };

  classes = () => {
    return {
      default: {
        routeList: {
          padding: `${StyleGlobals.dimen12} ${StyleGlobals.dimen12}`,
          // position: 'absolute',
          dislpay: "flex",
          width: "100%",
          verticalAlign: "top",
          display: "flex",
          flexWrap: "wrap"
        },
        noMatch: {
          padding: `${StyleGlobals.dimen8} ${StyleGlobals.dimen12}`
        }
      },
      mobile: {
        routeList: {
          padding: `0 ${StyleGlobals.dimen16} ${StyleGlobals.dimen16}`,
          position: "relative"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.props);
  };

  componentDidUpdate() {
    let { scrollElement, focused } = this.props;
    let focusedListItem = ReactDOM.findDOMNode(this.refs.focusedListItem);
    if (
      focusedListItem &&
      scrollElement &&
      this.state.focusedListItemId != focused
    ) {
      scrollElement.scrollTop = focusedListItem.offsetTop;
      //Setting state of focused item so we dont constantly updates scrolltop
      this.setState({
        focusedListItemId: focused
      });
    }
  }

  render() {
    let { routes, highlighted, focused, highlightRoute, mobile } = this.props;
    return (
      <div style={this.styles().routeList}>
        {routes.map((route, index) => (
          <BikeRouteListItem
            key={route.id}
            ref={focused == route.id ? "focusedListItem" : null}
            route={route}
            highlightRoute={highlightRoute}
            isHighlighted={highlighted == route.id}
            isFocused={focused == route.id}
            notFocused={focused && focused != route.id}
            isMobile={mobile}
            index={index}
          />
        ))}
        {routes.length < 1 ? this.renderNoMatch() : ""}
      </div>
    );
  }

  renderNoMatch = () => {
    let { t } = this.props;
    return (
      <article style={this.styles().noMatch}>
        <h1>{t("noMatch")}</h1>
        <p>{t("noMatchAgain")}</p>
      </article>
    );
  };
}

module.exports = withMQ(StyleGlobals.breakPoints)(
  translate("BikeRouteList")(BikeRouteList)
);
