"use strict";

const React = require("react");

// format of config:
//
//  {<name>: {minWidth: <number>, maxWidth: <number>, minHeight: <number>, maxHeight: <number>}}
//
// example:
//
//  {desktop: {minWidth: 900, minHeight: 400}}
//
// TODO optimize by using a single resize listener and memoizing the media state
// TODO also "support" (as in, not fail) in server environments
//
module.exports = (config={}) => Component => (
  class Mq extends React.Component {

    static displayName = `Mq(${Component.displayName || Component.name || '<Unnammed>'})`

    constructor () {
      super()

      const w = typeof window !== 'undefined' ? window.innerWidth : 1280;
      const h = typeof window !== 'undefined' ? window.innerHeight : 800;
      this.state = this._getMediaState(w, h)
      this._updateWindowSize = this._updateWindowSize.bind(this)
    }

    componentWillMount() {
      if (typeof window != 'undefined') {
        window.addEventListener('resize', this._updateWindowSize, false)
        this._updateWindowSize()
      }
    }

    componentWillUnmount() {
      if (typeof window != 'undefined') {
        window.removeEventListener('resize', this._updateWindowSize)
      }
    }

    render() {
      return <Component {...this.state} {...this.props} />
    }

    _updateWindowSize() {
      window.cancelAnimationFrame(this.debounceWindowResize)
      this.debounceWindowResize = window.requestAnimationFrame(() => {
        const nextState = this._getMediaState(window.innerWidth, window.innerHeight)
        const hasChanged = Object.keys(nextState).some(key => this.state[key] !== nextState[key])
        if (hasChanged) {
          this.setState(nextState)
        }
      })
    }

    _getMediaState(width, height) {
      return Object.keys(config).reduce((mq, key) => {
        const conf = config[key];

        mq[key] = true;

        if ('minWidth' in conf && width < conf.minWidth) {
          mq[key] = false
        }
        if ('maxWidth' in conf && width > conf.maxWidth) {
          mq[key] = false
        }
        if ('minHeight' in conf && height < conf.minHeight) {
          mq[key] = false
        }
        if ('maxHeight' in conf && height > conf.maxHeight) {
          mq[key] = false
        }

        return mq
      }, {})
    }
  }
)