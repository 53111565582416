"use strict";

const { combineReducers } = require("redux");
const { routerReducer } = require("react-router-redux");

const views = require("./reducers/views");
const routes = require("./reducers/routes");
const places = require("./reducers/places");
const images = require("./reducers/images");
const sights = require("./reducers/sights");
const filters = require("./reducers/filters");
const language = require("./reducers/language");
const whitelist = require("./reducers/whitelist");

module.exports = combineReducers({
  places,
  whitelist,
  sights,
  routes,
  filters,
  language,
  images,
  views,
  routing: routerReducer
});
