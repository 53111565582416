const React = require("react");
const { ReactCSS } = require("reactcss");

class Loader extends React.Component {
  static displayName = "Loader";

  classes = () => {
    return {
      default: {
        container: {
          width: "100%",
          height: "100vh",
          position: "relative"
        },
        icon: {
          background: "rgba(255,255,255,.6)",
          borderRadius: "4px",
          padding: "20px",
          border: "1px solid rgb(216, 216, 216)",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate3d(-50%, -50%, 0)",
          zIndex: 2,
          width: "150px",
          height: "130px"
        },
        svg: {
          width: "auto",
          height: "100%",
          display: "block",
          transform: "translateX(-3px)"
        },
        bikeIcon: {
          animation: "3s linear infinite loaderAnimationVisiblity",
          opacity: "0",
          strokeWidth: "2.5px"
        },
        ground: {
          strokeDasharray: `6 6`,
          animation: "2s linear infinite loaderAnimation"
        }
      }
    };
  };

  styles = () => {
    return ReactCSS(this.classes(), this.state);
  };

  render() {
    let bikes = ["racer", "touring", "city"];
    return (
      <div style={{ ...this.styles().container, ...this.props.style }}>
        <div style={this.styles().icon}>
          <svg style={this.styles().svg}>
            <use
              style={{
                ...this.styles().bikeIcon,
                transform: "translateY(1px)",
                animationDelay: "0s"
              }}
              xlinkHref={`#${bikes[0]}`}
            />
            <use
              style={{ ...this.styles().bikeIcon, animationDelay: "1s" }}
              xlinkHref={`#${bikes[1]}`}
            />
            <use
              style={{ ...this.styles().bikeIcon, animationDelay: "2s" }}
              xlinkHref={`#${bikes[2]}`}
            />
            <path
              className="LoaderGroundAnimation"
              style={this.styles().ground}
              d="M0.5,80 L110,80"
              stroke="#000000"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </svg>
        </div>
      </div>
    );
  }
}

module.exports = Loader;
