"use strict";

const { createSelector } = require("reselect");

const intersects = require("../../lib/intersects");
const routes = require("./routes");

const emptyPlaces = {};
const routePlacesSelector = createSelector(
  routes.selectedRouteSelector,
  state => state.places.types,
  state => state.places.byId || emptyPlaces,
  state => state.places.infoById || emptyPlaces,
  (route, types, byId, infoById) => {
    let places = [];

    if (route) {
      // join all places matching the selected types
      places = types.reduce(
        (all, type) => all.concat(route.properties.places[type] || []),
        []
      );

      // filter out places that has not been loaded
      places = places.filter(id => byId[id]);

      // convert ids to google place objects
      places = places.map(id => ({
        ...byId[id],
        info: infoById[id] || {}
      }));
    }

    return places;
  }
);

const placesWithoutDuplicatesSelector = createSelector(
  routePlacesSelector,
  places => {
    const duplicates = {};
    return places.reduce(
      (places, place) =>
        duplicates[place.place_id]
          ? places
          : places.concat((duplicates[place.place_id] = place)),
      []
    );
  }
);

const placesWhitelistedSelector = createSelector(
  placesWithoutDuplicatesSelector,
  state => state.whitelist.placeById,
  state => state.views.isAdmin,
  (places, whitelist, isAdmin) =>
    isAdmin ? places : places.filter(place => whitelist[place.place_id])
);

const placesVisibleSelector = createSelector(
  state => state.filters.bbox,
  placesWhitelistedSelector,
  (bbox, places) =>
    places.filter(place =>
      intersects(bbox, [
        place.geometry.location.lng,
        place.geometry.location.lat,
        place.geometry.location.lng,
        place.geometry.location.lat
      ]))
);

const placesGeoJSONSelector = createSelector(
  placesWhitelistedSelector,
  places => ({
    type: "FeatureCollection",
    features: places.map(place => ({
      id: place.place_id,
      type: "Feature",
      properties: place,
      geometry: {
        type: "Point",
        coordinates: [place.geometry.location.lng, place.geometry.location.lat]
      }
    }))
  })
);

const placeSelector = createSelector(
  state => state.places.focused,
  state => state.places.byId || emptyPlaces,
  state => state.places.infoById || emptyPlaces,
  (placeId, byId, infoById) => {
    return placeId
      ? {
          ...byId[placeId],
          info: infoById[placeId] || {}
        }
      : null;
  }
);

const emptyFilter = ["==", "id", ""];
const mapPlaceFilterSelector = createSelector(
  placeSelector,
  place => place ? ["==", "id", place.place_id] : emptyFilter
);

const placesCount = createSelector(
  placesWhitelistedSelector,
  places => places.length
);

exports.placesCount = placesCount;
exports.placesGeoJSONSelector = placesGeoJSONSelector;
exports.placesVisibleSelector = placesVisibleSelector;
exports.placeSelector = placeSelector;
exports.mapPlaceFilterSelector = mapPlaceFilterSelector;
